import { Component, EventEmitter, Input, Output } from "@angular/core"
import { firstValueFrom } from "rxjs"
import { ApiService } from "src/api.service"
import { environment } from "src/environments/environment"
import { StrapiFile } from "src/models/filetypes-model"
import { FileType } from "../file-card-container/file-card-container.component"

@Component({
  selector: "app-file-card",
  templateUrl: "./file-card.component.html",
  styleUrl: "./file-card.component.scss",
})
export class FileCardComponent {
  @Input({ required: true })
  file!: StrapiFile
  @Input()
  isSelected: boolean = false

  @Output()
  deleted: EventEmitter<StrapiFile> = new EventEmitter()
  @Output()
  selected: EventEmitter<StrapiFile> = new EventEmitter()

  protected deleting = false
  protected downloading = false
  constructor(private apiService: ApiService) {}

  public get loading(): boolean {
    return this.deleting || this.downloading
  }

  selectClicked(event: Event) {
    event.preventDefault()
    event.stopPropagation()
    this.selected.emit(this.file)
  }

  protected get fileUrl(): string {
    return environment.apiUrl + this.file.url
  }

  protected async deleteClicked() {
    try {
      this.deleting = true
      await firstValueFrom(this.apiService.deleteFile(this.file.id))
      this.deleted.emit(this.file)
    } catch {
    } finally {
      this.deleting = false
    }
  }
  protected downloadClicked() {
    try {
      this.downloading = true
      this.apiService.donwloadFile(this.file.url).subscribe((blob) => {
        const a = document.createElement("a")
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        a.download = this.file.name
        a.click()
        URL.revokeObjectURL(objectUrl)
      })
    } catch {
    } finally {
      this.downloading = false
    }
  }

  private getType(): FileType {
    const mime = this.file.mime
    if (mime.startsWith("video")) {
      return FileType.video
    } else if (mime.startsWith("audio")) {
      return FileType.audio
    } else if (mime.startsWith("image")) {
      return FileType.image
    } else {
      return FileType.document
    }
  }

  protected get isVideo(): boolean {
    return this.getType() == FileType.video
  }
  protected get isAudio(): boolean {
    return this.getType() == FileType.audio
  }
  protected get isImage(): boolean {
    return this.getType() == FileType.image
  }
  protected get isDocument(): boolean {
    return this.getType() == FileType.document
  }
}
