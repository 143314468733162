import { booleanAttribute, Component, ElementRef, HostBinding, HostListener, Input } from '@angular/core';
import { mdiAlertCircleOutline } from '@mdi/js';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent {
  protected readonly errorIcon = mdiAlertCircleOutline;

  @Input()
  label?: string;

  @Input()
  labelClass?: string;

  @Input({ transform: booleanAttribute })
  required: boolean = false;

  @Input()
  @HostBinding('class')
  labelPosition: 'top' | 'left' | 'right' = 'top';

  @Input()
  helpText?: string;

  @Input()
  control?: NgModel;

  constructor(protected elementRef: ElementRef) {}

  @HostListener('click')
  protected onClick() {
    this.elementRef.nativeElement?.querySelector('input, textarea')?.focus();
  }
}
