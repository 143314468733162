import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'formatSchwerpunktText',
})

export class FormatSchwerpunktText implements PipeTransform {
  formats : string[] = ["<b>", "<i>", ""];

  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    if (!value) {
      return value;
    }

    return this.sanitizer.bypassSecurityTrustHtml(value[0]+value.split('').slice(1).map(part => this.formatCharacter(part)).join(""));
  }

  formatCharacter(character: string) : string {
    let randomFormat = Math.round(Math.random());
    return this.formats[randomFormat] + 
        (Math.round(Math.random()) == 1 ? character.toLocaleUpperCase() : character.toLocaleLowerCase()) + 
        this.formats[randomFormat];
  }
}