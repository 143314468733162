import { FormControl, FormGroup } from "@angular/forms"

export class FormHelper {
  form: FormGroup
  submitAttempted = false
  constructor(form: FormGroup) {
    this.form = form
  }

  markFormTouched() {
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.controls[field];
      control.markAsTouched({ onlySelf: true });
    });
  }

  public patchValue(controlName: string, value: any) {
    if (!this.form) return
    this.form.controls[controlName].patchValue(value)
    this.form.controls[controlName].updateValueAndValidity()
  }

  public controlError(control: string, error: string): any {
    return this.form?.get(control)?.errors?.[error]
  }

  public controlInvalidAndTouched(control: string) {
    return this.form?.get(control)!.invalid && this.form?.get(control)!.touched
  }

  public rteCounterInvalid(control: string): boolean {
    return this.form?.get(control)!.invalid && this.controlError(control, "max")
  }

  public getControl(name: string): FormControl | undefined {
    return this.form?.controls[name] as FormControl
  }
}
