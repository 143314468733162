<div class="stage-day-title-container">
  <h4 class="stage-title">
    {{ stageTitle }}
  </h4>
  <div class="day-title">
    <h5 class="html" [innerHTML]="dayTitle"></h5>
    <h5>{{ dayDate }}</h5>
  </div>
</div>
@if(pagination) {
<div>{{ pagination.total }} Resultate</div>
<div>Seite {{ pagination.page }} von {{ pagination.pageCount }}</div>
}
<div class="pagination">
  <button class="pagination-prev" (click)="goToPage.emit('previous')">
    <svg xmlns="http://www.w3.org/2000/svg" width="243.06" height="92.59" viewBox="0 0 66.442 24.106">
      <defs>
        <clipPath id="a" clipPathUnits="userSpaceOnUse">
          <path d="M-882 221H253v-719H-882Z" />
        </clipPath>
      </defs>
      <path
        d="m0 0 36-63h-72Z"
        clip-path="url(#a)"
        style="fill: #000 icc-color(sRGB-IEC61966-2, 0.1, 0, 0, 0); fill-opacity: 1; fill-rule: nonzero; stroke: none"
        transform="matrix(0 .28127 .28127 0 20.072 12.183)"
      />
      <path
        d="m0 0 36-63h-72Z"
        clip-path="url(#a)"
        style="fill: #000 icc-color(sRGB-IEC61966-2, 0.1, 0, 0, 0); fill-opacity: 1; fill-rule: nonzero; stroke: none"
        transform="matrix(0 .28127 .28127 0 42.192 12.183)"
      />
      <path
        d="m0 0 36-63h-72Z"
        clip-path="url(#a)"
        style="fill: #000 icc-color(sRGB-IEC61966-2, 0.1, 0, 0, 0); fill-opacity: 1; fill-rule: nonzero; stroke: none"
        transform="matrix(0 .27987 .27987 0 64.283 12.132)"
      />
    </svg>
  </button>
  <button class="pagination-next" (click)="goToPage.emit('next')">
    <svg xmlns="http://www.w3.org/2000/svg" width="243.06" height="92.59" viewBox="0 0 66.442 24.106">
      <defs>
        <clipPath id="a" clipPathUnits="userSpaceOnUse">
          <path d="M-882 221H253v-719H-882Z" />
        </clipPath>
      </defs>
      <path
        d="m0 0 36-63h-72Z"
        clip-path="url(#a)"
        style="fill: #000 icc-color(sRGB-IEC61966-2, 0.1, 0, 0, 0); fill-opacity: 1; fill-rule: nonzero; stroke: none"
        transform="matrix(0 .28127 .28127 0 20.072 12.183)"
      />
      <path
        d="m0 0 36-63h-72Z"
        clip-path="url(#a)"
        style="fill: #000 icc-color(sRGB-IEC61966-2, 0.1, 0, 0, 0); fill-opacity: 1; fill-rule: nonzero; stroke: none"
        transform="matrix(0 .28127 .28127 0 42.192 12.183)"
      />
      <path
        d="m0 0 36-63h-72Z"
        clip-path="url(#a)"
        style="fill: #000 icc-color(sRGB-IEC61966-2, 0.1, 0, 0, 0); fill-opacity: 1; fill-rule: nonzero; stroke: none"
        transform="matrix(0 .27987 .27987 0 64.283 12.132)"
      />
    </svg>
  </button>
</div>
<div class="sort-container" *ngIf="items!.length > 0">     
  <select class="selectOrder" #selectOrder (change)="onDropdownChangeSort($event)">
    @for (option of sortOptions; track $index) {
      <option [value]="option.key">{{ option.label }}</option>
    }
  </select>
</div>
<mat-accordion multi="false">
  @for (item of items; track $index) {
  <mat-expansion-panel
    hideToggle="true"
    (opened)="openPanel(item.id)"
    (closed)="closePanel(item.id)"
  >
    <mat-expansion-panel-header [ngClass]="panelOpenState === item.id ? 'open' : 'close'">
      <mat-panel-title>
        <div class="header">
          <div class="time-text-container">
            <div class="time" [ngClass]="{
              'time-color-default': handleColorSelector(item) == 0,
              'time-color-success': handleColorSelector(item) == 1,
              'time-color-error': handleColorSelector(item) == 2
            }">
               {{ startTimeSelector(item.attributes) | time }}
            </div>
            <div class="text">
              <div class="header-speech-title">{{ titleSelector(item.attributes) }}</div>
              <div class="header-speech-subtitle">{{ subTitleSelector(item.attributes)  }}</div>
              <div class="header-speech-institution">{{ institutionSelector(item.attributes) }}</div>
            </div>
          </div>
          <div class="header-icons">
            <ng-container *ngIf="customPanelTemplate">
              <ng-container *ngTemplateOutlet="customPanelTemplate; context: { $implicit: item }"></ng-container>
            </ng-container>
            <img
              class="expandable-icon"
              [src]="'../../assets/' + (panelOpenState === item.id ? 'minus-icon.svg' : 'plus-icon.svg')"
            />          
          </div>
          <div class="expandable-text">
            <span >Mehr erfahren</span>
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="content">
      <div class="left-banner">
        <h5 class="html" [innerHTML]="daySelector(item.attributes)"></h5>
        <h5 style="font-weight: 600">{{ dateSelector(item.attributes) | date : "dd.MM.YYYY" }}</h5>
        @for (iconItem of iconItems; track $index) {
          <div class="icon-info-item">
            <i [class]="'bi bi-' + iconItem.icon"></i>
            <div>{{ iconItem.textSelector(item.attributes) }}</div>
          </div>
        }
        @if(institutionSelector(item.attributes)){
        <div class="institution-info-item">
          <p class="label">Institution:</p>
          <p class="text">{{ institutionSelector(item.attributes) }}</p>
        </div>
        }
      </div>
      <div class="main-content">
        <h5 class="title">
          {{ titleSelector(item.attributes) }}
        </h5>
        <p class="subtitle">{{ subTitleSelector(item.attributes) }}</p>
        <ng-container *ngIf="customContentTemplate">
          <ng-container *ngTemplateOutlet="customContentTemplate; context: { $implicit: item }"></ng-container>
        </ng-container>
        @if(speakersSelector(item.attributes) && speakersSelector(item.attributes)!.length > 0){
        <div class="speaker-container">
          <p class="title">Speaker:</p>
          @for (speaker of speakersSelector(item.attributes); track $index) {
          <div class="speaker">
            <p class="name">{{ speaker.Name }}</p>
            <p *ngIf="speaker.Info" class="info">{{ speaker.Info }}</p>
          </div>
          }
        </div>
        }
        <div class="html description" [innerHTML]="descriptionSelector(item.attributes)"></div>
      </div>
      <div class="bottom-arrows">
        <img src="../../assets/triangle-up-triple-sideways.svg" />
      </div>
    </div>
  </mat-expansion-panel>
  }
</mat-accordion>
@if(pagination) {
<div>Seite {{ pagination.page }} von {{ pagination.pageCount }}</div>
}
<div class="pagination">
  <button class="pagination-prev" (click)="goToPage.emit('previous')">
    <svg xmlns="http://www.w3.org/2000/svg" width="243.06" height="92.59" viewBox="0 0 66.442 24.106">
      <defs>
        <clipPath id="a" clipPathUnits="userSpaceOnUse">
          <path d="M-882 221H253v-719H-882Z" />
        </clipPath>
      </defs>
      <path
        d="m0 0 36-63h-72Z"
        clip-path="url(#a)"
        style="fill: #000 icc-color(sRGB-IEC61966-2, 0.1, 0, 0, 0); fill-opacity: 1; fill-rule: nonzero; stroke: none"
        transform="matrix(0 .28127 .28127 0 20.072 12.183)"
      />
      <path
        d="m0 0 36-63h-72Z"
        clip-path="url(#a)"
        style="fill: #000 icc-color(sRGB-IEC61966-2, 0.1, 0, 0, 0); fill-opacity: 1; fill-rule: nonzero; stroke: none"
        transform="matrix(0 .28127 .28127 0 42.192 12.183)"
      />
      <path
        d="m0 0 36-63h-72Z"
        clip-path="url(#a)"
        style="fill: #000 icc-color(sRGB-IEC61966-2, 0.1, 0, 0, 0); fill-opacity: 1; fill-rule: nonzero; stroke: none"
        transform="matrix(0 .27987 .27987 0 64.283 12.132)"
      />
    </svg>
  </button>
  <button class="pagination-next" (click)="goToPage.emit('next')">
    <svg xmlns="http://www.w3.org/2000/svg" width="243.06" height="92.59" viewBox="0 0 66.442 24.106">
      <defs>
        <clipPath id="a" clipPathUnits="userSpaceOnUse">
          <path d="M-882 221H253v-719H-882Z" />
        </clipPath>
      </defs>
      <path
        d="m0 0 36-63h-72Z"
        clip-path="url(#a)"
        style="fill: #000 icc-color(sRGB-IEC61966-2, 0.1, 0, 0, 0); fill-opacity: 1; fill-rule: nonzero; stroke: none"
        transform="matrix(0 .28127 .28127 0 20.072 12.183)"
      />
      <path
        d="m0 0 36-63h-72Z"
        clip-path="url(#a)"
        style="fill: #000 icc-color(sRGB-IEC61966-2, 0.1, 0, 0, 0); fill-opacity: 1; fill-rule: nonzero; stroke: none"
        transform="matrix(0 .28127 .28127 0 42.192 12.183)"
      />
      <path
        d="m0 0 36-63h-72Z"
        clip-path="url(#a)"
        style="fill: #000 icc-color(sRGB-IEC61966-2, 0.1, 0, 0, 0); fill-opacity: 1; fill-rule: nonzero; stroke: none"
        transform="matrix(0 .27987 .27987 0 64.283 12.132)"
      />
    </svg>
  </button>
</div>
