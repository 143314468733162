import { ViewContainerRef } from "@angular/core"
import { InfoComponent } from "./info/info.component"
import { CardGalleryComponent } from "./startpage-modules/card-gallery/card-gallery.component"
import { StartPageHeader } from "./startpage-modules/startpage-header/startpage-header.component"
import { TwoColumnStartpageTextComponent } from "./startpage-modules/two-column-startpage-text/two-column-text.component"
import { VideoComponent } from "./startpage-modules/video/video.component"
import { DocumentsListComponent } from "./startpage-modules/documents-list/documents-list.component"
import { LightboxComponent } from "./startpage-modules/lightbox/lightbox.component"
import { ImageComponent } from "./startpage-modules/image/image.component"
import { RichTextComponent } from "./startpage-modules/rich-text/rich-text.component"

export class DynamicContentContainer {
  imgBaseUrl = ""
  startPageDynamicContents: any[] = []
  constructor() {}

  initDynamicContents(dynamicContentContainer: ViewContainerRef) {
    dynamicContentContainer.clear()
    for (let component of this.startPageDynamicContents) {
      if (component["__component"] === "content-blocks.header") {
        const startPageRef = dynamicContentContainer.createComponent(StartPageHeader)
        startPageRef.setInput("header", component)
      }
      if (component["__component"] === "content-blocks.video-highlight") {
        const videoRef = dynamicContentContainer.createComponent(VideoComponent)
        videoRef.setInput("title", component!["Title"])
        videoRef.setInput("videoUrl", this.imgBaseUrl + component!["Video"]["data"]["attributes"]["url"])
      }
      if (component["__component"] === "content-blocks.dual-text") {
        const dualTextRef = dynamicContentContainer.createComponent(TwoColumnStartpageTextComponent)
        dualTextRef.setInput("title", component!["Title"])
        dualTextRef.setInput("subTitle", component!["SubTitle"])
        dualTextRef.setInput("column1", component!["LeftText"])
        dualTextRef.setInput("column2", component!["RightText"])
        dualTextRef.setInput("link", component!["Link"])
      }
      if (component["__component"] === "content-blocks.card-gallery") {
        const cardGalleryRef = dynamicContentContainer.createComponent(CardGalleryComponent)
        cardGalleryRef.setInput("title", component!["Title"])
        cardGalleryRef.setInput("subTitle", component!["SubTitle"])
        cardGalleryRef.setInput("cards", component!["Cards"])
      }
      if (component["__component"] === "content-blocks.info-panel") {
        const infoRef = dynamicContentContainer.createComponent(InfoComponent)
        infoRef.setInput("infoEntries", component!["InfoEntries"])
      }
      if (component["__component"] === "content-blocks.documents-list") {
        const documentsListRef = dynamicContentContainer.createComponent(DocumentsListComponent)
        documentsListRef.setInput("title", component!["Title"])
        documentsListRef.setInput("files", component!["Documents"]["data"])
      }
      if (component["__component"] === "content-blocks.double-image-content-block") {
        const lightboxRef = dynamicContentContainer.createComponent(LightboxComponent)
        lightboxRef.setInput("mediaItems", component!["Media"]["data"])
      }
      if (component["__component"] === "content-blocks.rich-text-content-block") {
        const richTextRef = dynamicContentContainer.createComponent(RichTextComponent)
        richTextRef.setInput("content", component!["HtmlContent"])
      }
      if (component["__component"] === "content-blocks.image-content-block") {
        const imageRef = dynamicContentContainer.createComponent(ImageComponent)
        imageRef.setInput("image", component!["Media"]["data"])
      }
    }
  }
}
