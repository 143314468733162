import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class RteRendererService {

  constructor(private sanitizer: DomSanitizer) { }

  renderContent(content: any): SafeHtml {
    if(content) {
      return this.sanitizer.bypassSecurityTrustHtml(content.map((item : any) => this.getContent(item)).join(''));
    } else return "";
  }

  private getContent(item: any): string {
    // Define how to render each type of content block
    switch(item.type) {
      case 'paragraph':
        return this.renderParagraph(item)
      case 'text':
        return this.renderText(item);
      case 'heading':
        return this.renderHeading(item);

      case 'list':
        return this.renderList(item);
      case 'list-item':
        return this.renderListItem(item);
      case 'code':
        return this.renderCode(item);
      case 'quote':
        return this.renderQuote(item);
      case 'image':
        return this.renderImage(item);
      // return `<img src="${item.url}" alt="${item.caption}">`;
      case 'link':
        return this.renderLink(item);
      // console.log("link", item.children)
      // return `${item}<a href="${item.url}" target="_blank">${item.children[0].text}</a>`;
      // Add more cases as needed for other types of blocks
      default:
        return '';
    }
  }


  private renderText(item: any) {
    let textContent = item.text;
    if (item.bold) {
      textContent = `<b>${textContent}</b>`;
    }
    if (item.italic) {
      textContent = `<i>${textContent}</i>`;
    }
    return textContent;
  }

  private renderImage(item: any) {
    // return "renderImage<br>";
    console.log("image item", item)
    const caption = item.image.caption ? `<figcaption>${item.caption}</figcaption>` : '';
    return `<figure><img src="${item.image.url}" alt="${item.image.alternativeText || ''}">${caption}</figure>`;
  }
  private renderLink(item: any) {
    return `<a href="${item.url}" target="_blank">${item.children[0].text}</a>`;
  }
  private renderHeading(item: any) {
    if(item.level == 1) {
      return `<h${item.level}><span>${item.children[0].text}</span></h${item.level}>`;
    } else {
      return `<h${item.level}>${item.children[0].text}</h${item.level}>`;
    }
  }
  private renderList(item: any) {
    const listTag = item.format == "ordered" ? 'ol' : 'ul';
    let listItems = item.children.map((child:any) => `<li>${this.getContent(child)}</li>`).join('');
    // const items = item.children.map((item : any) => `<li></li>`).join('');
    return `<${listTag}>${listItems}</${listTag}>`;
  }
  private renderListItem(item: any) {
    return item.children.map((child:any) => `${this.getContent(child)}`).join('');
  }
  private renderQuote(item: any) {
    let childrenRendered = item.children.map((child:any) => `${this.getContent(child)}`).join('');

    return `<blockquote>${childrenRendered}</blockquote><br>`;
  }
  private renderCode(item: any) {
    return `<code>${this.getContent(item.children[0])}</code>`;
  }
  private renderParagraph(item: any) {
    // return "renderParagraph<br>";

    let paragraphContent = ""
    return `<p>${item.children.map((child: any) => this.getContent(child)).join('')}</p>`;

    // for (const key in item.children) {
    //   if (item.children.hasOwnProperty(key)) {
    //     const child = item.children[key]
    //
    //     let textContent = "";
    //     textContent = this.getContent(child)
    //     // textContent = this.renderText(child)
    //     paragraphContent += textContent
    //   }
    // }
    return paragraphContent
  }
}
