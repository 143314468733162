<!--    <h1 *ngIf="page"><span>{{page['data']['attributes']['Title']}}</span></h1>
<div *ngIf="page && page['data'][0]['attributes']['HeroImage']['data']">
  <img class="img-fluid"
       [src]="imgBaseUrl + page['data'][0]['attributes']['HeroImage']['data']['attributes']['url']"
       [alt]="page['data'][0]['attributes']['HeroImage']['data']['attributes']['alternativeText']">
</div>
<div *ngIf="page">
  <img *ngIf="page['data'][0]['attributes']['HeroImage']['data']" [src]="imgBaseUrl + page['data'][0]['attributes']['HeroImage']['data']['attributes']['url']"/>
</div>
-->
<div #dynamicContent>

</div>