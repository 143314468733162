<div class="documents-list-container">
  <div class="title-container" [innerHTML]="title"></div>
  <div class="documents-container">
    @for (file of files; track $index) {
    <div class="document">
      <img src="../../../assets/pdf-file-icon.svg" /><a [href]="baseUrl + file.attributes?.url">{{ name(file) }}</a>
    </div>
    }
  </div>
</div>
