// split.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitCode',
})

export class SplitCode implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    // Split the string by ':'
    const parts = value.split(':');

    // Return the first part
    return parts[0];
  }
}

@Pipe({
  name: 'splitDescription',
})

export class SplitDescription implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    // Split the string by ':'
    const parts = value.split(':');

    // Return the first part
    return parts[1];
  }
}