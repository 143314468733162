import { Injectable } from "@angular/core"
import { environment } from "./environments/environment"
import { HttpClient } from "@angular/common/http"
import { Observable, tap } from "rxjs"
import { AuthenticationResponse, User } from "./models/authentication/authentication-response"
import { RegistrationRequest } from "./models/authentication/registration-request"
import { LoginRequest } from "./models/authentication/login-request"
import * as moment from "moment"
import { jwtDecode } from "jwt-decode"
import { ForgotPasswordRequest } from "./models/authentication/forgot-password-request"
import { ResetPasswordRequest } from "./models/authentication/reset-password-request"

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  public apiUrl = environment.apiUrl
  private _expiration?: moment.Moment
  private _user?: User

  public get expiration(): moment.Moment | undefined {
    return this._expiration
  }

  public get user(): User | undefined {
    return this._user
  }

  constructor(private http: HttpClient) {
    this._user = this.getUserFromStorage()
    this._expiration = this.getExpirationFromStorage()
  }
  unlockSite(): void {
    localStorage.setItem("isSiteUnlocked", "true")
  }

  isSiteLocked(): boolean {
    return !localStorage.getItem('isSiteUnlocked');
  }

  register(request: RegistrationRequest): Observable<AuthenticationResponse> {
    return this.http.post<AuthenticationResponse>(`${this.apiUrl}/api/auth/local/register`, request)
  }

  public login(request: LoginRequest, stayLoggedIn: boolean): Observable<AuthenticationResponse> {
    return this.http
      .post<AuthenticationResponse>(`${this.apiUrl}/api/auth/local`, request)
      .pipe(tap((res) => this.setSession(res, stayLoggedIn)))
  }

  private setSession(authResult: AuthenticationResponse, stayLoggedIn: boolean) {
    var storage = stayLoggedIn ? localStorage : sessionStorage
    var tokenContent = jwtDecode(authResult.jwt)
    if (tokenContent && tokenContent.exp) {
      const expiresAt = moment.unix(tokenContent.exp)
      this._expiration = expiresAt
      storage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()))
    }
    storage.setItem("auth_token", authResult.jwt)
    storage.setItem("user", JSON.stringify(authResult.user))
    this._user = authResult.user
  }

  public forgotPassword(request: ForgotPasswordRequest) {
    return this.http.post<any>(`${this.apiUrl}/api/auth/forgot-password`, request)
  }

  public resetPassword(request: ResetPasswordRequest) {
    return this.http.post<any>(`${this.apiUrl}/api/auth/reset-password`, request)
  }

  public isLoggedIn(): boolean {
    if (!this.expiration) return false
    return moment().isBefore(this.expiration) && this.getTokenFromStorage() != undefined
  }

  public getTokenFromStorage(): string | undefined {
    const localToken = localStorage.getItem("auth_token")
    const sessionToken = sessionStorage.getItem("auth_token")

    return localToken ?? sessionToken ?? undefined
  }

  private getExpirationFromStorage(): moment.Moment | undefined {
    const localExpiration = localStorage.getItem("expires_at")
    const sessionExpiration = sessionStorage.getItem("expires_at")
    if (!localExpiration && !sessionExpiration) return
    const expiresAt = JSON.parse(localExpiration ?? sessionExpiration!)
    return moment(expiresAt)
  }

  private getUserFromStorage(): User | undefined {
    var localJsonUser = localStorage.getItem("user")
    var sessionJsonUser = sessionStorage.getItem("user")
    if (localJsonUser) return JSON.parse(localJsonUser) as User
    if (sessionJsonUser) return JSON.parse(sessionJsonUser) as User
    else return
  }

  public logout() {
    localStorage.removeItem("auth_token")
    localStorage.removeItem("expires_at")
    localStorage.removeItem("user")
    sessionStorage.removeItem("auth_token")
    sessionStorage.removeItem("expires_at")
    sessionStorage.removeItem("user")

    this._user = undefined
    this._expiration = undefined
  }
}
