<div class="bottom-information">
  <div class="bottom-information-exclamation-mark-container">
    <div class="bottom-information-exclamation-mark">!</div>
  </div>
  <div class="bottom-information-title-text">
    <div class="bottom-information-title">
      <span class="title" [innerHTML]="title"></span
      >
    </div>
    <div class="html-content bottom-information-text" [innerHTML]="text">
    </div>
  </div>
</div>
