import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { ApiService } from "../../api.service"
import { MarkdownService } from "ngx-markdown"
import { DomSanitizer } from "@angular/platform-browser"
import { RteRendererService } from '../rte-renderer.service';
import { DynamicContentContainer } from "../dynamic-content-container"

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent extends DynamicContentContainer implements OnInit {
  page = null

  @ViewChild('dynamicContent', { read: ViewContainerRef }) dynamicContentContainer!: ViewContainerRef;

  constructor(private markdownService: MarkdownService,
              private apiService: ApiService,
              private route: ActivatedRoute,
              private router: Router,
              protected rteRenderer: RteRendererService,
              private sanitizer: DomSanitizer) {
    super()
    this.imgBaseUrl = apiService.apiUrl
  }


  ngOnInit(): void {
    this.imgBaseUrl = this.apiService.apiUrl
    this.route.params.subscribe(params => {
      let slug: string | null = "";
      if(this.route.snapshot.data["slug"]) {
        slug = this.route.snapshot.data["slug"];
      } else {
        if(params["slug"]) {
          slug = params["slug"];
        } else {
          slug = null;
        }
      }

      // TODO refactor join
      let path = '';
      if(params["seg1"]) {
        path += params["seg1"]
      }
      if(params["seg2"]) {
        path += '/'+params["seg2"]
      }
      if(params["slug"]) {
        path += '/'+params["slug"]
      }

      let isStartpage = false;
      if(path === '') {
        isStartpage = true;
      }

      let isTopMenu = false;
      this.apiService.getTopPageMenu().subscribe(
        (data:any) => {
          for(let item of data.data) {
            if(item['attributes'].Url === path) {
              isTopMenu = true;
            }
          }
        },
        (error:any) => {
          console.error(error);
        }
      );


      let isFooter = false;
      this.apiService.getFooterMenu().subscribe(
        (data:any) => {
          for(let item of data.data) {
            if(item['attributes'].Url === path) {
              isFooter = true;
            }
          }
        },
        (error:any) => {
          console.error(error);
        }
      );

      let menu = this.apiService.getMenu().subscribe({
        error: (error: any) => { console.log(error); }, 
        next: (data: any) => { 
          let found = false;
          // TODO: refactor to recursive call
          for(let item of data.data) {
            if(item['attributes'].Url === 'path') {
              found = true;
            } else {
              let subItems = item['attributes'].subItems
              for(let subItem of subItems) {
                if(subItem.Url === path) {
                  found = true;
                } else {
                  let subSubItems = subItem.subItems
                  for(let subItem of subSubItems) {
                    if(subItem.Url === path) {
                      found = true;
                    }
                  }
                }
              }
            }
          }

          if(slug && (found || isStartpage || isFooter || isTopMenu)) {
            this.apiService.getPageBySlug(slug).subscribe(
              (data: any) => {
                this.page = data
                if(!data.data[0] || data.data.length === 0) {
                  console.log("REDIRECTING SLUG NOT FOUND")
                  this.router.navigate(["/404"])
                } else {
                  this.startPageDynamicContents = data.data[0].attributes.DynamicContent;
                  this.initDynamicContents(this.dynamicContentContainer);  
                }
              },
              (error: any) => {
                console.error(error)
              });    
          } else {
            console.log("REDIRECTING URL NOT FOUND")
            this.router.navigate(["/404"])
          }
        }
      });
    })
  }

  getComponent(component: string) {
    return this.startPageDynamicContents.find(c => c["__component"] === component);
  }
}
