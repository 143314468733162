<div class="login-container">
  <div class="title-container">
    <h1>
      {{ page?.data?.attributes?.page?.data?.attributes?.Title ?? "" }}
    </h1>
  </div>
  <div class="content">
    <div [innerHTML]="page?.data?.attributes?.page?.data?.attributes?.HtmlContent ?? ''"></div>

    <div style="display: flex; flex-direction: row; flex-wrap: wrap; gap: 64px">
      <form style="flex: 1" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-title">Login für bereits registrierte Aussteller</div>
        <div class="inputs-row">
          <app-best-input 
            label="E-Mail:"
            [formGroup]="loginForm"
            controlName="identifier"
            type="email"
            [required]="true"
            [validatorErrors]="[{key: 'required', errorText: 'Dieses Feld muss ausgefüllt sein'}]"
          />
        </div>

        <div class="inputs-row">
          <app-best-input 
            label="Passwort:"
            [formGroup]="loginForm"
            controlName="password"
            type="password"
            [required]="true"
            [validatorErrors]="[{key: 'required', errorText: 'Dieses Feld muss ausgefüllt sein'}]"
          />
        </div>

        <label class="error-text" *ngIf="loginSuccess != undefined && !loginSuccess">
          {{ errorText }}
        </label>

        <label class="success-text" *ngIf="loginSuccess">
          Sie haben sich erfolgreich angemeldet und werden in Kürze zur Homepage weitergeleitet.
        </label>

        <div class="inputs-row">
          <div class="input-container checkbox" style="justify-content: space-between">
            <label class="input-label">
              <input [formControl]="stayLoggedInCtrl" type="checkbox" />
              <span class="custom-checkbox"></span>
              Angemeldet bleiben<b style="color: red">*</b>
            </label>
            <a class="passwordForgotten" href="/forgot-password">Password vergessen?</a>
          </div>
        </div>
        <label *ngIf="!loginForm.valid && fh?.submitAttempted" class="error-text" style="padding-top: 32px"
          >Einige Felder wurden nicht richtig ausgefüllt. Bitte überprüfen sie ihre Angaben.
        </label>
        <button class="submit-button" type="submit">
          Jetzt Anmelden
          <svg
            xmlns="http://www.w3.org/2000/svg"
            shape-rendering="geometricPrecision"
            text-rendering="geometricPrecision"
            image-rendering="optimizeQuality"
            fill-rule="evenodd"
            clip-rule="evenodd"
            viewBox="0 0 512 512"
            class="arrow"
          >
            <path
              fill-rule="nonzero"
              d="M165 0c-8-1-16 6-17 15s7 17 16 17l274 19L5 484c-7 7-7 17 0 23 6 7 16 7 23 0L461 74l19 274c0 9 8 16 17 16s16-9 15-17L491 37c0-9-7-15-15-16L165 0z"
            />
          </svg>
        </button>
      </form>
      <app-contacts style="flex: 1" [contacts]="page?.data?.attributes?.Contacts"></app-contacts>
    </div>
  </div>
</div>

<div #dynamicContent></div>
