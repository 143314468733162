import { Component, Input } from "@angular/core"
import { FormControl } from "@angular/forms"

@Component({
  selector: "app-toggle",
  templateUrl: "./toggle.component.html",
  styleUrl: "./toggle.component.scss",
})
export class ToggleComponent {
  @Input()
  formControl = new FormControl(false)
}
