<div class="stage-selector">
  <p class="title">Veranstaltungsort</p>
  <div class="stages-box">
    @for (stage of stages; track $index) {
    <div
      class="stage-container"
      [ngClass]="selectedStage?.id === stage.id ? 'selected' : ''"
      (click)="selectStage(stage)"
    >
      <img [src]="'../../assets/triangle-right' + (selected(stage) ? '-best-yellow.svg' : '-grey.svg')" />
      <div class="counter-name-container">
        <div class="stage-counter">BüHNE {{ stageCounter(stage) }}</div>
        <div class="stage-name">{{ stage.attributes.Name }}</div>
      </div>
    </div>
    }
  </div>
  <div class="location">{{ location }}</div>
</div>
