import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { hide } from '@popperjs/core';
import { image } from 'ngx-editor/schema/nodes';
import { ApiService } from 'src/api.service';

@Component({
  selector: 'lightbox-component',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss']
})
export class LightboxComponent implements OnInit {
  @Input()
  mediaItems = [];

  imgBaseUrl = ""
  currentItem = 0;
  open = false;

  constructor(private sanitizer: DomSanitizer, private apiService: ApiService) {  
  }

  ngOnInit(): void {
    this.imgBaseUrl = this.apiService.apiUrl
    let hidetest = document.getElementById('currentImage')!;
    if(hidetest) {
      document.onclick = function(e){
        if (e.target instanceof Element && e.target.id !== 'currentImage' && e.target.id != 'galleryimage') {
          let image = hidetest.getElementsByTagName('img')[0];
          let video = hidetest.getElementsByTagName('video')[0];

          if(image) {
            image.style.opacity = '0';
            image.style.visibility = 'hidden';
          }

          if(video) {
            video.style.opacity = '0';
            video.style.visibility = 'hidden';
          }
        }
      };
    }
  }

  next() {
    if(this.mediaItems.length === this.currentItem+1) {
      this.currentItem = 0;
      return;
    }
    this.currentItem ++;
  }

  prev() {
    if(this.currentItem-1 < 0) {
      this.currentItem = this.mediaItems.length-1;
      return;
    }
    this.currentItem --;
  }

  showFullImage() {
    let hidetest = document.getElementById('currentImage')!;    
    if(window.matchMedia("(max-width: 1024px)").matches ) {
      return;
    }
    let image = hidetest.getElementsByTagName('img')[0];
    image.style.visibility = 'visible';
    image.style.opacity = '1';
  }

  showFullVideo() {
    let hidetest = document.getElementById('currentImage')!;    
    if(window.matchMedia("(max-width: 1024px)").matches ) {
      return;
    }
    let image = hidetest.getElementsByTagName('video')[0];
    image.style.visibility = 'visible';
    image.style.opacity = '1';
  }

  focusClickOnGallery(img: string) {
    this.currentItem =  this.mediaItems.map(m => m['attributes']['name']).findIndex(n => n === img);
  }

  getShowedImages() {
    let images = [];
    let size = 0;
    if(this.mediaItems.length > 4) {
      for(let i = 0; i < 4; i++) {
        let imageIndex = 0;
        if((this.currentItem + i) >= this.mediaItems.length) {
          imageIndex = this.currentItem + i - this.mediaItems.length;
  
        } else {
          imageIndex = this.currentItem + i;
        }
        images.push(imageIndex);
      }
      return images;
    } else {
      return [...Array(this.mediaItems.length).keys()];
    }
  }
  isImage(imageIndex : any) : boolean {
    let mimetype : string = this.mediaItems[imageIndex]["attributes"]["mime"];
    if(mimetype.indexOf('image') !== -1) {
      return true;
    }

    return false;
  }

  isVideo(index: any) : boolean {
    let mimetype : string  = this.mediaItems[index]["attributes"]["mime"];
    if(mimetype.indexOf('video') !== -1) {
      return true;
    }

    return false;
  }
}
