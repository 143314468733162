<div *ngIf="uploadConfig" class="file-input-container">
  <input
    type="file"
    [id]="'fileInput' + uploadConfig.field"
    class="file-input"
    (change)="onUpload($event)"
    [accept]="calculateAcceptTypes()"
    multiple
  />

  <label style="display: inline-flex; width: 100%; cursor: pointer" [for]="'fileInput' + uploadConfig.field">
    <div class="uploaded-files">{{ !uploadedFileNames ? placeholder : uploadedFileNames.join(", ") }}</div>
    <div class="file-label">
      <span class="file-label-text">Dateien durchsuchen {{ files.length }}/{{ uploadLimit }}</span>
    </div>
  </label>
  <div
    class="d-flex; flex-row; justify-between; w-full; gap-3"
    style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; gap: 12px"
  >
    <span class="file-type-label">
      Erlaubte Dateiformate: @for (type of allowTypes; track $index) {
      {{ displayAcceptMap[type] }}
      }
    </span>
    <span class="selected-label"
      >Ausgewählt: {{ selectedFiles.length }}/@if (selectLimit) {{{selectLimit}}}@else {*}</span
    >
  </div>
</div>
<div class="files-container">
  <app-file-card
    *ngFor="let file of files"
    [file]="file"
    (deleted)="onDeleted($event)"
    (selected)="onFileSelect($event)"
    [isSelected]="isSelected(file)"
  >
  </app-file-card>
</div>
