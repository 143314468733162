import { Component, HostListener, OnInit, ElementRef  } from "@angular/core"
import { ApiService } from "../../api.service"
import { MarkdownService } from "ngx-markdown"
import { ActivatedRoute, Router } from "@angular/router"
import { filter } from "rxjs"
import { Schwerpunkte } from "../shared/schwerpunkte"
import { Title, Meta } from '@angular/platform-browser';
import { writeMeta } from '../shared/meta';
import * as qs from 'qs';

@Component({
  selector: "app-exhibitor-index",
  templateUrl: "./schlagwort-index.component.html",
  styleUrls: ["./schlagwort-index.component.scss"],
})
export class SchlagwortIndexComponent implements OnInit {
  imgBaseUrl = ""
  data: object | any = null
  // letterList: string[] = ["Alle", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
  // schlagworte = Schlagworte.schlagworte;
  schwerpunkte = Schwerpunkte.schwerpunkte;
  // schwerpunkteWithAll = Schwerpunkte.schwerpunkteWithAll;
  // schwerpunkteColorMapping = Schwerpunkte.schwerpunkteColorMapping;
  search = {
    schwerpunkt:"Alle",
    letter: "Alle",
    schlagwort: "Alle",
    name: "",
    sort: "name", // name | koje
    page: 0,
    maxPages: 0,
  }

  results = 0;
  isInitialSearch = true;
  id:string = "";

  protected readonly filter = filter

  constructor(private markdownService: MarkdownService,
              private apiService: ApiService, private elementRef: ElementRef,
              private title: Title,
              private meta: Meta, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.imgBaseUrl = this.apiService.apiUrl

    this.checkScreenSize();

    this.resetForm();
    // this.filterByComplex({ ... this.search }, 0);
    this.route.params.subscribe(params => {
      if(params && params["id"]) {
        this.id = params["id"]

        this.apiService.getExhibitorBySchlagwortNeu(this.id).subscribe(
          (data: any) => {
            console.log("result", data)
            this.data = data;
          })
      }

    })


    writeMeta(this.title, this.meta, "Aussteller von A bis Z",
      "BeSt Aussteller von A bis Z",
      "",
      false,
      null);
  }
  
  populateData(data:any) {
    this.data = data
    this.results = data.meta.pagination.total;
    this.search.page = data.meta.pagination.page;
    this.search.maxPages = data.meta.pagination.pageCount;
    console.log("this.search", this.search)
  }

  debugOut(data: any) {
    console.log(data)
    return data
  }

  protected isLargeScreen : boolean = true;
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    this.isLargeScreen = window.innerWidth >= 1024;
  }
  containsSchwerpunkt(item:any, schwerpunkt:string) {
    return Schwerpunkte.containsSchwerpunkt(item, schwerpunkt);
  }

  onDropdownChange(): void {
    console.log("onDropdownChange", this.search);
    const query = this.apiService.makeQuery({ ... this.search }, 0);
    const queryString = qs.stringify(query);
    console.log("qs", decodeURIComponent(queryString))
    // this.filterByComplex({ ... this.search }, 0);
  }

  onSearch() {
    const query = this.apiService.makeQuery({ ... this.search }, 0);
    const queryString = qs.stringify(query);
    console.log("qs", this.search, query, decodeURIComponent(queryString))
    // this.filterByComplex({ ... this.search }, 0);
  }

  onReset() {
    this.resetForm();
    // this.filterByComplex({ ... this.search }, 0);
  }
  resetForm() {
    this.data = null;
    this.search = {
      schwerpunkt:"Alle",
      letter: "Alle",
      schlagwort: "Alle",
      name: "",
      sort: "name", // name | koje
      page: 0,
      maxPages: 0,
    }
  }

  filterBySchlagwort(schlagwort: string) {
    console.log("sending getExhibitorBySchlagwortNeu", schlagwort)
    this.apiService.getExhibitorBySchlagwortNeu(schlagwort).subscribe(
      (data: any) => {
        console.log("data getExhibitorBySchlagwortNeu", data)
        this.data = data
        this.results = data.data.length;
      },
      (error: any) => {
        console.error(error)
      },
    )
  }


}
