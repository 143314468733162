<span *ngIf="title">{{title}}</span>
<div *ngFor="let component of galleryComponents">
  <div *ngIf="component.type === ComponentTypeEnum.Image" class="grid-image-container">
    <div *ngFor="let imageComponent of component.data">
      <img [src]="baseUrl + imageComponent['attributes']['url']">
    </div> 
  </div> 
  <div *ngIf="component.type === ComponentTypeEnum.RichText" [innerHTML]="component.data" class="richText">
  </div>
</div>
