@if (!viewHtml) {
<app-form-field
  [required]="required"
  [label]="label"
  [helpText]="helpText"
  [control]="textInput"
  [labelPosition]="labelPosition"
  [labelClass]="labelClass"
>
  <div class="form-field-input" [class.form-field-disabled]="disabled">
    <div class="flex flex-col w-full">
      <ngx-editor-menu [editor]="editor" [toolbar]="toolbar" [colorPresets]="colorPresets" />

      <ngx-editor
        [editor]="editor"
        #textInput="ngModel"
        [(ngModel)]="value"
        (ngModelChange)="valueChange.emit(value)"
        [ngModelOptions]="{ standalone: standalone }"
        [name]="name ?? label.toLowerCase()"
        [required]="required"
        [disabled]="disabled"
        outputFormat="html"
        [placeholder]="placeholder ?? ''"
        class="rich-text w-full h-full"
      />
    </div>
  </div>

  <ng-container error>
    {{ "common.validation-error.invalid-value" }}
  </ng-container>
</app-form-field>
} @else {
<app-form-field
  [required]="required"
  [label]="label"
  [helpText]="helpText"
  [control]="textInput"
  [labelPosition]="labelPosition"
  [labelClass]="labelClass"
>
  <div class="form-field-input" [class.form-field-disabled]="disabled">
    <textarea
      [(ngModel)]="value"
      (ngModelChange)="valueChange.emit(value)"
      #textInput="ngModel"
      [ngModelOptions]="{ standalone: standalone }"
      [name]="name ? name : label.toLowerCase()"
      [required]="required"
      [disabled]="disabled"
      [placeholder]="placeholder ?? ''"
      style="min-height: 120px"
    ></textarea>
  </div>
</app-form-field>
}
