// src/app/html-format.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'htmlFormat'
})
export class HtmlFormatPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    // Replace <i> and <b> tags with their HTML equivalents
    const formattedValue = value
      .replace(/<i>(.*?)<\/i>/g, '<i>$1</i>')
      .replace(/<b>(.*?)<\/b>/g, '<b>$1</b>');

    // Sanitize the resulting HTML string
    return this.sanitizer.bypassSecurityTrustHtml(formattedValue);
  }
}