import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addSpanToH1'
})
export class AddSpanToH1Pipe implements PipeTransform {

  transform(htmlString: any, ...args: unknown[]): unknown {
    console.log(htmlString)
    console.log(args)
    if(!htmlString || htmlString == "") return ""
    const domParser = new DOMParser();
    const html = domParser.parseFromString(htmlString, 'text/html');
    const h1s = html.querySelectorAll('h1');
    h1s.forEach(h1 => {
      const span = document.createElement('span');
      span.innerHTML = h1.innerHTML;
      h1.innerHTML = '';
      h1.appendChild(span);
    });
    const serializer = new XMLSerializer();
    return serializer.serializeToString(html); 
  }

}
