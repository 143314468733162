<div class="stage-container">
  <div class="title-container">
    <h1>
      {{ page?.page?.data?.attributes?.Title ?? "" }}
    </h1>
  </div>
  <div class="content">
    <div class="html" [innerHTML]="page?.page?.data?.attributes?.HtmlContent ?? ''"></div>
  </div>
  <p *ngIf="page?.Untertitel" style="margin-bottom: 0" class="before-underline">{{ page?.Untertitel }}</p>
  <h3 *ngIf="page?.Titel" class="page-title">{{ page?.Titel }}</h3>
  <div class="stage-info-flex" *ngIf="initialLoadDone">
    <app-stage-selector
      *ngIf="stages"
      [stages]="stages"
      (onStageSelected)="stageSelected($event)"
      [location]="page?.Veranstaltungsort ?? ''"
      [selectedStage]="selectedStage"
    ></app-stage-selector>
    <div class="day-selector-container">
      <app-day-selector
        *ngIf="days"
        [days]="days"
        (onDaySelected)="daySelected($event)"
        [selectedDay]="selectedDay"
      ></app-day-selector>
      <div class="information-options-box">
        <div class="important-information-container">
          <p class="information-option-title">Wichtige Informationen</p>
          <div class="information-container">
            @for (item of page?.Infos; track $index) {
            <app-information-item [item]="item"> </app-information-item>
            }
          </div>
        </div>
        <div class="options-container">
          <p class="information-option-title">Zusätzliche Optionen</p>
          <div class="option">
            <app-toggle [formControl]="signLanguageCtrl"></app-toggle>
            <p>Vorträge mit Gebärdensprache</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="selectedDay || selectedStage" class="filter-container">
    <h3 class="active-filter">Filter</h3>
    <div class="filter-tags">
      <span class="filter-tag" *ngIf="selectedDay">
        {{ selectedDay.attributes.Datum }}
        <button (click)="onRemoveDayFilter()"  class="remove-filter-tag"><svg width="20px" height="20px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="grey"><path d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg></button>
      </span>
      <span class="filter-tag" *ngIf="selectedStage">
        {{ selectedStage.attributes.Name }}
        <button (click)="onRemoveSelectedStage()"  class="remove-filter-tag"><svg width="20px" height="20px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="grey"><path d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg></button>
      </span>
    </div>
  </div>
  <app-speech-list
    #speechList
    (panelOpenStateChanged)="openPanelId = $event"
    *ngIf="speeches"
    [items]="speeches"
    [stageTitle]="!selectedStage ? 'ALLe BüHNEN' : 'BüHNE ' + stageCounter(selectedStage)"
    [dayTitle]="selectedDay?.attributes?.Wochentag ?? ''"
    [dayDate]="(selectedDay?.attributes?.Datum ?? '' | date : 'dd.MM.') ?? ''"
    [stages]="stages"
    (goToPage)="goToPage($event)"
    [pagination]="pagination"
    [startTimeSelector]="startTimeSelector"
    [endTimeSelector]="endTimeSelector"
    [titleSelector]="titleSelector"
    [subTitleSelector]="subTitleSelector"
    [institutionSelector]="institutionSelector"
    [daySelector]="daySelector"
    [dateSelector]="dateSelector"
    [speakersSelector]="speakersSelector"
    [descriptionSelector]="descriptionSelector"
    [locationSelector]="locationSelector"
    [iconItems]="listIconItems"
    [sortOptions]="listSortOptions"
    [customPanelTemplate]="customPanelTemplate"
  ></app-speech-list>
  <ng-template #customPanelTemplate let-item>
    @if(item.attributes.GebaerdendolmetscherIn) {
      <div class="sign-language-icon">
        <img src="../../assets/gebaerdensprache-icon.svg" />
      </div>
      }
  </ng-template>
</div>
<div #dynamicContent></div>
