import { Component, Input } from "@angular/core"

@Component({
  selector: "app-contacts",
  templateUrl: "./contacts.component.html",
  styleUrl: "./contacts.component.scss",
})
export class ContactsComponent {
  @Input() contacts?: any[]
}
