<app-breadcrump-nav [url]="getUrl()"></app-breadcrump-nav>
<div *ngIf="data?.data" class="exhibitor-detail" lang="de">
  <!-- Assigning data.bla.item to a variable `item` -->
  <ng-container *ngIf="data!.data as item">
    <span class="exhibitor-name">{{ item!.attributes!.name }}</span>
    <div class="detail-header">
      <div class="detail-element-1">
        <p class="header-title">Standnummer:</p>
          <h2 class="koje" [ngStyle]="{'--koje-color': getColorForBereich(item!.attributes!.bereich | splitCode)}">{{ item!.attributes!.bereich | splitCode }}{{ item!.attributes!.koje }}</h2>          
      </div>
      <div class="detail-element-2" *ngIf="item?.attributes?.icon?.data">
        <p class="header-title title-icon">Aussteller:</p>
        <img class="aussteller-icon" [src]="imgBaseUrl + item?.attributes?.icon?.data?.attributes?.formats?.thumbnail?.url"
               [alt]="item?.attributes?.icon?.data?.attributes.alternativeText"
               *ngIf="item?.attributes?.icon?.data?.attributes?.formats?.thumbnail?.url">
        <img class="aussteller-icon" [alt]="'Kein Bild vorhanden'"
               *ngIf="!item?.attributes?.icon?.data?.attributes?.formats?.thumbnail?.url">
      </div>
      <div class="detail-element" *ngIf="item!.attributes!.schwerpus!.data.length > 0">
        <p class="header-title">Schwerpunkte:</p>
        <div class="schwerpunkte">
          <div *ngFor="let schwerpunkt of item!.attributes!.schwerpus!.data" class="schwerpunkt-container">
            <svg class="schwerpunkt-indicator {{schwerpunkt!.attributes!.Schwerpunkt}} {{containsSchwerpunkt(item, schwerpunkt!.attributes!.Schwerpunkt)}}" xmlns="http://www.w3.org/2000/svg" width="64.824" height="74.085" viewBox="0 0 17.72 19.288"><defs><clipPath
              id="a" clipPathUnits="userSpaceOnUse"><path d="M-882 221H253v-719H-882Z" /></clipPath></defs><path 
              [ngStyle]="{'fill' : getColorFromSchwerpunkt(schwerpunkt!.attributes!.Schwerpunkt)}"
              d="m0 0 36-63h-72Z" clip-path="url(#a)"
              style="fill:#000 icc-color(sRGB-IEC61966-2,.1,0,0,0);fill-opacity:1;fill-rule:nonzero;stroke:none"
              transform="matrix(0 .28127 .28127 0 17.72 9.524)" />
            </svg>  
            <span class="schwerpunkt-text" [innerHTML]="schwerpunkt!.attributes!.Schwerpunkt | formatSchwerpunktText"></span>
          </div>
        </div>
      </div>
    </div>
    <figure *ngIf="item!.attributes!.lageplan?.data">
      <span class="fancy-image lageplan">
        <img class="img-fluid" [src]="imgBaseUrl + item!.attributes!.lageplan!.data!.attributes.url"
             [alt]="item!.attributes!.lageplan!.data!.attributes.alternativeText">
      </span>
    </figure>

    <div class="content-details">
      <div [innerHTML]="decodeContent(item!.attributes!.Allgemeine_Info_Einfuehrung)"></div>
      <div class="html-content" [innerHTML]="convertForMobile(rteContent)"></div>
      <div class="aussteller-link" *ngIf="item!.attributes!.www">
        <div class="info-link">
          <a [href]="item!.attributes!.www" target="_blank">
            <span>
              <p class="exhibitor-link">{{ item!.attributes!.www }}</p>
            </span>
            <img src="./assets/arrow-thin-top-right-corner-icon.svg" class="arrow">
          </a>
        </div>
        <div class="social-media-container" *ngIf="item!.attributes!.Instagram || item!.attributes!.Twitter_X || item!.attributes!.Facebook || item!.attributes!.YouTube">
          <span class="social-media-text">
            Folgen Sie uns auf
          </span>
          <div class="social-media-icons">
            <figure *ngIf="item!.attributes!.Instagram">
              <a [href]="item!.attributes!.Instagram" target="_blank">
                <svg width="38px" height="38px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" 
                  stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3 16V8C3 5.23858 5.23858 3 8 3H16C18.7614 3 21 5.23858 21 8V16C21 18.7614 18.7614 21 16 21H8C5.23858 21 3 18.7614 3 16Z" stroke="#000000" stroke-width="1.5"></path><path d="M17.5 6.51L17.51 6.49889" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </a>
            </figure>
            <figure *ngIf="item!.attributes!.Twitter_X">
              <a [href]="item!.attributes!.Twitter_X" target="_blank">
                <svg width="38px" height="38px" viewBox="0 0 24 24" stroke-width="1.5" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000">
                  <path d="M16.8198 20.7684L3.75317 3.96836C3.44664 3.57425 3.72749 3 4.22678 3H6.70655C6.8917 3 7.06649 3.08548 7.18016 3.23164L20.2468 20.0316C20.5534 20.4258 20.2725 21 19.7732 21H17.2935C17.1083 21 16.9335 20.9145 16.8198 20.7684Z" 
                    stroke="#000000" stroke-width="1.5"></path><path d="M20 3L4 21" stroke="#000000" stroke-width="1.5" stroke-linecap="round">
                  </path>
                </svg>
              </a> 
            </figure>
            <figure *ngIf="item!.attributes!.Facebook">
              <a [href]="item!.attributes!.Facebook" target="_blank">
                <svg width="38px" height="38px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M17 2H14C12.6739 2 11.4021 2.52678 10.4645 3.46447C9.52678 4.40215 9 5.67392 9 7V10H6V14H9V22H13V14H16L17 10H13V7C13 6.73478 13.1054 6.48043 13.2929 6.29289C13.4804 6.10536 13.7348 6 14 6H17V2Z" 
                  stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </a>
            </figure>
            <figure *ngIf="item!.attributes!.YouTube">
              <a [href]="item!.attributes!.YouTube" target="_blank">
                <svg width="38px" height="38px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M14 12L10.5 14V10L14 12Z" fill="#000000" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M2 12.7075V11.2924C2 8.39705 2 6.94939 2.90549 6.01792C3.81099 5.08645 5.23656 5.04613 8.08769 4.96549C9.43873 4.92728 10.8188 4.8999 12 4.8999C13.1812 4.8999 14.5613 4.92728 15.9123 4.96549C18.7634 5.04613 20.189 5.08645 21.0945 6.01792C22 6.94939 22 8.39705 22 11.2924V12.7075C22 15.6028 22 17.0505 21.0945 17.9819C20.189 18.9134 18.7635 18.9537 15.9124 19.0344C14.5613 19.0726 13.1812 19.1 12 19.1C10.8188 19.1 9.43867 19.0726 8.0876 19.0344C5.23651 18.9537 3.81097 18.9134 2.90548 17.9819C2 17.0505 2 15.6028 2 12.7075Z" 
                  stroke="#000000" stroke-width="1.5"></path>
                </svg>
              </a>
            </figure>
          </div>
        </div>
      </div>
      <div>
        <figure *ngIf="item!.attributes!.banner?.data">
          <span class="fancy-image lageplan banner">
            <img class="img-fluid" [src]="imgBaseUrl + item!.attributes!.banner!.data!.attributes.url"
                 [alt]="item!.attributes!.banner!.data!.attributes.alternativeText">
          </span>
        </figure>
      </div>
      <!--    <h2>Schwerpunkte</h2>-->
      <!--    <ul>-->
      <!--      <li *ngFor="let schwerpunkt of item!.attributes!.schwerpunkte">{{ schwerpunkt }}</li>-->
      <!--    </ul>-->

      <div *ngIf="item!.attributes!.Angebot_auf_der_BeSt">
        <h5 class="title">
            Angebot auf der BeSt
        </h5>
        <div class="html-content" [innerHTML]="decodeContent(item!.attributes!.Angebot_auf_der_BeSt)"></div>
      </div>
      <div *ngIf="item!.attributes!.Schwerpunkte_Beratungsangebot">
        <h5 class="title">
            Schwerpunkte des Beratungsangebots
        </h5>
        <div class="html-content" [innerHTML]="item!.attributes!.Schwerpunkte_Beratungsangebot"></div>
      </div>
      <div *ngIf="item!.attributes!.Schwerpunkte_Bildungsangebot">
        <h5 class="title">
            Schwerpunkte des Bildungsangebots
        </h5>
        <div class="html-content" [innerHTML]="decodeContent(item!.attributes!.Schwerpunkte_Bildungsangebot)"></div>
      </div>
      <div *ngIf="item!.attributes!.Zielgruppen">
        <h5 class="title">
          Zielgruppen
        </h5>
        <div class="html-content" [innerHTML]="decodeContent(item!.attributes!.Zielgruppen)"></div>
      </div>
      <div *ngIf="item!.attributes!.Aufnahmebedingungen">
        <h5 class="title">
          Aufnahmebedingungen
        </h5>
        <div class="html-content" [innerHTML]="decodeContent(item!.attributes!.Aufnahmebedingungen)"></div>
      </div>
      <div *ngIf="item!.attributes!.Kosten">
        <h5 class="title">
          Kosten
        </h5>
        <div class="html-content" [innerHTML]="decodeContent(item!.attributes!.Kosten)"></div>
      </div>
      <div *ngIf="item!.attributes!.Dauer_Ausbildung_Weiterbildung" lang="de">
        <h5 class="title">
          Dauer der Ausbildung / Weiterbildung
        </h5>
        <div class="html-content" [innerHTML]="decodeContent(item!.attributes!.Dauer_Ausbildung_Weiterbildung)"></div>
      </div>
      <div *ngIf="item!.attributes!.Abschluss">
        <h5 class="title">
          Abschluss
        </h5>
        <div class="html-content" [innerHTML]="decodeContent(item!.attributes!.Abschluss)"></div>
      </div>
      <div *ngIf="item!.attributes!.Dokumente.length > 0">
        <h5 class="title">
          Informationsmaterial
        </h5>
        <div>
          <div *ngFor="let dokument of item!.attributes!.Dokumente.data">
            <a [href]="imgBaseUrl + dokument.attributes.url" target="_blank">
              <span *ngIf="dokument.attributes.ext === '.pdf'">
                <img src="./assets/pdf-file-icon.svg" class="file-icon">
              </span>
              <span *ngIf="dokument.attributes.ext !== '.pdf'">
                <img src="./assets/file-line-icon.svg" class="file-icon">
              </span>
              {{getFileText(dokument)}}
            </a>  
          </div>
        </div>
      </div>
      <div class="details-footer">
        <div>
          <!-- Example: accessing name property if it exists -->
          <ng-container *ngIf="item!.attributes!.tel || item!.attributes!.email || item!.attributes!.www">
            <h5 class="title">Kontakt</h5>
            <p [innerHTML]="renderContactBlock(item!.attributes!.tel, item!.attributes!.email, item!.attributes!.www)">
            </p>
          </ng-container>
          <ng-container *ngIf="item!.attributes!.tel2 || item!.attributes!.email2 || item!.attributes!.www2">
            <h5 class="title">Kontakt - Weitere</h5>
            <p [innerHTML]="renderContactBlock(item!.attributes!.tel2, item!.attributes!.email2, item!.attributes!.www2)">
            </p>
          </ng-container>
          <ng-container *ngIf="item!.attributes!.tel3 || item!.attributes!.email3 || item!.attributes!.www3">
            <h5 class="title">Kontakt - Weitere</h5>
            <p [innerHTML]="renderContactBlock(item!.attributes!.tel3, item!.attributes!.email3, item!.attributes!.www3)">
            </p>
          </ng-container>
        </div>
        <div class="details-footer-addesse">
          <h5 class="title">Adresse</h5>
          <p class="html-content">
            <span *ngIf="item!.attributes!.country">{{ item!.attributes!.street }}<br></span>
            {{ item!.attributes!.zipcode }} {{ item!.attributes!.city }}<br>
            <span *ngIf="item!.attributes!.country && item!.attributes!.country!.data">{{item!.attributes!.country!.data.attributes.Name}}</span>
          </p>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<messebereich-info 
  title="<p> <strong> MESSEBEREICHE / </strong> <i> FARBLEITSYSTEME </i> </p>" 
  text="<p> Die Messekojen sind farblich gekennzeichneten Bereichen zugeordnet, deren genaue Bezeichnungen
  auf der Seite Hallenplan zu finden sind. Die Anfangsbuchstaben der Bereichsnamen sind Teil der
  Kojenummern. Die jeweilige Bereichsfarbe scheint im Messeplan und auf den Blenden der Kojen auf.</p>">
</messebereich-info>