<p class="title">Datum</p>
<div class="days-box">
  @for (day of days; track $index) {
  <div class="day-container" [ngClass]="selectedDay?.id === day.id ? 'selected' : ''" (click)="selectDay(day)">
    <div class="day-counter">TAG {{ dayCounter(day) }}</div>
    <div class="day-date-container">
      {{ weekday(day.attributes) }}
      <br />
      {{ day.attributes.Datum | date : "dd.MM.YYYY" }}
    </div>
  </div>
  }
</div>
<p class="sub-title">Bitte Datum auswählen</p>