import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormHelper } from 'src/app/form-helper';

@Component({
  selector: 'app-best-input',
  templateUrl: './best-input.component.html',
  styleUrl: './best-input.component.scss'
})
export class BestInputComponent implements OnInit {
  @Input({required: true})
  label!: string

  @Input()
  type: string = "text"

  @Input({required: true})
  formGroup!: FormGroup

  @Input({required: true})
  controlName!: string

  @Input()
  infoText?: string

  @Input()
  placeholder: string = ""

  @Input() 
  required: boolean = false

  @Input()
  validatorErrors: { key: string, errorText: string }[] = []

  fh?: FormHelper

  ngOnInit(): void {
    this.fh = new FormHelper(this.formGroup)
  }
}
