import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { NotFoundComponent } from "./not-found/not-found.component"
import { ExhibitorIndexComponent } from "./exhibitor-index/exhibitor-index.component"
import { ExhibitorDetailComponent } from "./exhibitor-detail/exhibitor-detail.component"
import { SitemapComponent } from "./sitemap/sitemap.component"
import { NotFoundGuard } from "./not-found.guard"
import { PageComponent } from "./page/page.component"
import { UnlockComponent } from "./unlock/unlock.component"
import { PasswordGuard } from "./password.guard"
import { SchlagwortIndexComponent } from "./schlagwort-index/schlagwort-index.component"
import { SchwerpunktIndexComponent } from "./schwerpunkt-index/schwerpunkt-index.component"
import { RegisterComponent } from "./register/register.component"
import { LoginComponent } from "./login/login.component"
import { ExhibitorFormComponent } from "./exhibitor-form/exhibitor-form.component"
import { AuthGuard } from "./auth.guard"
import { UnAuthGuard } from "./unauth.guard"
import { LogoutComponent } from "./logout/logout.component"
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component"
import { ResetPasswordComponent } from "./reset-password/reset-password.component"
import { EmptyTopMenuOverviewComponent } from "./empty-menu-overview/empty-menu-overview.component"
import { StageComponent } from './stage/stage.component';
import { GalleryComponent } from "./gallery/gallery.component"
// import { ExhibitorConverterComponent } from "./exhibitor-converter/exhibitor-converter.component"
import { WorkshopComponent } from "./workshop/workshop.component"

const routes: Routes = [
  // {
  //   path: 'convert',
  //   pathMatch: 'full',
  //   component: ExhibitorConverterComponent,
  // },
  {
    path: "forgot-password",
    pathMatch: "full",
    component: ForgotPasswordComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: "reset-password",
    pathMatch: "full",
    component: ResetPasswordComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: "logout",
    pathMatch: "full",
    component: LogoutComponent,
    canActivate: [PasswordGuard, AuthGuard],
  },
  {
    path: "selfservice",
    pathMatch: "full",
    component: ExhibitorFormComponent,
    canActivate: [PasswordGuard, AuthGuard],
  },
  {
    path: "register",
    pathMatch: "full",
    component: RegisterComponent,
    canActivate: [PasswordGuard, UnAuthGuard],
  },
  {
    path: "login",
    pathMatch: "full",
    component: LoginComponent,
    canActivate: [PasswordGuard, UnAuthGuard],
  },
  {
    path: "unlock",
    pathMatch: "full",
    component: UnlockComponent,
  },
  {
    path: 'programm',
    pathMatch: 'full',
    component: StageComponent,
    canActivate: [PasswordGuard]
  },
  {
    path: 'programm/messeprogramm/tag/:tag/buehne/:buehne',
    pathMatch: 'full',
    component: StageComponent,
    canActivate: [PasswordGuard]
  },
  {
    path: 'programm/workshops/workshops',
    component: WorkshopComponent,
    canActivate: [PasswordGuard]
  },
  {
    path: 'programm/workshops/tag/:tag',
    pathMatch: 'full',
    component: WorkshopComponent,
    canActivate: [PasswordGuard]
  },
  {
    path: "sitemap",
    pathMatch: 'full',
    component: SitemapComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: "sitemap",
    pathMatch: "full",
    component: SitemapComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: "aussteller-2024/schlagworte/:schlagworte/schwerpunkte/:schwerpunkte",
    pathMatch: "full",
    component: ExhibitorIndexComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: "aussteller-2024/schlagworte/:schlagworte/schwerpunkte/:schwerpunkte",
    pathMatch: "full",
    component: ExhibitorIndexComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: "best-besuch",
    pathMatch: "full",
    component: EmptyTopMenuOverviewComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: "galerie",
    pathMatch: "full",
    component: GalleryComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: "best-besuch/messebesuch",
    pathMatch: "full",
    component: EmptyTopMenuOverviewComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: "best-besuch/lehrkraefte",
    pathMatch: "full",
    component: EmptyTopMenuOverviewComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: "best-besuch/bildungsinteressierte",
    pathMatch: "full",
    component: EmptyTopMenuOverviewComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: "programm/messeprogramm",
    pathMatch: "full",
    component: EmptyTopMenuOverviewComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: "programm/livestream",
    pathMatch: "full",
    component: EmptyTopMenuOverviewComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: "programm/workshops",
    pathMatch: "full",
    component: EmptyTopMenuOverviewComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: "anreise",
    pathMatch: "full",
    component: EmptyTopMenuOverviewComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: "anreise/anfahrt",
    pathMatch: "full",
    component: EmptyTopMenuOverviewComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: "anreise/messegelaende",
    pathMatch: "full",
    component: EmptyTopMenuOverviewComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: "aussteller",
    pathMatch: "full",
    component: EmptyTopMenuOverviewComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: "aussteller/services",
    pathMatch: "full",
    component: EmptyTopMenuOverviewComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: "aussteller/anmeldung",
    pathMatch: "full",
    component: EmptyTopMenuOverviewComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: "best-besuch/messebesuch/aussteller-2024",
    pathMatch: "full",
    component: ExhibitorIndexComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: "best-besuch/aussteller",
    pathMatch: "full",
    component: ExhibitorIndexComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: "best-besuch/aussteller/:id",
    pathMatch: "full",
    component: ExhibitorDetailComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: "schlagwort/:id",
    pathMatch: "full",
    component: SchlagwortIndexComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: "schwerpunkt/:id",
    pathMatch: "full",
    component: SchwerpunktIndexComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: "schwerpunkt/:id",
    pathMatch: "full",
    component: SchwerpunktIndexComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: "404",
    pathMatch: "full",
    component: NotFoundComponent,
  },
  {
    path: "",
    component: PageComponent,
    pathMatch: "full",
    data: { slug: "Info" },
    canActivate: [PasswordGuard],
  },

  {
    path: ":seg1/:seg2/:slug",
    pathMatch: 'full',
    component: PageComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: ":seg1/:slug",
    pathMatch: 'full',
    component: PageComponent,
    canActivate: [PasswordGuard],
  },
  {
    path: ":slug",
    //pathMatch: 'full',
    component: PageComponent,
    canActivate: [PasswordGuard, NotFoundGuard],
  },
  {
    path: "**",
    pathMatch: "full",
    component: NotFoundComponent,
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
