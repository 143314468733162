import { Component } from "@angular/core"
import { Router } from "@angular/router"
import { AuthenticationService } from "src/authentication.service"

@Component({
  selector: "app-logout",
  templateUrl: "./logout.component.html",
  styleUrl: "./logout.component.scss",
})
export class LogoutComponent {
  constructor(private router: Router, private authService: AuthenticationService) {
    this.authService.logout()
    this.router.navigateByUrl("/")
  }
}
