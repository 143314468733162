import { Component, Input, OnInit  } from '@angular/core';
import { ApiService } from 'src/api.service';

interface Breadcrumb {
  text: string;
  url: string;
}

@Component({
  selector: 'app-breadcrump-nav',
  templateUrl: './breadcrump-nav.component.html',
  styleUrls: ['./breadcrump-nav.component.scss']
})
export class BreadcrumpPropertiesNavComponent implements OnInit {
  @Input()
  url: any;
  breadcrumbs: Breadcrumb[] = [];

  constructor(private apiService: ApiService) { }
  ngOnInit() {
    this.apiService.getMenu().subscribe({
      error: (error: any) => { console.log(error); }, 
      next: (data: any) => {              
        let parts = this.url.firstChild.snapshot.url.map((segment: any) => segment.path);
        let fullUrl = '';
        let menuSubData = data.data;
        const headerItem = data.data.find((a: any) => a.attributes.Url.includes(parts[0])); 
        if(headerItem) {
          fullUrl += '/' + parts[0];        
          let text = headerItem.attributes.Label.replace(/(<([^>]+)>)/ig, '');
          this.breadcrumbs.push({
            text: text.charAt(0) + text.substring(1).toLowerCase(),
            url: fullUrl
          });
          parts = parts.slice(1);
          menuSubData = headerItem.attributes.subItems;
        }

        for(const part of parts) {
          fullUrl += '/' + part;        
          let subItem = menuSubData.find((a: any) => a.Url.substring(a.Url.lastIndexOf('/')+1) === part);
          if(subItem) {
            let text = subItem.Label.replace(/(<([^>]+)>)/ig, '')
            this.breadcrumbs.push({
              text: text.charAt(0) + text.substring(1).toLowerCase(),
              url: fullUrl
            });
            menuSubData = subItem.subItems;
          } else {
            this.breadcrumbs.push({
              text: part,
              url: fullUrl
            })
          } 
        }
      },   
    });
  }

  getFont(breadcrump: string) {
    return this.breadcrumbs[this.breadcrumbs.length-1].text === breadcrump ?  'bold' : '';
  }

  getBreadcrumpText(title: any) {
    console.log("ge text");
    title = title.replace(/(<([^>]+)>)/ig, '')
    return title.charAt(0) + title.substring(1).toLowerCase()
  }
}