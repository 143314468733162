<form [formGroup]="formGroup" *ngIf="fh?.getControl(controlName) && formGroup">
    <label class="input-label"> {{ label }}<b *ngIf="required" style="color: red"> *</b></label>
    <input [type]="type" [formControlName]="controlName" [placeholder]="placeholder"/>
    <label *ngIf="infoText" class="info">{{ infoText }}</label>
    @if(fh?.controlInvalidAndTouched(controlName)){
        @for (validatorError of validatorErrors; track $index) {
            <span class="error-text" *ngIf="fh?.controlError(controlName, validatorError.key)">{{ validatorError.errorText }}</span>
        }
    }
    <ng-content></ng-content>
</form>
