<div class="card-gallery">
  <div class="subtitle-container">
    <div>
      <img class="triangle-right" src="./assets/triangle-up.svg">
      <img class="triangle-right" src="./assets/triangle-up.svg">
      <img class="triangle-right" src="./assets/triangle-up.svg">
    </div>
    <span class="subTitle" [innerHTML]="subTitle"></span>
  </div>
  <div class="gallery-title" [innerHTML]="convertTitle(title)"></div>
</div>

<div class="gallery-container">
  <span *ngFor="let card of cards!" class="gallery-item-container">
    <a [href]="card['Link']['Url']" target="_blank">
      <img *ngIf="card['Image']['data']" [src]="imgBaseUrl + card['Image']['data']['attributes']['url']" class="item-img">
      <!--<div *ngIf="!card['Image']['data'] && card['Text']" class="text-container underline-animation" [innerHTML]="card['Text']"></div>-->
      <div *ngIf="!card['Image']['data'] && card['Text']" class="text-container underline-animation" [innerHTML]="convertCardText(card['Text'])"></div>
      <div *ngIf="!card['Image']['data'] && card['Text']" class="triangle-container">
        <img class="triangle-right" src="./assets/triangle-up.svg">
        <img class="triangle-right" src="./assets/triangle-up.svg">
        <img class="triangle-right" src="./assets/triangle-up.svg">
      </div>
    </a>
  </span>
</div>