<div class="sticky-container">
  <div *ngIf="this.breadcrumbs.length !== 0">
    <a href="/">
      <img src="./assets/home-icon.svg" class="home-icon">
    </a>
    <span *ngFor="let breadcrump of breadcrumbs" [ngStyle]="{'font-weight' : getFont(breadcrump.text)}">
      <a [href]="breadcrump.url">
        > {{breadcrump.text}}
      </a>
    </span>
  </div>
</div>