import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from 'src/api.service';

@Component({
  selector: 'startpage-header-component',
  templateUrl: './startpage-header.component.html',
  styleUrls: ['./startpage-header.component.scss']
})
export class StartPageHeader {
  @Input()
  header = null;
  imgBaseUrl = ""

  constructor(private apiService: ApiService, private sanitizer: DomSanitizer) {   
    this.imgBaseUrl = this.apiService.apiUrl
  }

  getHTMLContent(content: any) {
    if(window.matchMedia("(min-width: 1024px)").matches && window.matchMedia("(max-width: 1350px)").matches) {
      return this.sanitizer.bypassSecurityTrustHtml(content.replace("<h1>", "<h1 style='font-size: 3rem; margin-bottom: 0; margin-top: 0;'>"));
    }

    if(window.matchMedia("(max-width: 1024px)").matches) {
      return this.sanitizer.bypassSecurityTrustHtml(content.replace("<h1>", "<h1 style='font-size: 2rem; margin-bottom: 0; margin-top: 0;'>"));
    }

    return content;
  }

  convetLinkTextForHTML(content: any) {
    return this.sanitizer.bypassSecurityTrustHtml(content.replace("<p>", "<p style='margin-bottom: 0;'>"));
  }
}
