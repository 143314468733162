import { booleanAttribute, Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { mdiCodeBlockTags } from '@mdi/js';
import { Editor, Toolbar, ToolbarDropdown } from 'ngx-editor';
import { Plugin, PluginKey } from 'prosemirror-state';
import { BaseInputDirective } from '../base-input.directive';
import { ControlContainer, NgForm } from '@angular/forms';

export type HtmlString = string

@Component({
  selector: 'app-rich-text-input',
  templateUrl: './rich-text-input.component.html',
  styleUrls: ['./rich-text-input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      deps: [[Optional, NgForm]],
      useFactory: (ngForm: NgForm) => ngForm,
    },
  ],
})
export class RichTextInputComponent extends BaseInputDirective<HtmlString> implements OnInit, OnDestroy {
  protected readonly showAsHtmlIcon = mdiCodeBlockTags;

  dropDown: ToolbarDropdown = {
    heading: ['h1', 'h2', 'h3', 'h4']
  };
  
  @Input()
  toolbar: Toolbar = [
    [this.dropDown],
    ['bold', 'italic', 'underline', 'strike'],
    ['ordered_list', 'bullet_list'],
    ['link', 'blockquote', 'code'],
    ['undo',  'redo']
  ];

  @Input()
  colorPresets = [];

  @Input({ transform: booleanAttribute })
  allowShowHtml = false;

  @Input({ transform: booleanAttribute })
  viewHtml = false;

  protected editor!: Editor;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.editor = new Editor({ linkValidationPattern: '.*' });
    const sanitizePastedHTMLPlugin = new Plugin({
      key: new PluginKey('PastePlugin'),
      props: {
        transformPastedHTML(inputHtml: string): string {
          try {
            const tmp = document.implementation.createHTMLDocument('New').body;
            tmp.innerHTML = inputHtml;
            return tmp.textContent || tmp.innerText || '';
          } catch (e) {
            return inputHtml;
          }
        },
      },
    });
    this.editor.registerPlugin(sanitizePastedHTMLPlugin);
  }

  ngOnDestroy(): void {
    this.editor?.destroy();
  }

  public focus() {
    this.editor.view.focus();
  }
}
