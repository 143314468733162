import { Component, Input } from "@angular/core"
import { ApiService } from "src/api.service"

@Component({
  selector: "app-documents-list",
  templateUrl: "./documents-list.component.html",
  styleUrl: "./documents-list.component.scss",
})
export class DocumentsListComponent {
  @Input()
  title: string = ""
  @Input()
  files: any[] = []

  baseUrl: string

  constructor(private apiService: ApiService) {
    this.baseUrl = apiService.apiUrl
  }

  public name(file: any): string {
    var name: string = file.attributes.name
    var ext: string = file.attributes.ext
    name = name.replace(ext, "")
    return `${name} (${ext.replace(".", "").toUpperCase()})`
  }
}
