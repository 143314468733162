import { Component, HostListener, OnInit, ElementRef, Input, ViewEncapsulation  } from "@angular/core"
import { ApiService } from "../../api.service"
import { MarkdownService } from "ngx-markdown"
import { ActivatedRoute, Router, UrlSegment } from "@angular/router"
import { ReplaySubject, filter, switchMap, takeUntil } from "rxjs"
import { Schwerpunkte } from "../shared/schwerpunkte"
import { Title, Meta } from '@angular/platform-browser';
import { writeMeta } from '../shared/meta';
import * as qs from 'qs';
import { FormControl } from "@angular/forms"
import { Bereiche } from "../shared/bereiche"
import { Schlagworte } from "../shared/schlagworte"

@Component({
  selector: "app-exhibitor-index",
  templateUrl: "./exhibitor-index.component.html",
  styleUrls: ["./exhibitor-index.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ExhibitorIndexComponent implements OnInit {
  imgBaseUrl = ""
  data: object | any = null
  schlagworte = Schlagworte.schlagworte;
  aktiveSchlagworte = []
  letterList: string[] = ["Alle", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
  upperCaseAlphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];
  schwerpunkte = Schwerpunkte.schwerpunkte;
  schwerpunkteWithAll = Schwerpunkte.schwerpunkteWithAll;
  schwerpunkteColorMapping = Schwerpunkte.schwerpunkteColorMapping;
  search = {
    schwerpunkte: ["Alle"],
    letter: "Alle",
    bereiche: ["Alle"],
    schlagworte: ["Alle"],
    name: "",
    sort: "name", // name | koje
    page: 0,
    maxPages: 0,
  }
    
  bereichFullNameMapping : Record<string, string> = {
    "A" : "Allgemeine Beratung & Information",
    "M": "Medien & Kommunikation",
    "B": "Bildungswege für Erwachsene",
    "S": "Sprachen",
    "G": "Gesundheit & Sozialwesen",
    "T": "Technik & Naturwissenschaften",
    "I": "Integrativer Schwerpunkt Barrierefrei",
    "U": "Universitäten Fachhochschulen Pädagogische Ausbildungen",
    "J": "Justiz & Verwaltung",
    "W": "Wirtschaft",
    "K": "Kunst & Kultur",
  };

  bereichNameMappingEntries = Object.entries(this.bereichFullNameMapping);
  selectedBereiche : string[] = [];


  results = 0;
  isInitialSearch = true;

  schlagwortesFilter: FormControl<string | null> = new FormControl<string>("")
  filteredSchlagworte: ReplaySubject<any[]> = new ReplaySubject<any[]>()
  selectedSchlagwort = '';

  protected readonly filter = filter

  constructor(private markdownService: MarkdownService,
              private apiService: ApiService, 
              private route: ActivatedRoute,
              private elementRef: ElementRef,
              private title: Title,
              private meta: Meta) {
  }

  ngOnInit(): void {
    this.imgBaseUrl = this.apiService.apiUrl
    this.markdownService.renderer.heading = (text: string, level: number) => {
      const escapedText = text.toLowerCase().replace(/[^\w]+/g, "-")
      return "<h" + level + ">" +
        "<span>" + text + "</span>" +
        "</h" + level + ">"
    }

    this.checkScreenSize();
    this.resetForm();
    this.route.paramMap.subscribe(paramMap => {
      let paramSchlagworte = paramMap.get('schlagworte')?.split(',');
      let paramSchwerpunkte = paramMap.get('schwerpunkte')?.split(',');

      if(paramSchlagworte?.includes('Alle')) {
        paramSchlagworte = ['Alle'];
      }

      if(paramSchwerpunkte?.includes('Alle')) {
        paramSchwerpunkte = ['Alle'];
      }

      if(paramSchwerpunkte) {
        this.search.schwerpunkte = paramSchwerpunkte.filter(schwerpunkt => Schwerpunkte.schwerpunkteWithAll.includes(schwerpunkt));
      }

      if(paramSchlagworte) {
        this.search.schlagworte = paramSchlagworte.filter(schlagwort => this.schlagworte.includes(schlagwort));
      }

      this.search.letter = '';
      this.filterByComplex({ ... this.search }, 0);
    }) 

    writeMeta(this.title, this.meta, "Aussteller von A bis Z",
      "BeSt Aussteller von A bis Z",
      "",
      false,
      null);

    this.apiService.getAktiveStichworteSchlagworte().subscribe({
        error: (error: any) => { console.log(error); }, 
        next: (data: any) => {
          if(data) {
            this.aktiveSchlagworte = data.message.map((s: any) => s.schlagwort);
          }
          this.filteredSchlagworte.next(this.aktiveSchlagworte.slice());
          this.schlagwortesFilter.valueChanges.pipe().subscribe(() => {
            this.filterSchlagworte();
          })
        }
    });
   
  }
  
  populateData(data:any) {
    this.data = data
    this.results = data.meta.pagination.total;
    this.search.page = data.meta.pagination.page;
    this.search.maxPages = data.meta.pagination.pageCount;
  }

  debugOut(data: any) {
    console.log(data)
    return data
  }

  protected isLargeScreen : boolean = true;
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    this.isLargeScreen = window.innerWidth >= 1024;
  }

  protected filterSchlagworte() {
    if (!this.schlagworte) {
      return
    }

    let search = this.schlagwortesFilter.value
    if (!search) {
      this.filteredSchlagworte.next(this.aktiveSchlagworte.slice())
      return
    } else {
      search = search.toLowerCase()
    }
    this.filteredSchlagworte.next(
      this.aktiveSchlagworte.filter((schlagwort: any) => schlagwort.toLowerCase().indexOf(search!) > -1),
    )
  }

  containsSchwerpunkt(item: any, schwerpunkt: string) {
    return Schwerpunkte.containsSchwerpunkt(item, schwerpunkt);
  }

  onDropdownChange(): void {
    const query = this.apiService.makeQuery({ ... this.search }, 0);
    const queryString = qs.stringify(query);
    console.log("qs", decodeURIComponent(queryString))
    this.filterByComplex({ ... this.search }, 0);
  }

  onDropdownChangeSort(event: Event) {
    const selectedOption = (event.target as HTMLSelectElement).value;
    this.search.sort = selectedOption;
    this.filterByComplex({ ... this.search }, 0);
    let box = document.getElementById('selectOrder') as HTMLSelectElement;
    box.value = 'option1';
  }

  onSchwerpunktSelected(searchValue: any) {
    if(searchValue === 'Alle') {
      this.search.schwerpunkte.length = 0;
    } else {
      this.search.schwerpunkte = this.search.schwerpunkte.filter(schwerpunkt => schwerpunkt !== 'Alle');
    }

    if(this.search.schwerpunkte.includes(searchValue)) {
      return;
    }

    this.search.schwerpunkte.push(searchValue);
    this.updateUrl();
    this.filterByComplex({ ... this.search }, 0);
  }

  onSchlagwortSelected(searchValue: any) {
    if(searchValue === 'Alle') {
      this.search.schlagworte.length = 0;
    } else {
      this.search.schlagworte = this.search.schlagworte.filter(schlagwort => schlagwort !== 'Alle');
    }

    if(this.search.schlagworte.includes(searchValue)) {
      return;
    }

    this.search.schlagworte.push(searchValue);
    this.updateUrl();
    this.filterByComplex({ ... this.search }, 0);
  }

  bereichSelectionChanged(bereiche: any) {
    let bereicheFitlered = bereiche.value.map((bereich:any) => bereich[0]+":"+bereich[1]);
    this.search.bereiche = bereicheFitlered;
    this.filterByComplex({ ... this.search }, 0);
  }

  updateUrl() {
    const schlagworteParam = this.search.schlagworte.length > 0 ? this.search.schlagworte.join(',') : 'Alle';
    const schwerpunkteParam = this.search.schwerpunkte.length > 0 ? this.search.schwerpunkte.join(',') : 'Alle';
    window.history.replaceState({}, '', '/aussteller-2024/schlagworte/' + schlagworteParam + '/schwerpunkte/' + schwerpunkteParam);
  }

  onSearch() {
    const query = this.apiService.makeQuery({ ... this.search }, 0);
    const queryString = qs.stringify(query);
    console.log("qs", this.search, query, decodeURIComponent(queryString))
    this.filterByComplex({ ... this.search }, 0);
  }

  onReset() {
    this.resetForm();
    this.filterByComplex({ ... this.search }, 0);
  }

  onRemoveSchwerpunktFilter(selectedSchwerpunkt: string) {
    this.search.schwerpunkte = this.search.schwerpunkte.filter(schwerpunkt => schwerpunkt !== selectedSchwerpunkt);
    this.filterByComplex({ ... this.search }, 0);
    this.updateUrl();
  }

  onRemoveSchlagwortFilter(selectedSchlagwort: string) {
    this.search.schlagworte = this.search.schlagworte.filter(schlagwort => schlagwort !== selectedSchlagwort);
    this.filterByComplex({ ... this.search }, 0);
    this.updateUrl();
  }

  onRemoveMessebereichFilter(selectedBereich: string) {
    this.search.bereiche = this.search.bereiche.filter(bereich => bereich !== selectedBereich);
    if(this.search.bereiche.length === 0) {
      this.search.bereiche.push('Alle');
    }
    this.filterByComplex({ ... this.search }, 0);
  }

  onRemoveLetterFilter() {
    this.search.letter = 'Alle';
    this.filterByComplex({ ... this.search }, 0);
  }

  resetForm() {
    this.data = null;
    this.search = {
      schwerpunkte: ["Alle"],
      letter: "Alle",
      bereiche: ["Alle"],
      schlagworte: ["Alle"],
      name: "",
      sort: "name", // name | koje
      page: 0,
      maxPages: 0,
    }
  }

  goToPage(param: string | number) {
    if(typeof param === "string") {
      if(param == "previous") {
        this.search.page = this.search.page > 0 ? this.search.page - 1 : 0
      }
      if(param == "next") {
        this.search.page = this.search.page < this.search.maxPages ? this.search.page + 1 : this.search.maxPages;
      }
    } else {
      this.search.page = param;
    }

    this.filterByComplex({ ... this.search }, this.search.page);
  }

  // search = {
  //   schwerpunkt:"Alle",
  //   letter: "Alle",
  //   schlagwort: "Alle",
  // }

  filterByComplex(search:any, targetPage:number) {
    console.log("sending getExhibhitorComplex, targetPage", targetPage);
    this.apiService.getExhibitorComplex(search, targetPage).subscribe(
      (data: any) => {
        console.log("data getExhibhitorComplex this.search", data, this.search)
        this.populateData(data);

        const element = this.elementRef.nativeElement.querySelector(`#ausstellerAnchor`);
        if (element) {
          if(!this.isInitialSearch) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }
        this.isInitialSearch = false;
    }, (error: any) => {
      console.error(error)
    })
  }

  filterByString(str: string) {
    console.log("sending getExhibitorByName")
    this.apiService.getExhibitorByName(str).subscribe(
      (data: any) => {
        console.log("data getExhibitorByName", data)
        this.populateData(data);
      },
      (error: any) => {
        console.error(error)
      },
    )
  }

  filterBySchlagwort(event: any) {
    let schlagwort : string = event.target.value;
    if(schlagwort == "Alle") {
      console.log("sending getExhibitors")
      this.apiService.getExhibitors().subscribe(
        (data: any) => {
          console.log("data getExhibitors", data)
          this.data = data
          this.results = data.data.length;
        },
        (error: any) => {
          console.error(error)
        },
      )
    } else {
      this.apiService.getExhibitorBySchlagwort(schlagwort).subscribe(
        (data: any) => {
          this.data = data
          this.results = data.data.length;
        },
        (error: any) => {
          console.error(error)
        },
      )
    }
  }

  filterBySchwerpunkt(selectedValue: string) {
    if(selectedValue === 'Alle') {
      this.search.schwerpunkte.length = 0;
    } else {
      this.search.schwerpunkte = this.search.schwerpunkte.filter(schwerpunkt => schwerpunkt !== 'Alle');
    }

    if(this.search.schwerpunkte.includes(selectedValue)) {
      return;
    }

    this.search.schwerpunkte.push(selectedValue);
    console.log("schwerpunkte: ", this.search.schwerpunkte);
    this.updateUrl();
    this.filterByComplex({ ... this.search }, 0);
  }

  filterByLetter(event: any) {
    console.log("filterByLetter event", event);
    let letter : string = event.target.value;
    // if we don't get a letter value,
    // the event came from a link and we need to query
    // .innerHTML instead of .value
    if(!letter) {
      letter = event.target.innerText.trim();
    }
    if(letter == "Alle") {
      console.log("sending getExhibitors")
      this.apiService.getExhibitors().subscribe(
        (data: any) => {
          console.log("data getExhibitors", data)
          this.data = data;
          this.results = data.data.length;
        },
        (error: any) => {
          console.error(error)
        },
      )
    } else {
      console.log("sending getExhibitorByLetter", letter)
      this.apiService.getExhibitorByLetter(letter).subscribe(
        (data: any) => {
          console.log("data getExhibitors 2", data)
          this.data = data
          this.results = data.data.length;
        },
        (error: any) => {
          console.error(error)
        },
      )
    }

    const element = this.elementRef.nativeElement.querySelector(`#ausstellerAnchor`);
    if (element) {
      // element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

  }
  getColorForBereich(bereich : string) {
    if(!bereich) {
      return;
    }

    if(this.search.bereiche.length === 1 && this.search.bereiche[0] === 'Alle') {
      return Bereiche.bereichColorMapping[bereich] ? Bereiche.bereichColorMapping[bereich] : 'rgb(254, 234, 0)';
    }

    if(this.search.bereiche.filter(b => b !== 'Alle').find(b => b.startsWith(bereich.toUpperCase()))) {
      return Bereiche.bereichColorMapping[bereich];
    }

    return 'rgb(220,220,220)';
  }

  getColorForSchwerpunkt(schwerpunkt: string) {
    if(this.search.schwerpunkte.length === 0 || (this.search.schwerpunkte.length === 1 && this.search.schwerpunkte[0] === 'Alle')) {
      return this.schwerpunkteColorMapping[schwerpunkt] ? this.schwerpunkteColorMapping[schwerpunkt] : 'rgb(254, 234, 0)';
    }

    if(this.search.schwerpunkte.filter(b => b !== 'Alle').find(b => b.includes(schwerpunkt))) {
      return this.schwerpunkteColorMapping[schwerpunkt];
    }

    return 'rgb(220,220,220)';
  }

  searchWithLetter(letter: any) {
    if(this.search.letter === letter) {
      this.search.letter = 'Alle';
    } else {
      this.search.letter = letter;
    }
    this.filterByComplex({ ... this.search }, 0);    
  }

  searchBereich(bereich: any) {
    if(this.search.bereiche.find(b => b.includes(bereich[1]))) {
      return;
    }

    this.search.bereiche.push(bereich[0]+":"+bereich[1]);

    if(this.search.bereiche.length > 1 && this.search.bereiche.indexOf("Alle", 0) > -1) {
      this.search.bereiche.splice(this.search.bereiche.indexOf("Alle", 0), 1);
    }

    this.filterByComplex({ ... this.search }, 0); 
  }

  getMessebereichTextDecoration(bereich: string) {
    return this.search.bereiche.find(b => b.includes(bereich)) ? 'underline' : 'none';
  }

  getSchwerpunktTextDecoration(schwerpunkt: string) {
    return this.search.schwerpunkte.find(b => b.includes(schwerpunkt)) ? 'underline' : 'none';
  }
  
  getUrl() {
    return this.route.root;
  }
}
