import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';


@Injectable({
  providedIn: 'root'
})
export class PasswordGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router) {}

  canActivate(): boolean {
    if (this.authService.isSiteLocked()) {
      console.log("password canActivate this.authService.isSiteLocked", this.authService.isSiteLocked())
      this.router.navigate(['/404']); // Redirect to 404 if site is locked
      return false;
    } else {
      return true; // Site is unlocked, allow navigation
    }
  }
}
