import { Component, Input  } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'two-column-text',
  templateUrl: './two-column-text.component.html',
  styleUrls: ['./two-column-text.component.scss']
})
export class TwoColumnStartpageTextComponent {
 
  @Input()
  title = '';

  @Input()
  subTitle : string = '';

  @Input()
  column1 : SafeHtml = '';

  @Input()
  column2 : SafeHtml = '';
  
  @Input()
  link : any = '';

  constructor(private sanitizer: DomSanitizer) { }

  convertTitle() {
    return this.sanitizer.bypassSecurityTrustHtml(this.title.replace("<h1>", "<h3 style='margin-top: 0'>").replace('</h1>', "</h3>").replace("<h2>", "<h3 style='margin-top: 0'>").replace('</h2>', "</h3>"));
  }

  convetLinkTextForHTML(content: any) {
    return this.sanitizer.bypassSecurityTrustHtml(content.replace("<p>", "<p style='margin-bottom: 0; margin-top: 0;'>"));
  }
}
