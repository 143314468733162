import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from "../../api.service"

@Component({
  selector: 'top-page-menu',
  templateUrl: './top-page-menu.component.html',
  styleUrls: ['./top-page-menu.component.scss']
})
export class TopPageMenu implements OnInit {
  menuItemsData: any = null;
  imgBaseUrl = ""

  constructor(private apiService: ApiService) { }
  ngOnInit(): void {
    this.imgBaseUrl = this.apiService.apiUrl;

    this.apiService.getTopPageMenu().subscribe({
      error: (error: any) => { 
        console.error(error); 
      }, 
      next: (data: any) => {
        if(data.data) {
          this.menuItemsData = data.data;
        }
      }
    });
  }

}
