import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'video-component',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent {
  @Input()
  title : string = '';

  @Input()
  videoUrl : string = '';

  constructor(private sanitizer: DomSanitizer) {  }

  convertTitleForMobile() {
    if(window.matchMedia("(max-width: 1023px)").matches) {
      return this.sanitizer.bypassSecurityTrustHtml(this.title.replace("<h1>", "<span>").replace('</h1>', "</span>").replace("<h2>", "<span>").replace('</h2>', "</span>"));
    }

    if(window.matchMedia("(min-width: 1024px)").matches) {
      return this.sanitizer.bypassSecurityTrustHtml(this.title.replace("<h1>", "<h1 style='font-size: 3rem'>"));
    }

    return this.title;
  }
}
