import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MarkdownService } from "ngx-markdown"
import { ApiService } from "../../api.service"
import { ActivatedRoute, Router } from "@angular/router"
import { writeMeta } from '../shared/meta';


@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  constructor(private title: Title, private meta: Meta) {
  }

  ngOnInit(): void {
    writeMeta(this.title, this.meta, "404 - Seite nicht gefunden",
      "Seite nicht gefunden",
      "",
      false,
      null);
  }
}
