import { CountryModel } from "./country-model"
import { StrapiFile } from "./filetypes-model"
import { StrapiListWrapper, StrapiSingleWrapper } from "./strapi-wrapper"

interface BaseExhibitorModel {
  user: any
  id: number
  name: string
  uidNumber: string
  Content: any[]
  HtmlContent: string
  mdContent: string
  street: string
  zipcode: string
  city: string
  country: CountryModel | number
  state: string
  tel: string
  email: string
  www: string
  bereich: string
  schlagwortes: any[]
  schwerpus: any[]
  tel2: string
  email2: string
  www2: string
  tel3: string
  email3: string
  www3: string
  icon: StrapiFile | any
  Allgemeine_Info_Einfuehrung: string
  Angebot_auf_der_BeSt: string
  Schwerpunkte_Beratungsangebot: string
  Schwerpunkte_Bildungsangebot: string
  Zielgruppen: string
  Aufnahmebedingungen: string
  Kosten: string
  Dauer_Ausbildung_Weiterbildung: string
  Abschluss: string
  Instagram: string
  Facebook: string
  Twitter_X: string
  YouTube: string
}

export interface ExhibitorModel extends BaseExhibitorModel {
  banner: StrapiFile | null
  all_banners: StrapiFile[] | null
  Dokumente: StrapiFile[] | null
  all_documents: StrapiFile[] | null
}

export interface ExhibitorUpdateResponseModel extends BaseExhibitorModel {
  banner: StrapiSingleWrapper<StrapiFile> | null
  all_banners: StrapiListWrapper<StrapiFile> | null
  Dokumente: StrapiListWrapper<StrapiFile> | null
  all_documents: StrapiListWrapper<StrapiFile> | null
}

export function exhibitorUpdateResponseModelToExhibitorModel(original: ExhibitorUpdateResponseModel): ExhibitorModel {
  var base = original as BaseExhibitorModel
  var banner: StrapiFile | null = original?.banner?.data
    ? { ...original.banner.data.attributes, id: original.banner.data.id }
    : null
  var all_banners = original?.all_banners?.data
    ? original.all_banners.data.map((b) => ({ ...b.attributes, id: b.id }))
    : null
  var Dokumente = original?.Dokumente?.data ? original.Dokumente.data.map((b) => ({ ...b.attributes, id: b.id })) : null
  var all_documents = original?.all_documents?.data
    ? original.all_documents.data.map((b) => ({ ...b.attributes, id: b.id }))
    : null
  var newModel = {
    ...base,
    banner: banner,
    all_banners: all_banners,
    Dokumente: Dokumente,
    all_documents: all_documents,
  }
  return newModel
}
