import { Title, Meta } from "@angular/platform-browser"

export function writeMeta(titleContext: Title,
                          metaContext: Meta,
                          title: string,
                          description: string,
                          shareImageUrl: string | null,
                          preventIndexing: boolean,
                          imgBaseUrl: string | null) {
  titleContext.setTitle(title)
  if (description) {
    metaContext.addTags([
      { name: "description", content: description },
    ])
  }
  if (shareImageUrl) {
    metaContext.addTags([
      {
        name: "twitter:card",
        content: imgBaseUrl + shareImageUrl,
      },
    ])
  }
  metaContext.addTags([
    { name: "twitter:site", content: "@bestinfo_at" },
  ])
  if (preventIndexing) {
    metaContext.addTags([
      { name: "robots", content: "noindex" },
    ])
  }
}