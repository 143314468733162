import { booleanAttribute, Directive, EventEmitter, Input, numberAttribute, Output } from '@angular/core';

@Directive({ selector: '[appBaseInput]' })
export class BaseInputDirective<InputValue = any, OutputValue = InputValue> {
  @Input({ required: true })
  label!: string;

  @Input()
  labelPosition: 'top' | 'left' | 'right' = 'top';

  @Input()
  labelClass?: string;

  @Input()
  placeholder?: string;

  @Input()
  name?: string;

  @Input({ transform: booleanAttribute })
  required: boolean = false;

  @Input({ transform: booleanAttribute })
  disabled: boolean = false;

  @Input({ transform: booleanAttribute })
  standalone = false;

  @Output()
  valueChange = new EventEmitter<OutputValue>();

  @Input()
  helpText?: string;

  @Input({ transform: numberAttribute })
  autoFocusAfterDelay?: number = undefined; // milliseconds after which it should be focussed

  private _value: InputValue | undefined;

  @Input()
  public get value(): InputValue | undefined {
    return this._value;
  }

  public set value(value: InputValue | undefined) {
    this._value = value;
  }
}
