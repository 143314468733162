<h1>Alle Aussteller mit Schlagwort {{id}}</h1>

<ul *ngFor="let item of data!.data">

  <li><a href="/aussteller/{{item.id}}">{{item.attributes.name}}</a></li>

</ul>





