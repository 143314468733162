import { Component, Input, OnInit  } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ApiService } from 'src/api.service';

@Component({
  selector: 'card-gallery',
  templateUrl: './card-gallery.component.html',
  styleUrls: ['./card-gallery.component.scss']
})
export class CardGalleryComponent implements OnInit {
  @Input()
  cards = [];

  @Input()
  title : SafeHtml = '';

  @Input()
  subTitle : string = '';

  imgBaseUrl = ""

  constructor(private apiService: ApiService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.imgBaseUrl = this.apiService.apiUrl
  }

  convertTitle(content: any) {
    if(window.matchMedia("(min-width: 1024px)").matches) {
      return this.sanitizer.bypassSecurityTrustHtml(content.replace("<h1>", "<h1 style='margin-top: 0;'>").replace("<h2>", "<h2 style='margin-top: 0;'>"));

    }

    return this.sanitizer.bypassSecurityTrustHtml(content.replace("<h1>", "<h1 style='margin-top: 0; font-size: 2rem;'>").replace("<h2>", "<h2 style='margin-top: 0; font-size: 2rem;'>"));
  }

  convertCardText(content: any) {
    return this.sanitizer.bypassSecurityTrustHtml(content.replace("<p>", "<p style='margin-bottom: 0;'>"));
  }
}
