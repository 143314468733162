import { Component, OnInit  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/api.service';

@Component({
  selector: 'empty-menu-overview',
  templateUrl: './empty-menu-overview.component.html',
  styleUrls: ['./empty-menu-overview.component.scss']
})
export class EmptyTopMenuOverviewComponent implements OnInit {
  pathItem : any;

  constructor(private apiService: ApiService, private route: ActivatedRoute) { }

  ngOnInit() {
    let url = this.route.root;
    if(url) {
      this.apiService.getMenu().subscribe({
        error: (error: any) => { console.log(error); }, 
        next: (data: any) => { 
          if(url.firstChild) {
            let parts = url.firstChild.snapshot.url.map((segment: any) => segment.path);
            if(parts.length === 1) {
              this.pathItem = data.data.find((a: any) => a.attributes.Url.includes(parts[parts.length-1])); 
            }

            if(parts.length > 1) {
              let menuSubData = data.data;
              const headerItem = data.data.find((a: any) => a.attributes.Url.includes(parts[0])); 
              menuSubData = headerItem.attributes.subItems;
              parts = parts.slice(1);
              for(const part of parts) {
                let subItem = menuSubData.find((a: any) => a.Url.substring(a.Url.lastIndexOf('/')+1) === part);
                console.log();
                if(subItem) {
                  menuSubData = subItem.subItems;
                }

                if(subItem.Url.substring(subItem.Url.lastIndexOf('/')+1) === parts[parts.length-1]) {
                  this.pathItem = subItem;
                }
              }
            }          
          }
        },   
      });    
    }
  }

  getTitle(title: any) {
    title = title.replace(/(<([^>]+)>)/ig, '')
    return title.charAt(0) + title.substring(1).toLowerCase()
  }

  getSubItems() {
    if(!this.pathItem) {
      return;
    }

    return this.pathItem.attributes ? this.pathItem.attributes.subItems : this.pathItem.subItems;
  }

  getUrl() {
    return this.route.root;
  }
}