import { Component, OnInit } from "@angular/core"
import { ApiService } from "../../api.service"

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.scss']
})
export class SitemapComponent implements OnInit {
  menu: any;
  constructor(private apiService: ApiService) { }
  ngOnInit(): void {
    this.apiService.getMenu().subscribe({
      error: (error: any) => { 
        console.error(error); 
      }, 
      next: (data: any) => {
        if(data.data) {
          this.menu = data.data;
        }
      }
    });
  }

  removeTags(text: string) {
    return text.replace(/(<([^>]+)>)/ig, '');
  }
}
