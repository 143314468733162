import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core"
import { debounce, debounceTime, firstValueFrom, Subject, takeWhile, tap } from "rxjs"
import { ApiService } from "src/api.service"
import { TagModel } from "../../models/tag-model"
import { VortragModel } from "../../models/vortrag-model"
import { BühneModel } from "../../models/bühne-model"
import { Pagination, StrapiDataWrapper } from "src/models/strapi-wrapper"
import { ProgrammPageModel } from "src/models/programm-page-model"
import { FormControl } from "@angular/forms"
import { ListSideBarIconItem, ListSortOptions, SpeechListComponent } from "../speech-list/speech-list.component"
import { ActivatedRoute } from "@angular/router"
import { DynamicContentContainer } from "../dynamic-content-container"
import { SpeakerModel } from "src/models/speaker-model"
import { TimePipe } from "../speech-list/time.pipe"
import { start } from "@popperjs/core"

@Component({
  selector: "app-stage",
  templateUrl: "./stage.component.html",
  styleUrl: "./stage.component.scss",
})
export class StageComponent extends DynamicContentContainer implements OnInit {
  days?: StrapiDataWrapper<TagModel>[]
  private _speeches?: StrapiDataWrapper<VortragModel>[]
  stages?: StrapiDataWrapper<BühneModel>[]
  page?: ProgrammPageModel

  selectedDay?: StrapiDataWrapper<TagModel>
  selectedStage?: StrapiDataWrapper<BühneModel>

  signLanguageCtrl: FormControl = new FormControl(false)

  pagination: Pagination = {
    page: 1,
    pageCount: 0,
    pageSize: 25,
    total: 0,
  }

  openPanelId?: number

  triggerReload: Subject<void> = new Subject()
  initialLoadDone = false

  @ViewChild("speechList")
  speechList?: SpeechListComponent<VortragModel>
  @ViewChild("dynamicContent", { read: ViewContainerRef }) dynamicContentContainer!: ViewContainerRef

  startTimeSelector = (item: VortragModel): string => item.timeslot.data.attributes.Start
  endTimeSelector = (item: VortragModel): string => item.timeslot.data.attributes.Ende
  titleSelector = (item: VortragModel): string => item.Titel
  subTitleSelector = (item: VortragModel): string => item.Untertitel
  institutionSelector = (item: VortragModel): string | undefined => item.Institution
  daySelector = (item: VortragModel): string => item.tag.data.attributes.Wochentag
  dateSelector = (item: VortragModel): string | Date | undefined => item.tag.data.attributes.Datum
  speakersSelector = (item: VortragModel): SpeakerModel[] | undefined => item.Speakers
  descriptionSelector = (item: VortragModel): string => item.Beschreibung
  locationSelector = (item: VortragModel): string => `Bühne ${this.stageCounter(item.buehne.data)}`

  listIconItems: ListSideBarIconItem<VortragModel>[] = [
    {
      icon: "clock",
      textSelector: (item: VortragModel): string => {
        var startTime = this.startTimeSelector(item)
        return new TimePipe().transform(startTime);
      }
    },
    {
      icon: "clock",
      textSelector: (item: VortragModel): string => {
        var endTime = this.endTimeSelector(item)
        return new TimePipe().transform(endTime);
      }
    },
    {
      icon: "geo-alt",
      textSelector: this.locationSelector
    },
    {
      icon: "card-checklist",
      textSelector: () => "Ohne Voranmeldung"
    }
  ]

  listSortOptions: ListSortOptions<VortragModel>[] = [
    {
      label: "Uhrzeit",
      default: true,
      key: "uhrzeit",
      selector: (item: VortragModel) => item.timeslot.data.attributes.Start
    },
    {
      label: "Institution A-Z",
      default: false,
      key: "instituttion",
      selector: (item: VortragModel) => item.Institution 
    }
  ]

  constructor(private apiService: ApiService, private route: ActivatedRoute) {
    super()
    this.triggerReload
      .pipe(
        debounceTime(200),
        takeWhile(() => this.initialLoadDone),
      )
      .subscribe({
        next: (_) => {
          this.getSpeeches()
        },
      })
  }

  get loading(): boolean {
    return this.days == undefined || this._speeches == undefined || this.stages == undefined
  }
  async ngOnInit() {
    await this.getPageWithDaysAndStages()
    await this.paramMapCalculations()
    this.startPageDynamicContents = this.page?.page?.data?.attributes.DynamicContent ?? []
    this.initDynamicContents(this.dynamicContentContainer)
    
    this.updateUrl()
    
    this.signLanguageCtrl.valueChanges.subscribe((_) => this.triggerReload.next())
    
    this.initialLoadDone = true
  }
  
  async paramMapCalculations() {
    var paramMap = await firstValueFrom(this.route.paramMap)
    let paramDay = paramMap.get("tag")
    let paramStage = paramMap.get("buehne")
    
    if (!paramStage) paramStage = "0"
    if (!paramDay) paramDay = "0"
    if (paramStage?.includes("Alle")) {
      this.selectedStage = undefined
    } else {
      var stageId = 0
      try {
        stageId = parseInt(paramStage ?? "0")
      } catch {}
      if (stageId > 0) {
        this.selectedStage = this.stages?.find((s) => s.id == stageId)
      } else {
        this.selectedStage = undefined
      }
    }
    
    var dayId = 0
    try {
      dayId = parseInt(paramDay ?? "0")
    } catch {}
    if (dayId > 0) {
      this.selectedDay = this.days?.find((d) => d.id == dayId)
    } else {
      this.selectedDay = this.days?.at(0)
    }
  }
  
  updateUrl() {
    window.history.replaceState(
      {},
      "",
      `programm/tag/${this.selectedDay?.id}/buehne/${this.selectedStage?.id ?? "Alle"}`,
    )
  }
  
  async getPageWithDaysAndStages() {
    const wrappedPage = await firstValueFrom(this.apiService.geProgrammPage())
    if (wrappedPage && wrappedPage.data) {
      this.page = wrappedPage.data.attributes
      this.days = this.page.tage.data
      this.stages = this.page.buehnen.data
    }
  }

  async getSpeeches() {
    var stageIds: number[] = []
    if (this.selectedStage) stageIds.push(this.selectedStage.id)
    else stageIds.push(...(this.stages?.map((s) => s.id) ?? []))
    var result = await firstValueFrom(
      this.apiService.getSpeeches(this.selectedDay?.id ?? 0, stageIds, this.signLanguageCtrl.value, this.pagination),
    )
    this.speechList?.closeAll()
    this._speeches = result.data
    this.pagination = result.meta?.pagination!
  }

  get speeches(): StrapiDataWrapper<VortragModel>[] | undefined {
    if (!this.selectedDay) return undefined

    return this._speeches
  }

  stageSelected(stage: StrapiDataWrapper<BühneModel> | undefined) {
    this.selectedStage = stage
    this.updateUrl()
    this.triggerReload.next()
  }
  daySelected(day: StrapiDataWrapper<TagModel>) {
    this.selectedDay = day
    this.updateUrl()
    this.triggerReload.next(undefined)
  }

  onRemoveDayFilter() {
    this.selectedDay = undefined;
  }

  onRemoveSelectedStage() {
    this.selectedStage = undefined;
  }

  stageCounter(stage: StrapiDataWrapper<BühneModel>) {
    return (this.stages?.findIndex((d) => d.id === stage.id) ?? -1) + 1
  }

  goToPage(param: string | number) {
    var oldPage = this.pagination.page
    if (typeof param === "string") {
      if (param == "previous") {
        this.pagination.page = Math.max(this.pagination.page - 1, 1)
      }
      if (param == "next") {
        this.pagination.page = Math.min(this.pagination.pageCount, this.pagination.page + 1)
      }
    } else {
      this.pagination.page = param
    }

    if (oldPage == this.pagination.page) return

    this.triggerReload.next()
  }
}
