import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from "../api.service"

@Injectable({
  providedIn: 'root'
})
export class NotFoundGuard implements CanActivate {
  constructor(private router: Router, private api: ApiService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const slug = route.paramMap.get('slug');
    console.log("canActivate 1");
    const isValidSlug = this.api.getPageExists(slug!);
    console.log("canActivate 2", isValidSlug);

    if (isValidSlug) {
      return true;
    } else {
      this.router.navigate(['/404']);
      return false;
    }


  }
  
}
