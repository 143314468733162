<div class="two-column-container">
  <div class="title-container">
    <p>{{subTitle}}</p>
    <div [innerHTML]="convertTitle()" ></div>  
  </div>

  <div class="content">
    <div class="content-column">
      <div [innerHTML]="column1"></div>
    </div>
    <div class="content-column content-column2">
      <div [innerHTML]="column2"></div>
      <div class="header-text info-link" *ngIf="link">
        <a [href]="link['Url']" target="_blank">
          <span  [innerHTML]="convetLinkTextForHTML(link['Label'])"></span>
          <img src="./assets/arrow-thin-top-right-corner-icon.svg" class="arrow">
        </a>
      </div>
    </div>
  </div>
</div>

