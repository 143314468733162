<div class="card">
  <!--<input type="checkbox" class="card-checkbox" [ngModel]="isSelected" (click)="selectClicked($event)" />-->
  <div class="input-container checkbox card-checkbox">
    <label class="input-label">
      <input #input [ngModel]="isSelected" (click)="selectClicked($event)" type="checkbox" />
      <span class="custom-checkbox"></span>
    </label>
  </div>
  <div class="card-bottom">
    <div class="card-text">{{ file.name }}</div>
    <div class="card-button-bar">
      <button type="button" class="delete-button" [disabled]="loading" (click)="deleteClicked()">Löschen</button>
      <button type="button" class="download-button" [disabled]="loading" (click)="downloadClicked()">
        Herunterladen
      </button>
    </div>
  </div>
</div>
