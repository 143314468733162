import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
  name: "time",
})
export class TimePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value

    // Split the time string by ":"
    const timeParts = value.split(":")

    // Return the first two parts (HH:MM)
    return `${timeParts[0]}:${timeParts[1]}`
  }
}
