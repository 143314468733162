<div class="exhibitor-container">
  <div class="title-container">
    <h1>
      {{ page?.data?.attributes?.page?.data?.attributes?.Title ?? "" }}
    </h1>
  </div>
  <div class="content">
    <div [innerHTML]="page?.data?.attributes?.page?.data?.attributes?.HtmlContent ?? ''"></div>
    <form *ngIf="exhibitorForm && exhibitor" [formGroup]="exhibitorForm" (ngSubmit)="onSubmit()">
      <!--MARK: INPUTS
  -->
      <div class="inputs-row">
        <app-best-input 
          label="UID-Nummer:"
          [formGroup]="exhibitorForm"
          controlName="uidNumber"
        />
      </div>
      <div class="inputs-row">
        <app-best-input 
          label="Adresse:"
          [formGroup]="exhibitorForm"
          controlName="street"
        />
      </div>
      <div class="inputs-row">
        <app-best-input 
          label="PLZ:"
          [formGroup]="exhibitorForm"
          controlName="zipcode"
        />        
        <app-best-input 
          style="flex: 2;"
          label="Stadt/Ort:"
          [formGroup]="exhibitorForm"
          controlName="city"
        />
      </div>
      <div class="inputs-row">
        <app-best-input 
          label="Bundesland:"
          [formGroup]="exhibitorForm"
          controlName="state"
        />
        <div class="input-container">
          <label class="input-label"> Land:</label>
          <select placeholder="Bitte auswählen" formControlName="country">
            <option [value]="null">Bitte auswählen</option>
            @for (country of countries?.data; track $index) {
            <option [value]="country.id">{{ country.attributes.Name }}</option>
            }
          </select>
        </div>
      </div>
      <div class="inputs-group">
        <label class="inputs-group-label"
          >Kontakt <small>(Mindestes eines der drei folgenden Felder muss ausgefüllt werden): </small>
          <b style="color: red">*</b></label
        >

        @if(exhibitorForm.errors?.['atLeastOneFieldRequired'] && (exhibitorForm.get('tel')!.touched ||
        exhibitorForm.get('email')!.touched || exhibitorForm.get('www')!.touched)){
        <span class="error-text">Eines der Felder muss ausgefüllt sein!</span>
        }
        <div class="inputs-row">
          <app-best-input 
            label="Telefonnummer:" 
            [formGroup]="exhibitorForm" 
            controlName="tel" 
            infoText="Format: +43 (0)1 234 5678"
            [validatorErrors]="[{key: 'pattern', errorText: 'Ungültige Telefonnummer'}]"
          />
          <app-best-input 
            label="E-Mail:"
            [formGroup]="exhibitorForm"
            controlName="email"
            type="email"
            [validatorErrors]="[{key: 'pattern', errorText: 'Ungültige E-Mail'}]"
          />
        </div>
        <div class="inputs-row">
          <app-best-input 
            label="Website:"
            [formGroup]="exhibitorForm"
            controlName="www"
            type="url"
            [validatorErrors]="[{key: 'pattern', errorText: 'Ungültige Url'}]"
          />
        </div>
      </div>
      <div class="inputs-row">
        <app-best-input 
          label="Telefonnummer 2:" 
          [formGroup]="exhibitorForm" 
          controlName="tel2" 
          infoText="Format: +43 (0)1 234 5678"
          type="tel"
          [validatorErrors]="[{key: 'pattern', errorText: 'Ungültige Telefonnummer'}]"
        />
        <app-best-input 
          label="E-Mail 2:"
          [formGroup]="exhibitorForm"
          controlName="email2"
          type="email"
          [validatorErrors]="[{key: 'pattern', errorText: 'Ungültige E-Mail'}]"
        />
      </div>
      <div class="inputs-row">
        <app-best-input 
          label="Website 2:"
          [formGroup]="exhibitorForm"
          controlName="www2"
          type="url"
          [validatorErrors]="[{key: 'pattern', errorText: 'Ungültige Url'}]"
        />
      </div>
      <div class="inputs-row">
        <app-best-input 
          label="Telefonnummer 3:" 
          [formGroup]="exhibitorForm" 
          controlName="tel3" 
          infoText="Format: +43 (0)1 234 5678"
          type="tel"
          [validatorErrors]="[{key: 'pattern', errorText: 'Ungültige Telefonnummer'}]"
        />
        <app-best-input 
          label="E-Mail 3:"
          [formGroup]="exhibitorForm"
          controlName="email3"
          type="email"
          [validatorErrors]="[{key: 'pattern', errorText: 'Ungültige E-Mail'}]"
        />
      </div>
      <div class="inputs-row">
        <app-best-input 
          label="Website 3:"
          [formGroup]="exhibitorForm"
          controlName="www3"
          type="url"
          [validatorErrors]="[{key: 'pattern', errorText: 'Ungültige Url'}]"
        />
      </div>
      <div class="inputs-row">
        <app-best-input 
          label="Instagram:"
          [formGroup]="exhibitorForm"
          controlName="Instagram"
          type="url"
          placeholder="https://www.instagram.com/bestmesse/"
          [validatorErrors]="[{key: 'instagramUrl', errorText: 'Ungültige Url für Instagram'}]"
        />
      </div>
      <div class="inputs-row">
        <app-best-input 
          label="YouTube:"
          [formGroup]="exhibitorForm"
          controlName="YouTube"
          type="url"
          placeholder="https://www.youtube.com/user/BeStinfoMesse"
          [validatorErrors]="[{key: 'youtubeUrl', errorText: 'Ungültige Url für YouTube'}]"
        />
      </div>
      <div class="inputs-row">
        <app-best-input 
          label="Twitter/X:"
          [formGroup]="exhibitorForm"
          controlName="Twitter_X"
          type="url"
          placeholder="https://twitter.com/bestinfo_at"
          [validatorErrors]="[{key: 'twitterUrl', errorText: 'Ungültige Url für Twitter/X'}]"
        />
      </div>
      <div class="inputs-row">
        <app-best-input 
          label="Facebook:"
          [formGroup]="exhibitorForm"
          controlName="Facebook"
          type="url"
          placeholder="https://www.facebook.com/bestinfo.at"
          [validatorErrors]="[{key: 'facebookUrl', errorText: 'Ungültige Url für Facebook'}]"
        />
      </div>

      <!--MARK: FILES
  -->
      <div class="inputs-row">
        <div class="input-container">
          <label style="margin-bottom: -2px" class="input-label"> Banner: </label>
          <app-file-card-container
            [files]="exhibitor.all_banners ?? []"
            [uploadConfig]="bannerUploadConfig"
            (fileUploadFailed)="fileUploadFailedCallback($event)"
            [control]="fh?.getControl('banner')"
            [selectLimit]="1"
            [uploadLimit]="4"
            [allowTypes]="bannerAllowTypes"
            placeholder="Banner.jpg"
          ></app-file-card-container>
        </div>
      </div>
      <div class="inputs-row">
        <div class="input-container">
          <label style="margin-bottom: -2px" class="input-label"> Dokumente: </label>
          <app-file-card-container
            [files]="exhibitor.all_documents ?? []"
            [uploadConfig]="documentUploadConfig"
            (fileUploadFailed)="fileUploadFailedCallback($event)"
            [control]="fh?.getControl('Dokumente')"
            [selectLimit]="3"
            [uploadLimit]="8"
            [allowTypes]="documentAllowTypes"
            placeholder="Anmeldeformular.pdf"
          ></app-file-card-container>
        </div>
      </div>

      <!--MARK: RTE
  -->
      <div class="inputs-row">
        <div class="input-container">
          <label class="input-label">Allgemeine Info/Einfuehrung: <b style="color: red">*</b> </label>
          <app-rich-text-input
            label=""
            [(value)]="Allgemeine_Info_Einfuehrung"
            (valueChange)="fh?.patchValue('Allgemeine_Info_Einfuehrung', $event)"
            [ngClass]="fh?.rteCounterInvalid('Allgemeine_Info_Einfuehrung') ? 'ng-touched ng-invalid' : ''"
          ></app-rich-text-input>
          <span
            [ngClass]="fh?.rteCounterInvalid('Allgemeine_Info_Einfuehrung') ? 'error-text' : ''"
            style="font-size: small"
            >{{ Allgemeine_Info_Einfuehrung ? richtextCounter(Allgemeine_Info_Einfuehrung) : 0 }}/1500</span
          >
          @if(exhibitorForm.get('Allgemeine_Info_Einfuehrung')!.invalid){
          <span class="error-text" *ngIf="fh?.controlError('Allgemeine_Info_Einfuehrung', 'required')"
            >Dieses Feld muss ausgefüllt sein</span
          >
          <span class="error-text" *ngIf="fh?.controlError('Allgemeine_Info_Einfuehrung', 'max')"
            >Maximale Zeichenanzahl überschritten</span
          >
          }
        </div>
      </div>
      <div class="inputs-row">
        <div class="input-container">
          <label class="input-label">Angebot auf der BeSt: <b style="color: red">*</b> </label>
          <app-rich-text-input
            label=""
            [(value)]="Angebot_auf_der_BeSt"
            (valueChange)="fh?.patchValue('Angebot_auf_der_BeSt', $event)"
            [ngClass]="fh?.rteCounterInvalid('Angebot_auf_der_BeSt') ? 'ng-touched ng-invalid' : ''"
          ></app-rich-text-input>

          <span [ngClass]="fh?.rteCounterInvalid('Angebot_auf_der_BeSt') ? 'error-text' : ''" style="font-size: small"
            >{{ Angebot_auf_der_BeSt ? richtextCounter(Angebot_auf_der_BeSt) : 0 }}/1500</span
          >
          @if(exhibitorForm.get('Angebot_auf_der_BeSt')!.invalid){
          <span class="error-text" *ngIf="fh?.controlError('Angebot_auf_der_BeSt', 'required')"
            >Dieses Feld muss ausgefüllt sein</span
          >
          <span class="error-text" *ngIf="fh?.controlError('Angebot_auf_der_BeSt', 'max')"
            >Maximale Zeichenanzahl überschritten</span
          >
          }
        </div>
      </div>
      <div class="inputs-row">
        <div class="input-container">
          <label class="input-label">Schwerpunkte Beratungsangebot: <b style="color: red">*</b></label>
          <app-rich-text-input
            label=""
            [(value)]="Schwerpunkte_Beratungsangebot"
            (valueChange)="fh?.patchValue('Schwerpunkte_Beratungsangebot', $event)"
            [ngClass]="fh?.rteCounterInvalid('Schwerpunkte_Beratungsangebot') ? 'ng-touched ng-invalid' : ''"
          ></app-rich-text-input>
          <span
            [ngClass]="fh?.rteCounterInvalid('Schwerpunkte_Beratungsangebot') ? 'error-text' : ''"
            style="font-size: small"
            >{{ Schwerpunkte_Beratungsangebot ? richtextCounter(Schwerpunkte_Beratungsangebot) : 0 }}/500</span
          >
          @if(exhibitorForm.get('Schwerpunkte_Beratungsangebot')!.invalid){
          <span class="error-text" *ngIf="fh?.controlError('Schwerpunkte_Beratungsangebot', 'required')"
            >Dieses Feld muss ausgefüllt sein</span
          >
          <span class="error-text" *ngIf="fh?.controlError('Schwerpunkte_Beratungsangebot', 'max')"
            >Maximale Zeichenanzahl überschritten</span
          >
          }
        </div>
      </div>
      <div class="inputs-row">
        <div class="input-container">
          <label class="input-label">Schwerpunkte Bildungsangebot: </label>
          <app-rich-text-input
            label=""
            [(value)]="Schwerpunkte_Bildungsangebot"
            (valueChange)="fh?.patchValue('Schwerpunkte_Bildungsangebot', $event)"
            [ngClass]="fh?.rteCounterInvalid('Schwerpunkte_Bildungsangebot') ? 'ng-touched ng-invalid' : ''"
          ></app-rich-text-input>
          <span
            [ngClass]="fh?.rteCounterInvalid('Schwerpunkte_Bildungsangebot') ? 'error-text' : ''"
            style="font-size: small"
            >{{ Schwerpunkte_Bildungsangebot ? richtextCounter(Schwerpunkte_Bildungsangebot) : 0 }}/500</span
          >
          @if(exhibitorForm.get('Schwerpunkte_Bildungsangebot')!.invalid){
          <span class="error-text" *ngIf="fh?.controlError('Schwerpunkte_Bildungsangebot', 'max')"
            >Maximale Zeichenanzahl überschritten</span
          >
          }
        </div>
      </div>
      <div class="inputs-row">
        <div class="input-container">
          <label class="input-label">Zielgruppen: </label>
          <app-rich-text-input
            label=""
            [(value)]="Zielgruppen"
            (valueChange)="fh?.patchValue('Zielgruppen', $event)"
            [ngClass]="fh?.rteCounterInvalid('Zielgruppen') ? 'ng-touched ng-invalid' : ''"
          ></app-rich-text-input>
          <span [ngClass]="fh?.rteCounterInvalid('Zielgruppen') ? 'error-text' : ''" style="font-size: small"
            >{{ Zielgruppen ? richtextCounter(Zielgruppen) : 0 }}/500</span
          >
          @if(exhibitorForm.get('Zielgruppen')!.invalid){
          <span class="error-text" *ngIf="fh?.controlError('Zielgruppen', 'max')"
            >Maximale Zeichenanzahl überschritten</span
          >
          }
        </div>
      </div>
      <div class="inputs-row">
        <div class="input-container">
          <label class="input-label">Aufnahmebedingungen: </label>
          <app-rich-text-input
            label=""
            [(value)]="Aufnahmebedingungen"
            (valueChange)="fh?.patchValue('Aufnahmebedingungen', $event)"
            [ngClass]="fh?.rteCounterInvalid('Aufnahmebedingungen') ? 'ng-touched ng-invalid' : ''"
          ></app-rich-text-input>
          <span [ngClass]="fh?.rteCounterInvalid('Aufnahmebedingungen') ? 'error-text' : ''" style="font-size: small"
            >{{ Aufnahmebedingungen ? richtextCounter(Aufnahmebedingungen) : 0 }}/1500</span
          >
          @if(exhibitorForm.get('Aufnahmebedingungen')!.invalid){
          <span class="error-text" *ngIf="fh?.controlError('Aufnahmebedingungen', 'max')"
            >Maximale Zeichenanzahl überschritten</span
          >
          }
        </div>
      </div>
      <div class="inputs-row">
        <div class="input-container">
          <label class="input-label">Kosten: </label>
          <app-rich-text-input
            label=""
            [(value)]="Kosten"
            (valueChange)="fh?.patchValue('Kosten', $event)"
            [ngClass]="fh?.rteCounterInvalid('Kosten') ? 'ng-touched ng-invalid' : ''"
          ></app-rich-text-input>
          <span [ngClass]="fh?.rteCounterInvalid('Kosten') ? 'error-text' : ''" style="font-size: small"
            >{{ Kosten ? richtextCounter(Kosten) : 0 }}/500</span
          >
          @if(exhibitorForm.get('Kosten')!.invalid){
          <span class="error-text" *ngIf="fh?.controlError('Kosten', 'max')">Maximale Zeichenanzahl überschritten</span>
          }
        </div>
      </div>
      <div class="inputs-row">
        <div class="input-container">
          <label class="input-label">Dauer Ausbildung/Weiterbildung: </label>
          <app-rich-text-input
            label=""
            [(value)]="Dauer_Ausbildung_Weiterbildung"
            (valueChange)="fh?.patchValue('Dauer_Ausbildung_Weiterbildung', $event)"
          ></app-rich-text-input>
          <span
            [ngClass]="fh?.rteCounterInvalid('Dauer_Ausbildung_Weiterbildung') ? 'error-text' : ''"
            style="font-size: small"
            >{{ Dauer_Ausbildung_Weiterbildung ? richtextCounter(Dauer_Ausbildung_Weiterbildung) : 0 }}/500</span
          >
          @if(exhibitorForm.get('Dauer_Ausbildung_Weiterbildung')!.invalid){
          <span class="error-text" *ngIf="fh?.controlError('Dauer_Ausbildung_Weiterbildung', 'max')"
            >Maximale Zeichenanzahl überschritten</span
          >
          }
        </div>
      </div>
      <div class="inputs-row">
        <div class="input-container">
          <label class="input-label">Abschluss: </label>
          <app-rich-text-input
            label=""
            [(value)]="Abschluss"
            (valueChange)="fh?.patchValue('Abschluss', $event)"
          ></app-rich-text-input>
          <span [ngClass]="fh?.rteCounterInvalid('Abschluss') ? 'error-text' : ''" style="font-size: small"
            >{{ Abschluss ? richtextCounter(Abschluss) : 0 }}/500</span
          >
          @if(exhibitorForm.get('Abschluss')!.invalid){
          <span class="error-text" *ngIf="fh?.controlError('Abschluss', 'max')"
            >Maximale Zeichenanzahl überschritten</span
          >
          }
        </div>
      </div>

      <label class="error-text" *ngIf="saveSuccess != undefined && !saveSuccess">
        Beim Speichern ist leider etwas schiefgelaufen. Bitte versuchen Sie es später erneut.
      </label>
      <label class="error-text" *ngIf="fileUploadFailed">
        Beim Hochladen der Bilder is etwas schiefgelaufen. Bitte versuchen Sie es später erneut.
      </label>
      <label *ngIf="!exhibitorForm.valid && fh?.submitAttempted" class="error-text" style="padding-top: 32px"
        >Einige Felder wurden nicht richtig ausgefüllt. Bitte überprüfen sie ihre Angaben.
      </label>

      <label class="success-text" *ngIf="saveSuccess"> Gespeichert! </label>
      <button class="submit-button" type="submit">
        Daten speichern
        <svg
            xmlns="http://www.w3.org/2000/svg"
            shape-rendering="geometricPrecision"
            text-rendering="geometricPrecision"
            image-rendering="optimizeQuality"
            fill-rule="evenodd"
            clip-rule="evenodd"
            viewBox="0 0 512 512"
            class="arrow"
          >
            <path
              fill-rule="nonzero"
              d="M165 0c-8-1-16 6-17 15s7 17 16 17l274 19L5 484c-7 7-7 17 0 23 6 7 16 7 23 0L461 74l19 274c0 9 8 16 17 16s16-9 15-17L491 37c0-9-7-15-15-16L165 0z"
            />
          </svg>
      </button>
    </form>
  </div>
</div>

<label *ngIf="!exhibitor && exhibitorForm" class="error-text" style="padding-top: 32px"
  >Es wurde ihnen noch kein Aussteller zugeordnet. Bitte melden Sie sich beim Messebüro.
</label>

<div #dynamicContent></div>
