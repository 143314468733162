import { Component, OnInit } from "@angular/core"
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { ActivatedRoute } from "@angular/router"
import { firstValueFrom, map, Observable } from "rxjs"
import { AuthenticationService } from "src/authentication.service"
import { ResetPasswordRequest } from "src/models/authentication/reset-password-request"
import { BeStValidators } from "../validators"
import { FormHelper } from "../form-helper"

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrl: "./reset-password.component.scss",
})
export class ResetPasswordComponent {
  fh?: FormHelper
  code?: string
  submitSuccess?: boolean
  errorText: string = ""
  resetPasswordForm?: FormGroup

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
  ) {}

  async ngOnInit() {
    this.code = (await firstValueFrom(this.route.queryParams))["code"]
    if (!this.code) {
      console.log("code missing")
    }
    this.resetPasswordForm = this.formBuilder.group(
      {
        code: [this.code],
        password: [null, Validators.compose([Validators.minLength(8), Validators.required])],
        passwordConfirmation: [null, Validators.compose([Validators.minLength(8), Validators.required])],
      },
      { validators: BeStValidators.sameInput("password", "passwordConfirmation") },
    )
    this.fh = new FormHelper(this.resetPasswordForm)
  }

  onSubmit() {
    this.fh!.markFormTouched()
    this.fh!.submitAttempted = true
    if(!this.resetPasswordForm?.valid) return

    const request = this.resetPasswordForm!.value as ResetPasswordRequest
    if (!request) this.errorText = "E-Mail fehlt"

    this.authService.resetPassword(request).subscribe(
      (_: any) => {
        this.submitSuccess = true
        this.errorText = ""
      },
      (error: any) => {
        this.submitSuccess = false
        console.log(error)
        this.errorText =
          "Leider ist etwas schiefgelaufen. Bitte versuchen Sie es später erneut. Falls das Problem anhält kontaktieren Sie uns bitte im Messebüro."
      },
    )
  }

  protected controlError(control: string, error: string): any {
    return this.resetPasswordForm?.get(control)?.errors?.[error]
  }

  protected controlInvalidAndTouched(control: string) {
    return this.resetPasswordForm?.get(control)!.invalid && this.resetPasswordForm?.get(control)!.touched
  }
}
