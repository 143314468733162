import { Component, Input } from '@angular/core';

@Component({
  selector: 'messebereich-info',
  templateUrl: './messebereich-info.component.html',
  styleUrls: ['./messebereich-info.component.scss']
})
export class MessebereichInfoComponent  {
  @Input()
  title: string = ""
  @Input()
  text: string = ""
}
