import { Component, Input } from "@angular/core";

@Component({
    selector: 'menu-tree-node',
    template: 
    '<ul>' +
        '<li *ngFor="let node of menu">' +
        '<div><a [href]="node.Url">{{removeTags(node.Label)}}</a></div>'+
            '<menu-tree-node [menu]="node.subItems"></menu-tree-node>' +
        '</li>' +
    '</ul>',
    styleUrls: ['./sitemap.component.scss']
})
export class SitemapMenuTree {
    @Input() menu: any;
    removeTags(text: string) {
        return text.replace(/(<([^>]+)>)/ig, '');
    }
}