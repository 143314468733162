import { Component, OnInit, SecurityContext } from "@angular/core"
import { MarkdownService } from "ngx-markdown"
import { ApiService } from "../../api.service"
import { ActivatedRoute, Router } from "@angular/router"
import { Schwerpunkte } from "../shared/schwerpunkte"
import { Title, Meta, SafeHtml, DomSanitizer } from "@angular/platform-browser"
import { writeMeta } from "../shared/meta"
import { RteRendererService } from "../rte-renderer.service"
import { Bereiche } from "../shared/bereiche"

@Component({
  selector: "app-exhibitor-detail",
  templateUrl: "./exhibitor-detail.component.html",
  styleUrls: ["./exhibitor-detail.component.scss"]
})
export class ExhibitorDetailComponent implements OnInit {
  id: string = ""
  slugFailed = false
  data: object | any = null
  imgBaseUrl = ""
  schwerpunkte = Schwerpunkte.schwerpunkte
  schwerpunkteColorMapping = Schwerpunkte.schwerpunkteColorMapping
  rteContent: SafeHtml = "";

  constructor(private apiService: ApiService,
              private route: ActivatedRoute,
              private markdownService: MarkdownService,
              private router: Router,
              private title: Title,
              private meta: Meta,
              protected rteRenderer: RteRendererService,
              private sanitizer: DomSanitizer)  {
  }

  ngOnInit(): void {
    this.imgBaseUrl = this.apiService.apiUrl
    const originalLinkMethod = this.markdownService.renderer.link.bind(this.markdownService.renderer)

    this.markdownService.renderer.link = (
      href: string,
      title: string,
      text: string,
    ): string => {
      // Add target="_blank" to all links
      const modifiedLink = originalLinkMethod(href, title, text)
      // return `<a href="${href}" title="${title || ""}" target="_blank">${modifiedLink}</a>`
      return `<a href="${href}" title="${title || ""}" target="_blank">${text}</a>`
    }

    this.route.params.subscribe(params => {
      if (params && params["id"]) {
        this.id = params["id"]

        this.apiService.getExhibitorBySlug(this.id).subscribe({
          error: (error: any) => { 
            this.slugFailed = true; 
            console.error(error); 
          }, 
          next: (data: any) => { 
            if (!data.data[0]) {
              this.slugFailed = true
              console.error("data slugFailed", this.slugFailed)
            }

            if (!this.slugFailed) {
              this.data = {
                data: data.data[0],
              }
              this.rteContent = this.rteRenderer.renderContent(data.data[0].attributes.Content);
              let title = ""
              if ((this.data as any).data.attributes?.seo?.metaTitle) {
                title = (this.data as any).data.attributes?.seo?.metaTitle
              } else {
                title = (this.data as any).data.attributes?.name
              }

              writeMeta(this.title, this.meta, title,
                (this.data as any).data.attributes?.seo?.metaDescription,
                this.imgBaseUrl + (this.data as any).data.attributes?.seo?.shareImage?.media?.data?.attributes!.url,
                (this.data as any).data.attributes?.seo?.preventIndexing, this.imgBaseUrl);

              return;
            }
            this.apiService.getExhibitorById(this.id).subscribe({               
              next: (data: any) => {
                console.log("data 2", data)
                this.data = data
                this.rteContent = this.rteRenderer.renderContent(data.data.attributes.Content);

                let title = ""
                if ((this.data as any).data.attributes?.seo?.metaTitle) {
                  title = (this.data as any).data.attributes?.seo?.metaTitle
                } else {
                  title = (this.data as any).data.attributes?.name
                }

                writeMeta(this.title, this.meta, title,
                  (this.data as any).data.attributes?.seo?.metaDescription,
                  this.imgBaseUrl + (this.data as any).data.attributes?.seo?.shareImage?.media?.data?.attributes!.url,
                  (this.data as any).data.attributes?.seo?.preventIndexing, this.imgBaseUrl)
                this.data = data            
              },
              error: (error: any) => { 
                console.error(error);
                if(this.slugFailed) {
                  this.router.navigate(["/404"])
                }
              },
              
          })

          }
        });
      }
    });
  }

  debugOut(data: any) {
    console.log(data)
    return data
  }

  trimUrl(url: string) {
    if (url) {
      let cleanedUrl = url.replace(/^http:\/\//, "")
      cleanedUrl = cleanedUrl.replace(/\/$/, "")

      return cleanedUrl
    }

    return
  }

  renderMarkdown(md: string) {
    return this.markdownService.parse(md)
  }

  containsSchwerpunkt(item: any, schwerpunkt: string) {
    return Schwerpunkte.containsSchwerpunkt(item, schwerpunkt);
  }

  getColorFromSchwerpunkt(schwerpunkt: string) {
    return this.schwerpunkteColorMapping[schwerpunkt];
  }

  renderContactBlock(tel: string, email: string, www: string) {
    let output = ""
    if (tel) {
      output += `<a href="tel:${tel}">${tel}</a><br>`
    }
    if (email) {
      output += `<a href="mailto:${tel}">${email}</a><br>`
    }
    if (www) {
      output += `<a href="${www}" target="_blank">${this.trimUrl(www)}</a>`
    }

    return output
  }

  getFileText(document: any) {
    return document.attributes.name.replace(/\.[^/.]+$/, "") + 
            ' (' + 
            document.attributes.ext.slice(1).toUpperCase() + 
            ", " + 
            document.attributes.size + ' KB) ';
  }

  convertForMobile(content : any) {
    if(window.matchMedia("(max-width: 1024px)").matches) {
      const contentString = this.sanitizer.sanitize(SecurityContext.HTML, content);
      if(contentString === null) {
        return content;
      }
      return this.sanitizer.bypassSecurityTrustHtml(contentString.replace("<h1>", "<h1 style='margin-top: 0; margin-bottom: 0; font-size: 3rem;'>"));
    }

    return content;
  }

  getUrl() {
    return this.route.root;
  }

  getColorForBereich(bereich : string) {
    return Bereiche.bereichColorMapping[bereich] ? Bereiche.bereichColorMapping[bereich] : 'rgb(254, 234, 0)';
  }

  decodeContent(content: any) {
    let decoded = this.decodeHTMLEntities(content);
    if(decoded) {
      return this.sanitizer.bypassSecurityTrustHtml(decoded);
    } 

    return content;
  }

  decodeHTMLEntities (str: any) {
    var tempTextarea = document.createElement('textarea');
    tempTextarea.innerHTML = str;
    return tempTextarea.value;
  }
}
