<form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
  <div class="inputs-row">
    <app-best-input 
      label="E-Mail:"
      [formGroup]="forgotPasswordForm"
      controlName="email"
      type="email"
      [required]="true"
      [validatorErrors]="[{key: 'required', errorText: 'Dieses Feld muss ausgefüllt sein'},{key: 'pattern', errorText: 'Ungültige E-Mail'}]"
    />
  </div>

  <label class="error-text" *ngIf="submitSuccess != undefined && !submitSuccess">
    {{ errorText }}
  </label>

  <label class="success-text" *ngIf="submitSuccess">
    Sie sollten in Kürze eine E-Mail mit einem Link erhalten. Falls dies nicht der Fall ist melden Sie sich bitte beim
    Messebüro.
  </label>

  <label *ngIf="!forgotPasswordForm.valid && fh?.submitAttempted" class="error-text" style="padding-top: 32px"
    >Einige Felder wurden nicht richtig ausgefüllt. Bitte überprüfen sie ihre Angaben.
  </label>

  <button class="submit-button" type="submit">
    Absenden
    <svg
            xmlns="http://www.w3.org/2000/svg"
            shape-rendering="geometricPrecision"
            text-rendering="geometricPrecision"
            image-rendering="optimizeQuality"
            fill-rule="evenodd"
            clip-rule="evenodd"
            viewBox="0 0 512 512"
            class="arrow"
          >
            <path
              fill-rule="nonzero"
              d="M165 0c-8-1-16 6-17 15s7 17 16 17l274 19L5 484c-7 7-7 17 0 23 6 7 16 7 23 0L461 74l19 274c0 9 8 16 17 16s16-9 15-17L491 37c0-9-7-15-15-16L165 0z"
            />
          </svg>
  </button>
</form>
