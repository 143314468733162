import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from 'src/api.service';

@Component({
  selector: 'image-component',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
  @Input()
  image : any;

  imgBaseUrl = ""
  open = false;

  constructor(private sanitizer: DomSanitizer, private apiService: ApiService) {  
    console.log("image ", this.image);
  }

  ngOnInit(): void {
    this.imgBaseUrl = this.apiService.apiUrl
  }
}