import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from "@angular/core"
import { ApiService } from "src/api.service"
import { debounceTime, firstValueFrom, Subject, takeWhile } from "rxjs"
import { parseTimeToMilliseconds } from "../utils"
import { TagModel } from "src/models/tag-model"
import { Pagination, StrapiDataWrapper } from "src/models/strapi-wrapper"
import { Workshop } from "src/models/workshop"
import { WorkshopAnmeldungCreateRequest } from "src/models/workshop-anmeldung"
import { WorkshopPageModel } from "src/models/workshop-page-model"
import { HandleColorTypes, ListSideBarIconItem, ListSortOptions, SpeechListComponent } from "../speech-list/speech-list.component"
import { DynamicContentContainer } from "../dynamic-content-container"
import { ActivatedRoute } from "@angular/router"
import { SpeakerModel } from "src/models/speaker-model"
import { TimePipe } from "../speech-list/time.pipe"
import { EMAIL_REGEX } from "../validators"
import { NgForm } from "@angular/forms"

enum WorkshopSignupState {
  success,
  failDueToFull,
  fail,
  notDone,
  full,
}

@Component({
  selector: "app-workshop",
  templateUrl: "./workshop.component.html",
  styleUrl: "./workshop.component.scss",
})
export class WorkshopComponent extends DynamicContentContainer implements OnInit, OnDestroy {
  SUCCESS = WorkshopSignupState.success
  FAIL_DUE_TO_FULL = WorkshopSignupState.failDueToFull
  FAIL = WorkshopSignupState.fail
  NOTDONE = WorkshopSignupState.notDone
  FULL = WorkshopSignupState.full

  emailRegexPattern = EMAIL_REGEX

  private SIGNUP_STATES_KEY = "signupStates"

  protected submitting: boolean = false
  private _workshopSignups = new Map<number, WorkshopSignupState>()
  private _successfullSignups: number[] = []
  
  private _workshops?: StrapiDataWrapper<Workshop>[]
  days?: StrapiDataWrapper<TagModel>[]
  page?: WorkshopPageModel

  selectedDay?: StrapiDataWrapper<TagModel>

  pagination: Pagination = {
    page: 1,
    pageCount: 0,
    pageSize: 25,
    total: 0,
  }

  triggerReload: Subject<void> = new Subject()
  initialLoadDone = false

  @ViewChild("speechList")
  speechList?: SpeechListComponent<Workshop>
  @ViewChild("dynamicContent", { read: ViewContainerRef }) dynamicContentContainer!: ViewContainerRef

  startTimeSelector = (item: Workshop): string => item.Start
  endTimeSelector = (item: Workshop): string => item.Ende
  titleSelector = (item: Workshop): string => item.Titel
  subTitleSelector = (item: Workshop): string => item.Untertitel ?? ""
  institutionSelector = (item: Workshop): string | undefined => item.Institution
  daySelector = (item: Workshop): string => item.tag.data.attributes.Wochentag
  dateSelector = (item: Workshop): string | Date | undefined => item.tag.data.attributes.Datum
  speakersSelector = (item: Workshop): SpeakerModel[] | undefined => item.Speakers
  descriptionSelector = (item: Workshop): string => item.Beschreibung ?? ""
  locationSelector = (item: Workshop): string => item.workshop_bereich.data.attributes.Name
  handleColorSelector = (item: StrapiDataWrapper<Workshop>): HandleColorTypes => {
    switch(this.signupState(item.id)) {
      case WorkshopSignupState.notDone: return HandleColorTypes.default
      case WorkshopSignupState.success: return HandleColorTypes.success
      case WorkshopSignupState.failDueToFull: 
      case WorkshopSignupState.fail:
      case WorkshopSignupState.full: return HandleColorTypes.error
    }

  } 

  listIconItems: ListSideBarIconItem<Workshop>[] = [
    {
      icon: "clock",
      textSelector: (item: Workshop): string => {
        var startTime = this.startTimeSelector(item)
        return new TimePipe().transform(startTime);
      }
    },
    {
      icon: "geo-alt",
      textSelector: this.locationSelector
    },
    {
      icon: "card-checklist",
      textSelector: () => "Mit Voranmeldung"
    }
  ]

  listSortOptions: ListSortOptions<Workshop>[] = [
    {
      label: "Uhrzeit",
      default: true,
      key: "uhrzeit",
      selector: (item: Workshop) => item.Start
    },
    {
      label: "Institution A-Z",
      default: false,
      key: "instituttion",
      selector: (item: Workshop) => item.Institution ?? ""
    }
  ]

  constructor(private apiService: ApiService, private route: ActivatedRoute) {
    super()
    this.triggerReload
      .pipe(
        debounceTime(200),
        takeWhile(() => this.initialLoadDone),
      )
      .subscribe({
        next: (_) => {
          this.getWorkshops().then(() => this.setWorkshopSignups())
        },
      })
  }

  get loading(): boolean {
    return this.days == undefined || this._workshops == undefined
  }

  async ngOnInit() {
    this.loadSuccessfullSignupsFromStorage()
    await this.getPageWithDays()
    await this.paramMapCalculations()

    this.startPageDynamicContents = this.page?.page?.data?.attributes.DynamicContent ?? []
    this.initDynamicContents(this.dynamicContentContainer)

    this.updateUrl()

    this.initialLoadDone = true
  }

  ngOnDestroy() {
    this.saveSuccessfullSignupsToStorage()
  }

  async paramMapCalculations() {
    var paramMap = await firstValueFrom(this.route.paramMap)
    let paramDay = paramMap.get("tag")

    if (!paramDay) paramDay = "0"

    var dayId = 0
    try {
      dayId = parseInt(paramDay ?? "0")
    } catch {}
    if (dayId > 0) {
      this.selectedDay = this.days?.find((d) => d.id == dayId)
    } else {
      this.selectedDay = this.days?.at(0)
    }
  }

  updateUrl() {
    window.history.replaceState(
      {},
      "",
      `workshops/tag/${this.selectedDay?.id}`,
    )
  }
  

  //MARK: SIGN UP LOGIC
  private saveSuccessfullSignupsToStorage(){
    localStorage.setItem(this.SIGNUP_STATES_KEY, JSON.stringify(this._successfullSignups))
  }

  private setWorkshopSignups() {
    this._workshops?.forEach((w) => {
      const storageState = this._successfullSignups.find((v) => v == w.id)
      var state = this.NOTDONE
      if (storageState) {
        state = this.SUCCESS
      } else {
        state = this.signupStateBySpace(w)
      }

      this._workshopSignups.set(w.id, state)
    })
    console.log(this._workshopSignups)
  }

  private signupStateBySpace(workshop: StrapiDataWrapper<Workshop>) {
    return workshop.attributes.Platzanzahl > (workshop.attributes.signups ?? 0) ? this.NOTDONE : this.FULL
  }

  signupState(workshopId: number): WorkshopSignupState {
    return this._workshopSignups.get(workshopId) ?? WorkshopSignupState.notDone
  }
  
  private loadSuccessfullSignupsFromStorage(): void{
    var states = localStorage.getItem(this.SIGNUP_STATES_KEY)
    if (!states) {
      this._successfullSignups = []
      return
    } 

    this._successfullSignups = JSON.parse(states) as number[]
  }

  async signup(ngForm: NgForm, workshop: StrapiDataWrapper<Workshop>) {
    this.submitting = true
    const formValue = ngForm.value as { email: string; name: string }
    var signupRequest: WorkshopAnmeldungCreateRequest = {
      Email: formValue.email,
      Name: formValue.name,
      workshop: workshop.id,
    }

    try {
      const result = await firstValueFrom(this.apiService.createWorkshopAnmeldung(signupRequest))
      workshop.attributes.signups = result.data?.attributes.signups ?? workshop.attributes.signups
      this._successfullSignups.push(workshop.id)
      this.saveSuccessfullSignupsToStorage()
      this._workshopSignups.set(workshop.id, this.SUCCESS)
    } catch (err: any) {
      console.log(err);
      if(err.error.error.status == 403 && err.error.error.details.full){
        this._workshopSignups.set(workshop.id, this.FAIL_DUE_TO_FULL)
        workshop.attributes.signups =  err.error.error.details.signups
      }
      else {
        this._workshopSignups.set(workshop.id, this.FAIL)
      }
    } finally {
      this.submitting = false
    }
  }  

  async getWorkshops() {
    var result = await firstValueFrom(
      this.apiService.getWorkshops(this.selectedDay?.id ?? 0, this.pagination),
    )
    this.speechList?.closeAll()
    this._workshops = result.data
    this.pagination = result.meta?.pagination!
  }

  async getPageWithDays() {
    const wrappedPage = await firstValueFrom(this.apiService.getWorkshopPage())
    if (wrappedPage && wrappedPage.data) {
      this.page = wrappedPage.data.attributes
      this.days = this.page.tage.data
    }
  }

  get workshops(): StrapiDataWrapper<Workshop>[] | undefined {
    if (!this.selectedDay) return undefined

    return this._workshops
  }


  daySelected(day: StrapiDataWrapper<TagModel>) {
    this.selectedDay = day
    this.updateUrl()
    this.triggerReload.next(undefined)
  }

  onRemoveDayFilter() {
    this.selectedDay = undefined;
  }

  goToPage(param: string | number) {
    var oldPage = this.pagination.page
    if (typeof param === "string") {
      if (param == "previous") {
        this.pagination.page = Math.max(this.pagination.page - 1, 1)
      }
      if (param == "next") {
        this.pagination.page = Math.min(this.pagination.pageCount, this.pagination.page + 1)
      }
    } else {
      this.pagination.page = param
    }

    if (oldPage == this.pagination.page) return

    this.triggerReload.next()
  }
}
