<app-form-field-label
  *ngIf="label"
  [label]="label"
  [required]="required"
  [helpText]="helpText"
  [ngClass]="labelClass"
/>

<div class="flex flex-col" [class.flex-1]="labelPosition === 'left'">
  <ng-content />

  <p *ngIf="(control?.touched || control?.dirty) && control?.invalid" class="validation error">
    <span>
      <ng-content select="[error]" />
    </span>
  </p>
</div>
