import { Component, EventEmitter, Input, Output, signal, TemplateRef, ViewChild } from "@angular/core"
import { MatAccordion } from "@angular/material/expansion"
import { BühneModel } from "src/models/bühne-model"
import { SpeakerModel } from "src/models/speaker-model"
import { Pagination, StrapiDataWrapper } from "src/models/strapi-wrapper"

export interface ListSideBarIconItem<T> {
  icon: string;
  textSelector: (item: T) => any;
}

export interface ListSortOptions<T> {
  label: string;
  key: string;
  selector: (item: T) => string;
  default: boolean;
}

export enum HandleColorTypes {
  default,
  success,
  error
}

@Component({
  selector: "app-speech-list",
  templateUrl: "./speech-list.component.html",
  styleUrl: "./speech-list.component.scss",
})
export class SpeechListComponent<T> {
  @Input({ required: true })
  items!: StrapiDataWrapper<T>[]

  @Input()
  selectedStage: BühneModel | undefined

  @Input()
  stages?: StrapiDataWrapper<BühneModel>[]

  @Input()
  stageTitle: string = ""
  @Input()
  dayTitle: string = ""
  @Input()
  dayDate: string = ""
  @Input()
  pagination?: Pagination
  @Input({required: true})
  startTimeSelector: (item: T) => string = (_) => ""
  @Input({required: true})
  endTimeSelector: (item: T) => string = (_) => ""
  @Input({required: true})
  titleSelector: (item: T) => string = (_) => ""
  @Input({required: true})
  subTitleSelector: (item: T) => string = (_) => ""
  @Input({required: true})
  institutionSelector: (item: T) => string | undefined = (_) => undefined
  @Input({required: true})
  daySelector: (item: T) => string = (_) => ""
  @Input({required: true})
  dateSelector: (item: T) => string | Date | undefined = (_) => undefined
  @Input({required: true})
  speakersSelector: (item: T) => SpeakerModel[] | undefined = (_) => undefined
  @Input({required: true})
  descriptionSelector: (item: T) => string = (_) => ""
  @Input({required: true})
  locationSelector: (item: T) => string = (_) => ""
  @Input()
  handleColorSelector: (item: StrapiDataWrapper<T>) => HandleColorTypes = (_) => HandleColorTypes.default
  

  @Input({required: true})
  iconItems: ListSideBarIconItem<T>[] = []
  @Input({required: true})
  sortOptions: ListSortOptions<T>[] = [
    {
      label: "Uhrzeit",
      default: true,
      key: "uhrzeit",
      selector: (_) => ""
    },
    {
      label: "Institution A-Z",
      default: false,
      key: "instituttion",
      selector: (_) => ""
    }
  ]
  
  @Input() customPanelTemplate?: TemplateRef<{ $implicit: StrapiDataWrapper<T> }>;
  @Input() customContentTemplate?: TemplateRef<{ $implicit: StrapiDataWrapper<T> }>;

  @ViewChild(MatAccordion)
  accordion?: MatAccordion

  @Output()
  goToPage: EventEmitter<string | number> = new EventEmitter()
  
  @Output()
  panelOpenStateChanged: EventEmitter<number> = new EventEmitter()

  protected panelOpenState = -1

  public closeAll() {
    this.accordion?.closeAll()
    this.panelOpenStateChanged.emit(-1)
  }
  
  protected openPanel(id: number) {
    this.panelOpenState = id
    this.panelOpenStateChanged.emit(this.panelOpenState)
  }
  
  protected closePanel(id: number) {
    if(this.panelOpenState == id){
      this.panelOpenState = -1
      this.panelOpenStateChanged.emit(this.panelOpenState)
    } 
  }

  onDropdownChangeSort(event: Event) {
    const selectedOption = (event.target as HTMLSelectElement).value;
    console.log("selected ", selectedOption);

    var sortOption = this.sortOptions.find((v) => v.key == selectedOption)
    if(!sortOption) return
    
    this.items.sort((s1, s2) => sortOption!.selector(s1.attributes).localeCompare(sortOption!.selector(s2.attributes)));
  }
}
