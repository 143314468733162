import { FormHelper } from "./../form-helper"
import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from "@angular/core"
import { FormBuilder, FormControl, FormGroup } from "@angular/forms"
import { Subject, firstValueFrom } from "rxjs"
import { ApiService } from "src/api.service"
import { AuthenticationService } from "src/authentication.service"
import { environment } from "src/environments/environment"
import {
  ExhibitorModel,
  ExhibitorUpdateResponseModel,
  exhibitorUpdateResponseModelToExhibitorModel,
} from "src/models/exhibitor-model"
import { RichTextValidators } from "../slidelizard-richtext/rich-text-input/validators"
import { BeStValidators } from "../validators"
import { FileType, UploadConfig } from "../file-card-container/file-card-container.component"
import { StrapiListWrapper, StrapiSingleWrapper } from "src/models/strapi-wrapper"
import { CountryModel } from "src/models/country-model"
import { DynamicContentContainer } from "../dynamic-content-container"
import { SingletonPageModel } from "src/models/singleton-page-model"

@Component({
  selector: "app-exhibitor-form",
  templateUrl: "./exhibitor-form.component.html",
  styleUrl: "./exhibitor-form.component.scss",
})
export class ExhibitorFormComponent extends DynamicContentContainer implements OnDestroy, OnInit {
  fh?: FormHelper
  exhibitorForm?: FormGroup
  protected _onDestroy = new Subject<void>()
  protected exhibitor?: ExhibitorModel
  protected saveSuccess?: boolean
  protected fileUploadFailed: boolean = false
  protected bannerUrl?: string
  protected iconUrl?: string
  protected Allgemeine_Info_Einfuehrung?: string
  protected Angebot_auf_der_BeSt?: string
  protected Schwerpunkte_Beratungsangebot?: string
  protected Schwerpunkte_Bildungsangebot?: string
  protected Zielgruppen?: string
  protected Aufnahmebedingungen?: string
  protected Kosten?: string
  protected Dauer_Ausbildung_Weiterbildung?: string
  protected Abschluss?: string
  protected countries?: StrapiListWrapper<CountryModel>

  protected banner: any

  protected bannerUploadConfig?: UploadConfig
  protected bannerAllowTypes: FileType[] = [FileType.image]
  protected documentUploadConfig?: UploadConfig
  protected documentAllowTypes: FileType[] = [FileType.document]

  page?: StrapiSingleWrapper<SingletonPageModel>

  @ViewChild("dynamicContent", { read: ViewContainerRef }) dynamicContentContainer!: ViewContainerRef
  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private authService: AuthenticationService,
  ) {
    super()
    this.imgBaseUrl = apiService.apiUrl
  }

  async ngOnInit() {
    if (!this.authService.user?.id) return

    this.apiService.getExhibitorSelfservicePage().subscribe((data: StrapiSingleWrapper<SingletonPageModel>) => {
      this.page = data
      this.startPageDynamicContents = this.page?.data.attributes.page?.data.attributes.DynamicContent ?? []
      this.initDynamicContents(this.dynamicContentContainer)
    })

    var user = await firstValueFrom(this.apiService.getUser())
    this.countries = await firstValueFrom(this.apiService.getCountries())
    this.exhibitor = user.exhibitor
    this.bannerUploadConfig = {
      field: "all_banners",
      ref: "api::exhibitor.exhibitor",
      refId: this.exhibitor?.id.toString() ?? "",
    }
    this.documentUploadConfig = {
      field: "all_documents",
      ref: "api::exhibitor.exhibitor",
      refId: this.exhibitor?.id.toString() ?? "",
    }

    this.bannerUrl = this.exhibitor?.banner?.url ? environment.apiUrl + this.exhibitor?.banner?.url : undefined
    this.iconUrl = this.exhibitor?.icon?.url ? environment.apiUrl + this.exhibitor?.icon?.url : undefined

    this.Allgemeine_Info_Einfuehrung = this.exhibitor?.Allgemeine_Info_Einfuehrung ?? ""
    this.Angebot_auf_der_BeSt = this.exhibitor?.Angebot_auf_der_BeSt ?? ""
    this.Schwerpunkte_Beratungsangebot = this.exhibitor?.Schwerpunkte_Beratungsangebot ?? ""
    this.Schwerpunkte_Bildungsangebot = this.exhibitor?.Schwerpunkte_Bildungsangebot ?? ""
    this.Zielgruppen = this.exhibitor?.Zielgruppen ?? ""
    this.Aufnahmebedingungen = this.exhibitor?.Aufnahmebedingungen ?? ""
    this.Kosten = this.exhibitor?.Kosten ?? ""
    this.Dauer_Ausbildung_Weiterbildung = this.exhibitor?.Dauer_Ausbildung_Weiterbildung ?? ""
    this.Abschluss = this.exhibitor?.Abschluss ?? ""

    if (this.exhibitor && !this.exhibitor!.all_documents) this.exhibitor.all_documents = []
    if (this.exhibitor && !this.exhibitor!.all_banners) this.exhibitor.all_banners = []

    var country = this.exhibitor?.country as CountryModel | undefined
    this.exhibitorForm = this.formBuilder.group(
      {
        uidNumber: [this.exhibitor?.uidNumber],
        street: [this.exhibitor?.street],
        zipcode: [this.exhibitor?.zipcode],
        city: [this.exhibitor?.city],
        country: [country?.id],
        state: [this.exhibitor?.state],
        tel: [this.exhibitor?.tel, BeStValidators.phoneNumber()],
        email: [this.exhibitor?.email, BeStValidators.email()],
        www: [this.exhibitor?.www, BeStValidators.link()],
        tel2: [this.exhibitor?.tel2, BeStValidators.phoneNumber()],
        email2: [this.exhibitor?.email2, BeStValidators.email()],
        www2: [this.exhibitor?.www2, BeStValidators.link()],
        tel3: [this.exhibitor?.tel3, BeStValidators.phoneNumber()],
        email3: [this.exhibitor?.email3, BeStValidators.email()],
        www3: [this.exhibitor?.www3, BeStValidators.link()],
        banner: [this.exhibitor?.banner?.id ?? null],
        Dokumente: [this.exhibitor?.Dokumente?.map((d) => d.id) ?? []],
        Allgemeine_Info_Einfuehrung: [
          this.Allgemeine_Info_Einfuehrung,
          [RichTextValidators.required(), RichTextValidators.maxLength(1500)],
        ],
        Angebot_auf_der_BeSt: [
          this.Angebot_auf_der_BeSt,
          [RichTextValidators.required(), RichTextValidators.maxLength(1500)],
        ],
        Schwerpunkte_Beratungsangebot: [
          this.Schwerpunkte_Beratungsangebot,
          [RichTextValidators.required(), RichTextValidators.maxLength(500)],
        ],
        Schwerpunkte_Bildungsangebot: [this.Schwerpunkte_Bildungsangebot, [RichTextValidators.maxLength(500)]],
        Zielgruppen: [this.Zielgruppen, [RichTextValidators.maxLength(500)]],
        Aufnahmebedingungen: [this.Aufnahmebedingungen, [RichTextValidators.maxLength(1500)]],
        Kosten: [this.Kosten, [RichTextValidators.maxLength(500)]],
        Dauer_Ausbildung_Weiterbildung: [this.Dauer_Ausbildung_Weiterbildung, [RichTextValidators.maxLength(500)]],
        Abschluss: [this.Abschluss, [RichTextValidators.maxLength(500)]],
        Instagram: [this.exhibitor?.Instagram, BeStValidators.instagramUrlValidator()],
        YouTube: [this.exhibitor?.YouTube, BeStValidators.youtubeUrlValidator],
        Twitter_X: [this.exhibitor?.Twitter_X, BeStValidators.twitterUrlValidator()],
        Facebook: [this.exhibitor?.Facebook, BeStValidators.facebookUrlValidator()],
      },
      { validators: BeStValidators.atLeastOneFieldRequired(["tel", "email", "www"]) },
    )
    this.fh = new FormHelper(this.exhibitorForm)
  }

  protected richtextCounter(richtext: string): number {
    if (richtext.length == 0) return 0

    const htmlTagRegex = /<\/?[^>]+(>|$)/g
    return richtext.replace(htmlTagRegex, "").length
  }

  protected setBanner(event: any) {
    this.banner = event.target.files[0]
  }

  ngOnDestroy(): void {
    this._onDestroy.next()
    this._onDestroy.complete()
  }

  //MARK: Submit
  async onSubmit() {
    this.fh!.markFormTouched()
    this.fh!.submitAttempted = true
    if(!this.exhibitorForm?.valid) return

    var request = this.exhibitorForm!.value as ExhibitorModel

    this.apiService.putExhibitor(this.exhibitor?.id ?? 0, request).subscribe(
      (response: StrapiSingleWrapper<ExhibitorUpdateResponseModel>) => {
        this.saveSuccess = true
        var responseExhibitor: ExhibitorUpdateResponseModel = { ...response.data.attributes, id: response.data.id }
        var newExhibitor = exhibitorUpdateResponseModelToExhibitorModel(responseExhibitor)
        this.exhibitor = newExhibitor
        this.exhibitor!.id = response.data.id
      },
      (_: any) => {
        this.saveSuccess = false
      },
    )
  }

  protected fileUploadFailedCallback(event: boolean) {
    this.fileUploadFailed = event
  }
}
