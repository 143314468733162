
  export class Bereiche {
    public static bereichColorMapping : Record<string, string> = {
        "A" : "#FBC269",
        "B": "#C9D6DE",
        "G": "#E52E1F",
        "I": "#63B22F",
        "J": "#D73D8D",
        "K": "#F7A912",
        "M": "#08B4C5",
        "S": "#E84892",
        "T": "#009C41",
        "W": "#41ACE2",
        "U": "#FDEA14"
      };
  }