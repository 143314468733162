<div class="contacts-container">
  <div class="title">Ihre Ansprechpartner</div>
  <p>Bei Fragen und Anliegen steht Ihnen das Messebüro der BeSt Wien jederzeit zur Verfügung.</p>
  @for (contact of contacts; track $index) {
  <div class="contact">
    <p>
      <b>{{ contact.Name }}</b>
    </p>
    <p>Tel: {{ contact.Tel }}</p>
    <p>
      E-Mail: <a [href]="'mailto:' + contact.Email">{{ contact.Email }}</a>
    </p>
  </div>
  }
</div>
