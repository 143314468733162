<form *ngIf="resetPasswordForm && code" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
  <div class="inputs-row">
    <app-best-input 
      label="Neues Passwort:"
      [formGroup]="resetPasswordForm"
      controlName="password"
      type="password"
      [required]="true"
      infoText="Mindestens 8 Zeichen."
      [validatorErrors]="[{key: 'required', errorText: 'Dieses Feld muss ausgefüllt sein'}, {key: 'minlength', errorText: 'Dieses Feld muss mindestens 8 Zeichen lang sein'}]"
    />
    <app-best-input 
      label="Neues Passwort wiederholen:"
      [formGroup]="resetPasswordForm"
      controlName="passwordConfirmation"
      type="password"
      [required]="true"
      infoText="Mindestens 8 Zeichen."
      [validatorErrors]="[{key: 'required', errorText: 'Dieses Feld muss ausgefüllt sein'}, {key: 'minlength', errorText: 'Dieses Feld muss mindestens 8 Zeichen lang sein'}]"
    >
      @if(resetPasswordForm.errors?.['sameInput'] && resetPasswordForm.get('passwordConfirmation')?.touched) {
      <span class="error-text">Die eingegebenen Passwörter stimmen nicht überein</span>
      }
    </app-best-input>
  </div>

  <label class="error-text" *ngIf="submitSuccess != undefined && !submitSuccess">
    {{ errorText }}
  </label>

  <label class="success-text" *ngIf="submitSuccess">
    Ihr Passwort sollte nun geändert sein. Sie können jetzt versuchen sich wieder einzuloggen.
  </label>

  <label *ngIf="!resetPasswordForm.valid && fh?.submitAttempted" class="error-text" style="padding-top: 32px"
    >Einige Felder wurden nicht richtig ausgefüllt. Bitte überprüfen sie ihre Angaben.
  </label>

  <button class="submit-button" type="submit">
    Passwort ändern
    <svg
            xmlns="http://www.w3.org/2000/svg"
            shape-rendering="geometricPrecision"
            text-rendering="geometricPrecision"
            image-rendering="optimizeQuality"
            fill-rule="evenodd"
            clip-rule="evenodd"
            viewBox="0 0 512 512"
            class="arrow"
          >
            <path
              fill-rule="nonzero"
              d="M165 0c-8-1-16 6-17 15s7 17 16 17l274 19L5 484c-7 7-7 17 0 23 6 7 16 7 23 0L461 74l19 274c0 9 8 16 17 16s16-9 15-17L491 37c0-9-7-15-15-16L165 0z"
            />
          </svg>
  </button>
</form>

<label *ngIf="!code && resetPasswordForm" class="error-text" style="padding-top: 32px"
  >Anscheinend ist Ihr Link nicht korrekt und/oder vollständig. Bitte versuchen Sie es erneut.
</label>
