import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from 'src/api.service';

@Component({
  selector: 'rich-text-component',
  templateUrl: './rich-text.component.html',
  styleUrls: ['./rich-text.component.scss']
})
export class RichTextComponent implements OnInit {
  @Input()
  content : any;

  imgBaseUrl = ""
  open = false;

  constructor(private sanitizer: DomSanitizer, private apiService: ApiService) {  
  }

  ngOnInit(): void {
    this.imgBaseUrl = this.apiService.apiUrl
  }
}