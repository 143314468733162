<app-breadcrump-nav [url]="getUrl()"></app-breadcrump-nav>
<h1>Aussteller A bis Z</h1>
<div class="aussteller-index-info">
  <p>An der BeSt Wien beteiligen sich zahlreiche Aussteller aus dem Bereich Beruf,
    Studium und Weiterbildung. Dazu zählen Universitäten und Fachhochschulen, Akademien
    und Kollegs, private Bildungsanbieter und Sprachschulen sowie Unternehmen, Interessenvertretungen,
    Ministerien und Behörden. 
  </p>
  <p>
    Unser umfangreiches und detailliertes Ausstellerverzeichnis macht Ihnen die gezielte Suche nach Information zu allen
    Ausstellern der BeSt leicht. Nutzen Sie die umfassenden Filter-, Sortier- und Suchfunktionen um eine Trefferliste mit maximaler
    Relevanz für Ihre Interessen zu erhalten. Klicken Sie auf einen Aussteller, gelangen Sie zum Ausstellerprofil mit den Details. Die
    Zugehörigkeit der Aussteller zu den Messebereichen und Schwerpunkten ist farblich markiert.</p>
  <p>
    <b class="sub-info-text">Das Ausstellerverzeichnis wird laufend aktualisiert.</b>
  </p>
</div>
<div>
  <span class="subTitle">Ausstellerverzeichnis</span>
  <h3 class="super-container-title">Aussteller finden</h3>
</div>
<div class="super-container">
  <div class="super-schwerpunkte">
    <span>Schwerpunkte</span>
    <ul>
      <li *ngFor="let schwerpunkt of schwerpunkte">
        <svg class="schwerpunkt-arrow" xmlns="http://www.w3.org/2000/svg" width="92.592" height="81.018"
             viewBox="0 0 25.314 21.094">
          <defs>
            <clipPath id="a" clipPathUnits="userSpaceOnUse">
              <path d="M-882 221H253v-719H-882Z" />
            </clipPath>
          </defs>
          <path d="m0 0 36-63h-72Z" clip-path="url(#a)"
                style="fill:{{getColorForSchwerpunkt(schwerpunkt)}};fill-opacity:1;fill-rule:nonzero;stroke:none"
                transform="matrix(.35159 0 0 -.35159 12.657 -.528)" />
        </svg>
        <a class="schwerpunkt-link" (click)="filterBySchwerpunkt(schwerpunkt)" [ngStyle]="{'text-decoration': getSchwerpunktTextDecoration(schwerpunkt)}" attr.aria-label="Filtern nach Schwerpunkt {{schwerpunkt}}">{{schwerpunkt}}</a>
      </li>
    </ul>
  </div>
  <div class="super-search">
    <span>Aussteller</span>
    <div class="alphabet-filter">
      <span class="letter-search" *ngFor="let letter of upperCaseAlphabet" [ngStyle]="{'text-decoration' : this.search.letter === letter ? 'underline' : 'none' }" (click)="searchWithLetter(letter)">{{letter}}</span>
    </div>
    <span class="filter-search-explanation-text">Alphabetische Suche</span>
    <div class="search-container" id="ausstellerAnchor">
        <div class="search-name">
          <input  class="name-search-input" type="text" [(ngModel)]="search.name" (keyup.enter)="onSearch()" (blur)="onSearch()" placeholder="Nach Namen suchen...">
          <button class="search-button" (click)="onSearch()"><svg width="38px" height="38px" viewBox="0 0 24 24" stroke-width="1.5" fill="#000000" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M17 17L21 21" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3 11C3 15.4183 6.58172 19 11 19C13.213 19 15.2161 18.1015 16.6644 16.6493C18.1077 15.2022 19 13.2053 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11Z" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg></button>
        </div>
        <span class="filter-search-explanation-text">Bitte den Namen des Ausstellers eingeben</span>
        <div class="search-schlagwort">
          <div class="select-border">
            <mat-form-field class="filter-select" subscriptSizing="dynamic">
              <mat-select (selectionChange)="onSchlagwortSelected($event.value)" placeholder="Nach Stichwort suchen...">
                <mat-option style="background-color: white; ">
                  <ngx-mat-select-search
                  [formControl]="schlagwortesFilter"
                ></ngx-mat-select-search>
                </mat-option>
                <mat-option style="background-color: white; border-bottom: 1px solid grey; height: auto;" *ngFor="let schlagwort of filteredSchlagworte | async" class="selectedFilter" [value]="schlagwort">{{schlagwort}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <span class="filter-search-explanation-text">Bitte das Stichwort auswählen</span>
    </div>

    <span>Messebereiche</span>
    <div class="messebereiche-filter">
      <div *ngFor="let bereich of bereichNameMappingEntries" class="bereich-info" (click)="searchBereich(bereich)">
        <span class="bereich-letter" [ngStyle]="{'--bereich-color': getColorForBereich(bereich[0])}">{{bereich[0]}}</span> 
        <p class="bereich-name" [ngStyle]="{'text-decoration' : getMessebereichTextDecoration(bereich[1])}">{{bereich[1]}}</p>
      </div>
    </div>
    <div class="messebereich-filter-selectbox">
      <div class="select-border">
        <mat-form-field class="filter-select" subscriptSizing="dynamic">
          <mat-select (selectionChange)="bereichSelectionChanged($event)" multiple style="display: inline-block;">
            @for (bereich of bereichNameMappingEntries; track bereich) {
              <mat-option [value]="bereich" style="background-color: white; border-bottom: 1px solid grey; height: auto;">{{bereich[1]}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>

<div *ngIf="(this.search.letter && this.search.letter !== 'Alle') ||
    (this.search.bereiche.length > 1 || this.search.bereiche[0] !== 'Alle') ||
    (this.search.schlagworte.length > 0 && this.search.schlagworte[0] !== 'Alle') ||
    (this.search.schwerpunkte.length > 0 && this.search.schwerpunkte[0] !== 'Alle')" class="filter-container">
    <h3 class="active-filter">Filter</h3>
    <div class="filter-tags">
      <span class="filter-tag" *ngIf="search.letter && search.letter !== 'Alle'">
        Aussteller mit {{ search.letter }}
        <button (click)="onRemoveLetterFilter()"  class="remove-filter-tag"><svg width="20px" height="20px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="grey"><path d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg></button>
      </span>
      <span class="filter-tag" *ngFor="let schlagwort of search.schlagworte">
        <span *ngIf="schlagwort !== 'Alle'" class="filter-text">{{ schlagwort }}</span>
        <span *ngIf="schlagwort !== 'Alle'">
          <button (click)="onRemoveSchlagwortFilter(schlagwort)"  class="remove-filter-tag"><svg width="20px" height="20px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="grey"><path d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg></button>
        </span>
      </span> 
      <span class="filter-tag" *ngFor="let messebereich of search.bereiche">
        <span *ngIf="messebereich !== 'Alle'"class="filter-text">{{ messebereich.includes(':') ? messebereich.split(':')[1] : messebereich }}</span>
        <span *ngIf="messebereich !== 'Alle'">
          <button (click)="onRemoveMessebereichFilter(messebereich)"  class="remove-filter-tag"><svg width="20px" height="20px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="grey"><path d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg></button>
        </span>
      </span> 
      <span class="filter-tag" *ngFor="let schwerpunkte of search.schwerpunkte">
        <span *ngIf="schwerpunkte !== 'Alle'"class="filter-text">{{ schwerpunkte }}</span>
        <span *ngIf="schwerpunkte !== 'Alle'">
          <button (click)="onRemoveSchwerpunktFilter(schwerpunkte)"  class="remove-filter-tag"><svg width="20px" height="20px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="grey"><path d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg></button>
        </span>
      </span>
    </div>        
</div>
<div *ngIf="data" class="letter-links-container">
  <div class="first-letter-links">
  </div>
  <div>
    <span>{{results}} Resultate</span>
  </div>
  <div>
    <div>Seite {{search.page}} von {{search.maxPages}}</div>
    <div class="pagination">
      <button class="pagination-prev" (click)="goToPage('previous')">
        <svg xmlns="http://www.w3.org/2000/svg" width="243.06" height="92.59" viewBox="0 0 66.442 24.106">
          <defs>
            <clipPath id="a" clipPathUnits="userSpaceOnUse">
              <path d="M-882 221H253v-719H-882Z" />
            </clipPath>
          </defs>
          <path d="m0 0 36-63h-72Z" clip-path="url(#a)"
                style="fill:#000 icc-color(sRGB-IEC61966-2,.1,0,0,0);fill-opacity:1;fill-rule:nonzero;stroke:none"
                transform="matrix(0 .28127 .28127 0 20.072 12.183)" />
          <path d="m0 0 36-63h-72Z" clip-path="url(#a)"
                style="fill:#000 icc-color(sRGB-IEC61966-2,.1,0,0,0);fill-opacity:1;fill-rule:nonzero;stroke:none"
                transform="matrix(0 .28127 .28127 0 42.192 12.183)" />
          <path d="m0 0 36-63h-72Z" clip-path="url(#a)"
                style="fill:#000 icc-color(sRGB-IEC61966-2,.1,0,0,0);fill-opacity:1;fill-rule:nonzero;stroke:none"
                transform="matrix(0 .27987 .27987 0 64.283 12.132)" />
        </svg>
      </button>
      <button class="pagination-next" (click)="goToPage('next')">
        <svg xmlns="http://www.w3.org/2000/svg" width="243.06" height="92.59" viewBox="0 0 66.442 24.106">
          <defs>
            <clipPath id="a" clipPathUnits="userSpaceOnUse">
              <path d="M-882 221H253v-719H-882Z" />
            </clipPath>
          </defs>
          <path d="m0 0 36-63h-72Z" clip-path="url(#a)"
                style="fill:#000 icc-color(sRGB-IEC61966-2,.1,0,0,0);fill-opacity:1;fill-rule:nonzero;stroke:none"
                transform="matrix(0 .28127 .28127 0 20.072 12.183)" />
          <path d="m0 0 36-63h-72Z" clip-path="url(#a)"
                style="fill:#000 icc-color(sRGB-IEC61966-2,.1,0,0,0);fill-opacity:1;fill-rule:nonzero;stroke:none"
                transform="matrix(0 .28127 .28127 0 42.192 12.183)" />
          <path d="m0 0 36-63h-72Z" clip-path="url(#a)"
                style="fill:#000 icc-color(sRGB-IEC61966-2,.1,0,0,0);fill-opacity:1;fill-rule:nonzero;stroke:none"
                transform="matrix(0 .27987 .27987 0 64.283 12.132)" />
        </svg>
      </button>
    </div>
  </div>

  <div class="sort-container" *ngIf="data!.data.length > 0">     
    <select class="selectOrder" #selectOrder (change)="onDropdownChangeSort($event)">
      <option value="option1" selected disabled>Sortieren nach</option>
      <option value="name">Aussteller A - Z</option>
      <option value="koje">Standnummer</option>
    </select>
  </div>
  <div>
    <div *ngFor="let item of data!.data">
      <div class="aussteller-header">
        <div class="mobile-top-information">
          <div class="aussteller-koje">
            <span class="koje-background" [ngStyle]="{'--koje-color': getColorForBereich(item!.attributes!.bereich | splitCode)}">
              {{ item!.attributes!.bereich | splitCode }} {{ item!.attributes!.koje }}
            </span>
          </div> 
          <div class="schwerpunkt-indicators top-schwerpunkt">
            <span class="schwerpunkt-indicator" *ngFor="let schwerpunkt of schwerpunkte">
              <svg class="{{schwerpunkt}} {{containsSchwerpunkt(item, schwerpunkt)}}" xmlns="http://www.w3.org/2000/svg" width="64.824" height="74.085" viewBox="0 0 17.72 19.288"><defs><clipPath
                id="a" clipPathUnits="userSpaceOnUse"><path d="M-882 221H253v-719H-882Z" /></clipPath></defs><path
                d="m0 0 36-63h-72Z" clip-path="url(#a)"
                style="fill:#000 icc-color(sRGB-IEC61966-2,.1,0,0,0);fill-opacity:1;fill-rule:nonzero;stroke:none"
                transform="matrix(0 .28127 .28127 0 17.72 9.524)" /></svg>
            </span>
          </div>
        
        </div>
        <span class="aussteller-title" *ngIf="item!.attributes.slug"><a href="best-besuch/aussteller/{{item!.attributes.slug}}">{{ item!.attributes!.name }}</a></span>
        <span class="aussteller-title" *ngIf="!item!.attributes.slug"><a href="best-besuch/aussteller/{{item!.id}}">{{ item!.attributes!.name }}</a></span>

        <img class="aussteller-icon" [src]="imgBaseUrl + item?.attributes?.icon?.data?.attributes?.formats?.thumbnail?.url"
             [alt]="item?.attributes?.icon?.data?.attributes.alternativeText"
             *ngIf="item?.attributes?.icon?.data?.attributes?.formats?.thumbnail?.url">
        <img class="aussteller-icon" [alt]="'Kein Bild vorhanden'"
             *ngIf="!item?.attributes?.icon?.data?.attributes?.formats?.thumbnail?.url">
        <div class="schwerpunkt-indicators bottom-schwerpunkt">
            <span class="schwerpunkt-indicator" *ngFor="let schwerpunkt of schwerpunkte">
              <svg class="{{schwerpunkt}} {{containsSchwerpunkt(item, schwerpunkt)}}" xmlns="http://www.w3.org/2000/svg" width="64.824" height="74.085" viewBox="0 0 17.72 19.288"><defs><clipPath
                id="a" clipPathUnits="userSpaceOnUse"><path d="M-882 221H253v-719H-882Z" /></clipPath></defs><path
                d="m0 0 36-63h-72Z" clip-path="url(#a)"
                style="fill:#000 icc-color(sRGB-IEC61966-2,.1,0,0,0);fill-opacity:1;fill-rule:nonzero;stroke:none"
                transform="matrix(0 .28127 .28127 0 17.72 9.524)" /></svg>
            </span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="data!.data.length > 0">
    <div>Seite {{search.page}} von {{search.maxPages}}</div>
    <div class="pagination">
      <button class="pagination-prev" (click)="goToPage('previous')">
        <svg xmlns="http://www.w3.org/2000/svg" width="243.06" height="92.59" viewBox="0 0 66.442 24.106">
          <defs>
            <clipPath id="a" clipPathUnits="userSpaceOnUse">
              <path d="M-882 221H253v-719H-882Z" />
            </clipPath>
          </defs>
          <path d="m0 0 36-63h-72Z" clip-path="url(#a)"
                style="fill:#000 icc-color(sRGB-IEC61966-2,.1,0,0,0);fill-opacity:1;fill-rule:nonzero;stroke:none"
                transform="matrix(0 .28127 .28127 0 20.072 12.183)" />
          <path d="m0 0 36-63h-72Z" clip-path="url(#a)"
                style="fill:#000 icc-color(sRGB-IEC61966-2,.1,0,0,0);fill-opacity:1;fill-rule:nonzero;stroke:none"
                transform="matrix(0 .28127 .28127 0 42.192 12.183)" />
          <path d="m0 0 36-63h-72Z" clip-path="url(#a)"
                style="fill:#000 icc-color(sRGB-IEC61966-2,.1,0,0,0);fill-opacity:1;fill-rule:nonzero;stroke:none"
                transform="matrix(0 .27987 .27987 0 64.283 12.132)" />
        </svg>
      </button>
      <button class="pagination-next" (click)="goToPage('next')">
        <svg xmlns="http://www.w3.org/2000/svg" width="243.06" height="92.59" viewBox="0 0 66.442 24.106">
          <defs>
            <clipPath id="a" clipPathUnits="userSpaceOnUse">
              <path d="M-882 221H253v-719H-882Z" />
            </clipPath>
          </defs>
          <path d="m0 0 36-63h-72Z" clip-path="url(#a)"
                style="fill:#000 icc-color(sRGB-IEC61966-2,.1,0,0,0);fill-opacity:1;fill-rule:nonzero;stroke:none"
                transform="matrix(0 .28127 .28127 0 20.072 12.183)" />
          <path d="m0 0 36-63h-72Z" clip-path="url(#a)"
                style="fill:#000 icc-color(sRGB-IEC61966-2,.1,0,0,0);fill-opacity:1;fill-rule:nonzero;stroke:none"
                transform="matrix(0 .28127 .28127 0 42.192 12.183)" />
          <path d="m0 0 36-63h-72Z" clip-path="url(#a)"
                style="fill:#000 icc-color(sRGB-IEC61966-2,.1,0,0,0);fill-opacity:1;fill-rule:nonzero;stroke:none"
                transform="matrix(0 .27987 .27987 0 64.283 12.132)" />
        </svg>
      </button>
    </div>
  </div>
  <!--  <h2>Schlagwortliste</h2>-->
  <!--  <select (change)="filterBySchlagwort($event)">-->
  <!--    <option *ngFor="let schlagwort of schlagworte">{{schlagwort}}</option>-->
  <!--  </select>-->
</div>  
<messebereich-info 
  title="<p> <strong> MESSEBEREICHE / </strong> <i> FARBLEITSYSTEME </i> </p>" 
  text="<p> Die Messekojen sind farblich gekennzeichneten Bereichen zugeordnet, deren genaue Bezeichnungen
  auf der Seite Hallenplan zu finden sind. Die Anfangsbuchstaben der Bereichsnamen sind Teil der
  Kojenummern. Die jeweilige Bereichsfarbe scheint im Messeplan und auf den Blenden der Kojen auf.</p>"></messebereich-info>
<!--<div *ngIf="data">-->
<!--  <div *ngFor="let item of data!.data">-->
<!--    <figure>-->
<!--      <img class="img-fluid" [src]="imgBaseUrl + item!.attributes!.banner!.data!.attributes.url" [alt]="item!.attributes!.banner!.data!.attributes.alternativeText" >-->
<!--    </figure>-->
<!--    <h1>{{ item!.attributes!.name }}</h1>-->

<!--    &lt;!&ndash; Example: accessing name property if it exists &ndash;&gt;-->
<!--    <h2>Adresse</h2>-->
<!--    <p>-->
<!--      {{ item!.attributes!.street }}-->
<!--    </p>-->
<!--    <p>{{ item!.attributes!.zipcode }}{{ item!.attributes!.city }}</p>-->
<!--    <p>{{ item!.attributes!.country }}</p>-->
<!--    <hr>-->
<!--    <p>Koje: {{ item!.attributes!.koje }}</p>-->
<!--    <p>Keyword: {{ item!.attributes!.keyword  }}</p>-->
<!--    <p>Keywords: {{ item!.attributes!.keywords  }}</p>-->

<!--    <button (click)="debugOut(item!.attributes!.banner!.data!.attributes)">Log banner to Console</button>-->

<!--    &lt;!&ndash; You can access other properties in a similar way &ndash;&gt;-->
<!--  </div>-->
<!--</div>-->
