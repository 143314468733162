<div *ngIf="header" class="header-container">
  <div class="title-image-container image-container-top">
    <img class="title-image" [src]="imgBaseUrl + header!['Image']['data']['attributes']['url']">
  </div>
  <div class="header-text-container">
    <div class="subtitle-container">
      <div class="triangle-container">
        <img class="triangle-right" src="./assets/triangle-up.svg">
        <img class="triangle-right" src="./assets/triangle-up.svg">
        <img class="triangle-right" src="./assets/triangle-up.svg">
      </div>
      <span class="subTitle">{{header!['SubTitle']}}</span>
    </div>
    <div class="header-text" [innerHTML]="getHTMLContent(header!['Title'])"></div>
    <div class="header-text" [innerHTML]="header!['Text']"></div>
    <div class="header-text info-link" *ngIf="header!['Link']">
      <a [href]="header!['Link']['Url']" target="_blank">
        <span  [innerHTML]="convetLinkTextForHTML(header!['Link']['Label'])"></span>
        <img src="./assets/arrow-thin-top-right-corner-icon.svg" class="arrow">
      </a>
    </div>
  </div>
  <div class="title-image-container image-container-bottom" *ngIf="header!['Image']['data']">
    <img class="title-image" [src]="imgBaseUrl + header!['Image']['data']['attributes']['url']">
  </div>
</div>