import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../api.service"

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  menuItemsData: any = null;
  constructor(private apiService: ApiService) { }
  ngOnInit(): void {
    this.apiService.getFooterMenu().subscribe(
      (data:any) => {
        this.menuItemsData = data;
      },
      (error:any) => {
        console.error(error);
      }
    );
  }

  scrollToTop() {
     window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
