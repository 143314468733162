import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms"
const htmlTagRegex = /<\/?[^>]+(>|$)/g

export class RichTextValidators {
  static maxLength(maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string | undefined | null

      if (value == null || value == undefined || value === "") {
        return null
      }

      return value.replace(htmlTagRegex, "").length <= maxLength ? null : { max: true }
    }
  }

  static required(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string | undefined | null

      if (value == null || value == undefined || value === "") {
        return { required: true }
      }

      return value.replace(htmlTagRegex, "").length > 0 ? null : { required: true }
    }
  }
}
