import { Component, Input, OnInit, Optional } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { ApiService } from 'src/api.service';

export type HtmlString = string
export enum ComponentType { 'Image', 'RichText' };
export type component = {
  type: ComponentType,
  data: any
}

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      deps: [[Optional, NgForm]],
      useFactory: (ngForm: NgForm) => ngForm,
    },
  ],
})
export class GalleryComponent implements OnInit {
  public ComponentTypeEnum = ComponentType;
  baseUrl = ""
  galleryComponents: component[] = [];
  
  gallerySlug = "gallery";
  title = "";

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.baseUrl = this.apiService.apiUrl;
    this.apiService.getPageBySlug("galerie").subscribe({
      error: (error: any) => { console.log(error); }, 
      next: (data: any) => { 
        if(!data.data[0]) {
          return; 
        }
        let startPageDynamicContents = data.data[0].attributes.DynamicContent;
        this.title = data.data[0].attributes.Title;
        for (let component of startPageDynamicContents) {
          if (component["__component"] === "content-blocks.double-image-content-block") {          
            this.galleryComponents.push(
              {
                type : ComponentType.Image,
                data : component!["Media"]["data"]
              }  
            );
          }
          if (component["__component"] === "content-blocks.rich-text-content-block") {                                  
            this.galleryComponents.push(
              {
                type : ComponentType.RichText,
                data : component!["HtmlContent"]
              }
            );
          }          
        }
      }
    });

  }

}