import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core"
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms"
import { Router } from "@angular/router"
import { AuthenticationService } from "src/authentication.service"
import { AuthenticationResponse } from "src/models/authentication/authentication-response"
import { LoginRequest } from "src/models/authentication/login-request"
import { FormHelper } from "../form-helper"
import { ApiService } from "src/api.service"
import { DynamicContentContainer } from "../dynamic-content-container"
import { StrapiSingleWrapper } from "src/models/strapi-wrapper"
import { LoginPageModel } from "src/models/login-page-model"

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrl: "./login.component.scss",
})
export class LoginComponent extends DynamicContentContainer implements OnInit {
  fh: FormHelper
  loginForm: FormGroup
  stayLoggedInCtrl: FormControl = new FormControl(false)
  loginSuccess?: boolean
  errorText: string = ""
  page?: StrapiSingleWrapper<LoginPageModel>
  
  @ViewChild("dynamicContent", { read: ViewContainerRef }) dynamicContentContainer!: ViewContainerRef
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private apiService: ApiService,
  ) {
    super()
    this.imgBaseUrl = apiService.apiUrl
    this.loginForm = this.formBuilder.group({
      identifier: [null, Validators.required],
      password: [null, Validators.required],
    })
    this.fh = new FormHelper(this.loginForm)
  }

  ngOnInit() {
    this.apiService.getLoginPage().subscribe((data: any) => {
      this.page = data
      this.startPageDynamicContents = this.page?.data.attributes.page?.data.attributes.DynamicContent ?? []
      this.initDynamicContents(this.dynamicContentContainer)
    })
  }

  onSubmit() {
    this.fh!.markFormTouched()
    this.fh!.submitAttempted = true
    if(!this.loginForm?.valid) return

    var request = this.loginForm.value as LoginRequest
    this.authService.login(request, this.stayLoggedInCtrl.value as boolean).subscribe(
      (_: AuthenticationResponse) => {
        this.loginSuccess = true
        setTimeout(() => this.router.navigate(["/"]), 5000)
      },
      (error: any) => {
        this.loginSuccess = false
        console.log(error)
        if (error?.error?.error?.message === "Your account has been blocked by an administrator" ?? false) {
          this.errorText =
            "Es sieht so aus als wurde ihre Registierung noch nicht von unserem Team bearbeitet. Bitte haben sie noch geduld."
        } else {
          this.errorText = "E-Mail oder Passwort ist ungültig."
        }
      },
    )
  }
}
