import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { mdiInformationOutline } from '@mdi/js';

@Component({
  selector: 'app-form-field-label',
  templateUrl: './form-field-label.component.html',
  styleUrls: ['./form-field-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldLabelComponent {
  protected readonly infoIcon = mdiInformationOutline;

  @Input()
  label!: string;

  @Input()
  required: boolean = false;

  @Input()
  helpText?: string;
}
