import { NgModule } from "@angular/core"
import { AppRoutingModule } from "./app-routing.module"
import { AppComponent } from "./app.component"
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http"
import {
  NgbModule,
  NgbCollapseModule,
  NgbDropdownModule,
  NgbOffcanvasModule,
  NgbActiveOffcanvas,
} from "@ng-bootstrap/ng-bootstrap"
import { MarkdownModule } from "ngx-markdown"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { Nav2Component } from "./nav2/nav2.component"
import { FooterComponent } from "./footer/footer.component"
import { NotFoundComponent } from "./not-found/not-found.component"
import { ExhibitorIndexComponent } from "./exhibitor-index/exhibitor-index.component"
import { ExhibitorDetailComponent } from "./exhibitor-detail/exhibitor-detail.component"
import { SitemapComponent } from "./sitemap/sitemap.component"
import { SplitCode, SplitDescription } from "./shared/split-bereich.pipe"
import { PageComponent } from "./page/page.component"
import { PasswordGuard } from "./password.guard"
import { ExhibitorConverterComponent } from "./exhibitor-converter/exhibitor-converter.component"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { HtmlFormatPipe } from "./html-format.pipe"
import { SchlagwortIndexComponent } from "./schlagwort-index/schlagwort-index.component"
import { SchwerpunktIndexComponent } from "./schwerpunkt-index/schwerpunkt-index.component"
import { StageComponent } from "./stage/stage.component"
import { MatFormFieldModule } from "@angular/material/form-field"
import { FormatSchwerpunktText } from "./shared/format-schwerpunkt-text"
import { CardGalleryComponent } from "./startpage-modules/card-gallery/card-gallery.component"
import { TwoColumnStartpageTextComponent } from "./startpage-modules/two-column-startpage-text/two-column-text.component"
import { SliderComponent } from "./startpage-modules/slider/slider.component"
import { InfoComponent } from "./info/info.component"
import { StartPageHeader } from "./startpage-modules/startpage-header/startpage-header.component"
import { MessebereichInfoComponent } from "./messebereich-info/messebereich-info.component"
import { VideoComponent } from "./startpage-modules/video/video.component"
import { RegisterComponent } from "./register/register.component"
import { LoginComponent } from "./login/login.component"
import { AuthInterceptor } from "./auth.interceptor"
import { ExhibitorFormComponent } from "./exhibitor-form/exhibitor-form.component"
import { NgxMatSelectSearchModule } from "ngx-mat-select-search"
import { MatSelectModule } from "@angular/material/select"
import { OverlayModule } from "@angular/cdk/overlay"
import { LogoutComponent } from "./logout/logout.component"
import { NgxEditorModule } from "ngx-editor"
import { FormFieldLabelComponent } from "./slidelizard-richtext/form-field/form-field-label/form-field-label.component"
import { FormFieldComponent } from "./slidelizard-richtext/form-field/form-field.component"
import { RichTextInputComponent } from "./slidelizard-richtext/rich-text-input/rich-text-input.component"
import { MatTooltipModule } from "@angular/material/tooltip"
import { BaseInputDirective } from "./slidelizard-richtext/base-input.directive"
import { AddSpanToH1Pipe } from "./add-span-to-h1.pipe"
import { FileCardComponent } from "./file-card/file-card.component"
import { FileCardContainerComponent } from "./file-card-container/file-card-container.component"
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component"
import { ResetPasswordComponent } from "./reset-password/reset-password.component"
import { ContactsComponent } from "./contacts/contacts.component"
import { DocumentsListComponent } from "./startpage-modules/documents-list/documents-list.component"
import { BreadcrumpPropertiesNavComponent } from "./breadcrump-nav/breadcrump-nav.component"
import { EmptyTopMenuOverviewComponent } from "./empty-menu-overview/empty-menu-overview.component"
import { LightboxComponent } from "./startpage-modules/lightbox/lightbox.component"
import { DaySelectorComponent } from "./day-selector/day-selector.component"
import { StageSelectorComponent } from "./stage-selector/stage-selector.component"
import { ToggleComponent } from "./toggle/toggle.component"
import { InformationItemComponent } from "./information-item/information-item.component"
import { SpeechListComponent } from "./speech-list/speech-list.component"
import { MatExpansionModule } from "@angular/material/expansion"
import { TimePipe } from "./speech-list/time.pipe"
import { WorkshopComponent } from "./workshop/workshop.component"
import { SitemapMenuTree } from "./sitemap/sitemap-menu-tree"
import { TopPageMenu } from "./top-page-menu/top-page-menu.component"
import { GalleryComponent } from "./gallery/gallery.component"
import { ImageComponent } from "./startpage-modules/image/image.component"
import { RichTextComponent } from "./startpage-modules/rich-text/rich-text.component"
import { BestInputComponent } from "./form-inputs/best-input/best-input.component"

@NgModule({
  declarations: [
    AppComponent,
    Nav2Component,
    EmptyTopMenuOverviewComponent,
    BreadcrumpPropertiesNavComponent,
    StartPageHeader,
    SitemapMenuTree,
    CardGalleryComponent,
    LightboxComponent,
    InfoComponent,
    ImageComponent,
    RichTextComponent,
    SliderComponent,
    VideoComponent,
    GalleryComponent,
    TwoColumnStartpageTextComponent,
    FooterComponent,
    TopPageMenu,
    NotFoundComponent,
    ExhibitorIndexComponent,
    ExhibitorDetailComponent,
    MessebereichInfoComponent,
    SitemapComponent,
    SplitCode,
    SplitDescription,
    FormatSchwerpunktText,
    PageComponent,
    ExhibitorConverterComponent,
    HtmlFormatPipe,
    SchlagwortIndexComponent,
    SchwerpunktIndexComponent,
    StageComponent,
    WorkshopComponent,
    RegisterComponent,
    LoginComponent,
    ExhibitorFormComponent,
    LogoutComponent,
    RichTextInputComponent,
    FormFieldComponent,
    FormFieldLabelComponent,
    BaseInputDirective,
    AddSpanToH1Pipe,
    FileCardComponent,
    FileCardContainerComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ContactsComponent,
    DocumentsListComponent,
    DaySelectorComponent,
    StageSelectorComponent,
    ToggleComponent,
    InformationItemComponent,
    SpeechListComponent,
    TimePipe,
    BestInputComponent,
  ],
  imports: [
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    NgbDropdownModule,
    NgbCollapseModule,
    NgbOffcanvasModule,
    HttpClientModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MarkdownModule.forRoot(),
    OverlayModule,
    NgxEditorModule,
    MatTooltipModule,
    MatExpansionModule,
  ],
  providers: [
    NgbActiveOffcanvas,
    PasswordGuard,
    HtmlFormatPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    AddSpanToH1Pipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
