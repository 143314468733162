import { Component } from "@angular/core"
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { AuthenticationService } from "src/authentication.service"
import { ForgotPasswordRequest } from "src/models/authentication/forgot-password-request"
import { BeStValidators } from "../validators"
import { FormHelper } from "../form-helper"

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrl: "./forgot-password.component.scss",
})
export class ForgotPasswordComponent {
  forgotPasswordForm: FormGroup
  submitSuccess?: boolean
  errorText: string = ""
  fh: FormHelper

  constructor(private formBuilder: FormBuilder, private authService: AuthenticationService) {
    this.forgotPasswordForm = this.formBuilder.group({
      email: [null, Validators.compose([BeStValidators.email(), Validators.required])],
    })
    this.fh = new FormHelper(this.forgotPasswordForm)
  }

  protected onSubmit() {
    this.fh!.markFormTouched()
    this.fh!.submitAttempted = true
    if(!this.forgotPasswordForm?.valid) return

    const request = this.forgotPasswordForm.value as ForgotPasswordRequest
    if (!request) this.errorText = "E-Mail fehlt"

    this.authService.forgotPassword(request).subscribe(
      (_: any) => {
        this.submitSuccess = true
        this.errorText = ""
      },
      (error: any) => {
        this.submitSuccess = false
        console.log(error)
        this.errorText =
          "Leider ist etwas schiefgelaufen. Bitte versuchen Sie es später erneut. Falls das Problem anhält kontaktieren Sie uns bitte im Messebüro."
      },
    )
  }
}
