import { AuthenticationResponse } from "../../models/authentication/authentication-response"
import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core"
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { Router } from "@angular/router"
import { AuthenticationService } from "src/authentication.service"
import { RegistrationRequest } from "src/models/authentication/registration-request"
import { BeStValidators } from "../validators"
import { FormHelper } from "../form-helper"
import { SingletonPageModel } from "src/models/singleton-page-model"
import { StrapiSingleWrapper } from "src/models/strapi-wrapper"
import { ApiService } from "src/api.service"
import { DynamicContentContainer } from "../dynamic-content-container"

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrl: "./register.component.scss",
})
export class RegisterComponent extends DynamicContentContainer implements OnInit {
  fh: FormHelper
  registerForm: FormGroup
  registrationSuccess?: boolean
  page?: StrapiSingleWrapper<SingletonPageModel>

  @ViewChild("dynamicContent", { read: ViewContainerRef }) dynamicContentContainer!: ViewContainerRef
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private apiService: ApiService,
  ) {
    super()
    this.imgBaseUrl = apiService.apiUrl
    this.registerForm = this.formBuilder.group(
      {
        email: [null, Validators.compose([BeStValidators.email(), Validators.required])],
        tel: [null, Validators.compose([BeStValidators.phoneNumber(), Validators.required])],
        firstName: [null, Validators.required],
        lastName: [null, Validators.required],
        password: [null, Validators.compose([Validators.minLength(8), Validators.required])],
        passwordConfirmation: [null, Validators.compose([Validators.minLength(8), Validators.required])],
        agb: [false, Validators.requiredTrue],
        ageCheck: [false, Validators.requiredTrue],
      },
      { validators: BeStValidators.sameInput("password", "passwordConfirmation") },
    )
    this.fh = new FormHelper(this.registerForm)
  }

  ngOnInit() {
    this.apiService.geRegistrationPage().subscribe((data: StrapiSingleWrapper<SingletonPageModel>) => {
      this.page = data
      this.startPageDynamicContents = this.page?.data.attributes.page?.data.attributes.DynamicContent ?? []
      this.initDynamicContents(this.dynamicContentContainer)
    })
  }

  onSubmit() {
    this.fh!.markFormTouched()
    this.fh!.submitAttempted = true
    if(!this.registerForm?.valid) return

    var request = this.registerForm.value as RegistrationRequest
    var names = []
    if (request.firstName) names.push(request.firstName)
    if (request.lastName) names.push(request.lastName)
    request.username = names.join(" ")

    this.authService.register(request).subscribe(
      (_: AuthenticationResponse) => {
        this.registrationSuccess = true
        setTimeout(() => this.router.navigate(["/"]), 5000)
      },
      (_: any) => {
        this.registrationSuccess = false
      },
    )
  }
}
