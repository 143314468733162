<div class="lightbox" *ngIf="mediaItems.length > 1">
  <button class="prev prev-top" (click)="prev()">
    <div>
      <img class="triangle-left" src="./assets/triangle-up.svg">
      <img class="triangle-left" src="./assets/triangle-up.svg">
      <img class="triangle-left" src="./assets/triangle-up.svg">
    </div>
  </button>
  <img *ngIf="isImage(currentItem)" class="lightbox-image" id="galleryimage" (click)="showFullImage()" [src]="imgBaseUrl + mediaItems[currentItem]['attributes']['url']"/>
  <video *ngIf="isVideo(currentItem)" controls class="lightbox-image" id="galleryimage" (click)="showFullVideo()" [src]="imgBaseUrl + mediaItems[currentItem]['attributes']['url']">
  </video>
  <button class="next next-top" (click)="next()">
    <div>
      <img class="triangle-right" src="./assets/triangle-up.svg">
      <img class="triangle-right" src="./assets/triangle-up.svg">
      <img class="triangle-right" src="./assets/triangle-up.svg">
    </div>
  </button>
  <div class="mobile-gallery-nav">
    <button class="prev prev-bottom" (click)="prev()">
      <div>
        <img class="triangle-left" src="./assets/triangle-up.svg">
        <img class="triangle-left" src="./assets/triangle-up.svg">
        <img class="triangle-left" src="./assets/triangle-up.svg">
      </div>
    </button>
    <button class="next next-bottom" (click)="next()">
      <div>
        <img class="triangle-right" src="./assets/triangle-up.svg">
        <img class="triangle-right" src="./assets/triangle-up.svg">
        <img class="triangle-right" src="./assets/triangle-up.svg">
      </div>
    </button>
  </div>
  <br>
</div>
<div id="currentImage">
  <img *ngIf="isImage(currentItem)" class="hide"  [src]="imgBaseUrl + mediaItems[currentItem]['attributes']['url']" >
  <video *ngIf="isVideo(currentItem)" controls class="hide" [src]="imgBaseUrl + mediaItems[currentItem]['attributes']['url']" ></video>  
</div>

<div id="gallery-overview" *ngIf="mediaItems.length > 1">
  <div *ngFor="let imgt of getShowedImages()">
    <img *ngIf="isImage(imgt)" [src]="imgBaseUrl + mediaItems[imgt]['attributes']['url']" class="gallery-overview-item" (click)="focusClickOnGallery(mediaItems[imgt]['attributes']['name'])">
    <video *ngIf="isVideo(imgt)" [src]="imgBaseUrl + mediaItems[imgt]['attributes']['url']" class="gallery-overview-item" (click)="focusClickOnGallery(mediaItems[imgt]['attributes']['name'])">
    </video>
  </div>
</div>
<div *ngIf="mediaItems.length === 1" class="lightbox">
  <img *ngIf="isImage(currentItem)" class="lightbox-image" id="galleryimage" [src]="imgBaseUrl + mediaItems[currentItem]['attributes']['url']">
  <video *ngIf="isVideo(currentItem)" class="lightbox-image" id="galleryimage" [src]="imgBaseUrl + mediaItems[currentItem]['attributes']['url']"></video>
</div>