<div class="video-container" *ngIf="title">
  <div class="video-title">
    <div class="video-text" [innerHTML]="convertTitleForMobile()"></div>
    <div class="video-triangle-container">
      <img class="triangle-right" src="./assets/triangle-up.svg">
      <img class="triangle-right" src="./assets/triangle-up.svg">
      <img class="triangle-right" src="./assets/triangle-up.svg">
    </div>
  </div>
  <div class="video-con2">
    <video [muted]="'muted'" loop autoplay>
      <source [src]="videoUrl">
    </video>
  </div>
</div>