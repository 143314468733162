export class Schwerpunkte {
  public static schwerpunkte: string[] = ["Studium", "International", "Weiterbildung", "Job Direkt", "14+", "Barrierefrei"]
  public static schwerpunkteWithAll: string[] = ["Alle", "Studium", "International", "Weiterbildung", "Job Direkt", "14+", "Barrierefrei"]
  public static schwerpunkteColorMapping : Record<string, string> = {
    "Studium" : "#F7A824",
    "International": "#E94262",
    "Weiterbildung": "#7F5DA4",
    "Job direkt": "#0466AA",
    "14+": "#B0DEF0",
    "Barrierefrei": "#46AA9C"
  };
  public static containsSchwerpunkt(item: any, schwerpunkt: string) {
    let keys = Object.keys(item.attributes.schwerpus.data);
    for(let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = item.attributes.schwerpus.data[key];
      if(value.attributes.Schwerpunkt === schwerpunkt) {
        return "highlighted"
      }
    }
    return ""
  }
}

