import { Directive, ElementRef, Output, EventEmitter, HostListener, Component, inject, TemplateRef, OnInit} from '@angular/core';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from "../../api.service"
import { Router } from "@angular/router"
import { AuthenticationService } from "src/authentication.service"

@Component({
  selector: 'app-nav2',
  templateUrl: './nav2.component.html',
  styleUrls: ['./nav2.component.scss']
})
export class Nav2Component implements OnInit {
  imgBaseUrl: string = "";
  isLargeScreen = true;
  shrinkMenu: boolean = false;
  hideMenu: boolean = false;
  shrinkTriggerPosition: number = 200;
  scrollTriggerPosition: number = 1200;
  previousPosition: number = 0;

  devIndex = 0;
  devShow = false;

  // activeOffcanvas = inject(NgbActiveOffcanvas);
  offcanvasService = inject(NgbOffcanvas);

  menuItemsData: any = null;
  @Output() clickOutside = new EventEmitter<void>();

  @HostListener('document:click', ['$event.target'])
  public onPageClick(targetElement: HTMLElement) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.devShow = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    this.isLargeScreen = window.innerWidth >= 1200;
    if(this.isLargeScreen) {
      this.closeScroll();
    }
  }

  openMenu() {
    this.offcanvasService.open("Test", { position: 'start' })
  }

  openScroll(content: TemplateRef<any>) {
    this.offcanvasService.open(content, { scroll: true, position: 'start' });
  }

  closeScroll() {
    this.offcanvasService.dismiss();
    // this.offcanvasService.open(content, { scroll: true, position: 'end' });
  }

  constructor(private apiService: ApiService, private router: Router, protected authService: AuthenticationService, private elementRef: ElementRef) { }
  ngOnInit(): void {
    this.imgBaseUrl = this.apiService.apiUrl;
    this.checkScreenSize();
    // this.onWindowScroll();

    this.apiService.getMenu().subscribe(
      (data:any) => {
        this.menuItemsData = data;
      },
      (error:any) => {
        console.error(error);
      }
    );
  }

  // @HostListener('window:scroll', [])
  // onWindowScroll() {
  //   let direction = 0;  // 0 == down, 1 = up;
  //   direction = this.previousPosition < window.pageYOffset ? 0 : 1;
  //   this.previousPosition = window.pageYOffset;
  //
  //   if(window.pageYOffset > this.shrinkTriggerPosition) {
  //     this.shrinkMenu = true;
  //   } else {
  //     this.shrinkMenu = false;
  //   }
  //
  //   this.hideMenu = window.pageYOffset >= this.scrollTriggerPosition;
  //   if(direction == 1) {  // if we're scrolling up, immediately bring up the menu again
  //     this.hideMenu = false;
  //   }
  // }

  toggleMenu(i:number) {
    this.devIndex = i;
    this.devShow = !this.devShow;
  }

  hideMenuHandler() {
    this.devShow = false;
  }

  protected filterSubMenuItems(items: any[]) {
    if (!items) return []
    return items.filter(
      (i) =>
        !i.Filter ||
        i.Filter == "allways" ||
        (this.authService.isLoggedIn() && i.Filter == "auth") ||
        (!this.authService.isLoggedIn() && i.Filter == "unauth"),
    )
  }
}
