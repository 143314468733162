import { ValidatorFn, AbstractControl, Validators, ValidationErrors } from "@angular/forms"

export const LINK_REGEX = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w-]*)*$/
export const PHONE_REGEX = /^\+?[0-9]*$/
export const FACEBOOK_REGEX = /^(https?:\/\/)?(www\.)?facebook\.com\/.+$/
export const YOUTUBE_REGEX = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/).+$/
export const INSTAGRAM_REGEX = /^(https?:\/\/)?(www\.)?instagram\.com\/.+$/
export const TWITTER_REGEX = /^(https?:\/\/)?(www\.)?(twitter\.com|x\.com)\/.+$/
export const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

export class BeStValidators {
  static atLeastOneFieldRequired(fields: string[]): ValidatorFn {
    return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
      const isAnyFieldFilled = fields.some((field) => {
        const control = formGroup.get(field)
        return control && control.value
      })

      return isAnyFieldFilled ? null : { atLeastOneFieldRequired: true }
    }
  }

  static phoneNumber(): ValidatorFn {
    return Validators.pattern(PHONE_REGEX)
  }

  static link(): ValidatorFn {
    return Validators.pattern(LINK_REGEX)
  }

  static facebookUrlValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const url = control.value
      if (url == null || url == undefined || url === "") return null
      const regex = FACEBOOK_REGEX
      return regex.test(url) ? null : { facebookUrl: true }
    }
  }

  static youtubeUrlValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const url = control.value
      if (url == null || url == undefined || url === "") return null
      const regex = YOUTUBE_REGEX
      return regex.test(url) ? null : { youtubeUrl: true }
    }
  }

  static instagramUrlValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const url = control.value
      if (url == null || url == undefined || url === "") return null
      const regex = INSTAGRAM_REGEX
      return regex.test(url) ? null : { instagramUrl: true }
    }
  }

  static twitterUrlValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const url = control.value
      if (url == null || url == undefined || url === "") return null
      const regex = TWITTER_REGEX
      return regex.test(url) ? null : { twitterUrl: true }
    }
  }

  static email(): ValidatorFn {
    return Validators.pattern(EMAIL_REGEX)
  }

  static sameInput(firstField: string, secondField: string): ValidatorFn {
    return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
      const firstControl = formGroup.get(firstField)
      const secondControl = formGroup.get(secondField)

      const same = firstControl && secondControl && firstControl.value === secondControl.value

      return same ? null : { sameInput: true }
    }
  }
}
