import { Component, EventEmitter, Input, Output } from "@angular/core"
import { BühneModel } from "src/models/bühne-model"
import { StrapiDataWrapper } from "src/models/strapi-wrapper"

@Component({
  selector: "app-stage-selector",
  templateUrl: "./stage-selector.component.html",
  styleUrl: "./stage-selector.component.scss",
})
export class StageSelectorComponent {
  @Input({ required: true })
  stages!: StrapiDataWrapper<BühneModel>[]

  @Input()
  location: string = ""

  @Output()
  onStageSelected: EventEmitter<StrapiDataWrapper<BühneModel> | undefined> = new EventEmitter()

  @Input()
  selectedStage?: StrapiDataWrapper<BühneModel>

  selectStage(stage: StrapiDataWrapper<BühneModel>) {
    if (this.selectedStage?.id == stage.id) {
      this.selectedStage = undefined
      this.onStageSelected.emit(undefined)
    } else {
      this.selectedStage = stage
      this.onStageSelected.emit(this.selectedStage)
    }
  }

  selected(stage: StrapiDataWrapper<BühneModel>): boolean {
    if (!this.selectedStage) return true

    return this.selectedStage?.id === stage.id
  }

  stageCounter(stage: StrapiDataWrapper<BühneModel>) {
    return this.stages.findIndex((d) => d.id === stage.id) + 1
  }
}
