<app-breadcrump-nav [url]="getUrl()"></app-breadcrump-nav>
<div style="max-width: 100%;">
  <h1 *ngIf="pathItem && pathItem.attributes" [innerHTML]="getTitle(pathItem.attributes.Label)"></h1>
  <h1 *ngIf="pathItem && !pathItem.attributes" [innerHTML]="getTitle(pathItem.Label)"></h1>
  <div *ngIf="getSubItems()">
    <div *ngFor="let subItem of getSubItems()">
      > <a [href]="subItem.Url"><span [innerHTML]="subItem.Label"></span></a>
    </div>
  </div>
</div>
