<!--desktop bar-->
<div class="sticky-container" [class.shrink]="shrinkMenu" [class.hidden]="hideMenu"><!-- [class.shrink]="shrinkMenu" -->
  <nav class="desktop" [ngbCollapse]="!isLargeScreen">
    <a class="home-link" href="#">
      <img src="../assets/BeSt_Logo_RGB-Zusatz_klein_sanitized_mod_2.svg" alt="BeSt³ Logo" class="logo" />
    </a>
    <div class="menu-container">
      <div class="menu">
        <ul ngbNav>
          <ng-container *ngFor="let menuItem of menuItemsData?.data; let i = index">

            <li ngbNavItem ngbDropdown>
              <button ngbNavLink
                      [class.isSubdued]="menuItem.attributes.isSubdued"
                      [class.isOpen]="devIndex == i && devShow"
                      (click)="toggleMenu(i)"><span [innerHTML]="menuItem.attributes.Label | htmlFormat"></span>
              </button>
              <ul *ngIf="menuItem.attributes.subItems" ngbDropdownMenu>
                <li *ngFor="let subItem of menuItem.attributes.subItems" ngbDropdownItem>
                  <a [routerLink]="subItem.Url" attr.aria-label="{{subItem.Label}}"><span
                    [innerHTML]="subItem.Label | htmlFormat"></span></a>
                </li>
              </ul>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>

  </nav>


  <!--  <div class="menu-container" [ngClass]="'columns-2'">-->
  <!--    <ul class="menu-items">-->
  <!--        <li *ngIf="menuItemsData.data[0] as menuItem">-->
  <!--          {{menuItem.attributes.Label}}-->
  <!--          <ul *ngIf="menuItem.attributes.subItems">-->
  <!--            <li *ngFor="let subItem of menuItem.attributes.subItems">-->
  <!--              {{subItem.Label}}-->
  <!--              <ul *ngIf="subItem">-->
  <!--                <li *ngFor="let subItem2 of subItem.subItems">-->
  <!--                  {{subItem2.Label}}-->
  <!--                </li>-->
  <!--              </ul>-->

  <!--            </li>-->
  <!--          </ul>-->
  <!--          <hr>-->
  <!--        </li>-->
  <!--    </ul>-->
  <!--    <div class="image-container">-->
  <!--      <img src="path/to/your/image.jpg" alt="Image">-->
  <!--    </div>-->
  <!--  </div>-->

</div>

<div class="menu-curtain hidden" [class.show]="devShow" (click)="hideMenuHandler()"></div>
<!--<pre style="padding-top:250px;">{{menuItemsData.data[devIndex].attributes.subItems | json}}</pre>-->
<div class="menu-container-new hidden" [class.show]="devShow" *ngIf="menuItemsData">
  <div class="menu-item"
       *ngFor="let item of menuItemsData.data[devIndex].attributes.subItems.slice(0,4); let i = index">
    <a *ngIf="item.Url" class="heading" [innerHTML]="item.Label | htmlFormat" href="{{ item.Url }}"></a>
    <span *ngIf="!item.Url" class="heading" [innerHTML]="item.Label | htmlFormat"></span>
    <ul>
      <li *ngFor="let subItem of item.subItems">
        <a href="{{ subItem['Url']}}">{{ subItem["Label"] }}</a>
      </li>
    </ul>
  </div>
  <div *ngIf="menuItemsData.data[devIndex].attributes.subItems.length < 4"
       class="image-container"
       [ngClass]="'columns-' + menuItemsData.data[devIndex].attributes.subItems.length">
    <img src="{{imgBaseUrl + menuItemsData.data[devIndex].attributes.MegaMedia.data.attributes.url}}" alt="Image">
  </div>
</div>


<button class="mobile-menu-button" (click)="openScroll(menucontent)" [ngbCollapse]="isLargeScreen">
  <span class="burger">
    <svg xmlns="http://www.w3.org/2000/svg" id="Ebene_1" width="30" height="16.5" version="1.1" viewBox="0 0 30 16.5">
      <!-- Generator: Adobe Illustrator 28.6.0, SVG Export Plug-In . SVG Version: 1.2.0 Build 709)  -->
      <g>
        <g id="Layer_1">
          <g id="hamburger">
            <line id="Line_2" y1=".75" x2="30" y2=".75" style="fill: none; stroke: #000; stroke-width: 1.5px;"/>
            <line id="Line_4" y1="15.75" x2="30" y2="15.75" style="fill: none; stroke: #000; stroke-width: 1.5px;"/>
          </g>
        </g>
      </g>
    </svg>
  </span>
</button>
<ng-template #menucontent let-offcanvas class="offcanvas-header">
  <span class="offcanvas-title">NAViGATIoN</span>
  <button
    type="button"
    class="mobile-close-button"
    aria-label="Close"
    (click)="closeScroll()"
  >
    <span class="burger">
<!--      <svg width="92.59" height="243.06" viewBox="0 0 25.31 63.28" xmlns="http://www.w3.org/2000/svg"><defs><clipPath id="a" clipPathUnits="userSpaceOnUse"><path d="M-882 221H253v-719H-882Z"/></clipPath></defs><path d="m0 0 36-63h-72Z" clip-path="url(#a)" style="fill:#000 icc-color(sRGB-IEC61966-2,.1,0,0,0);fill-opacity:1;fill-rule:nonzero;stroke:none" transform="matrix(.35159 0 0 -.35159 12.66 42.72)"/><path d="m0 0 36-63h-72Z" clip-path="url(#a)" style="fill:#000 icc-color(sRGB-IEC61966-2,.1,0,0,0);fill-opacity:1;fill-rule:nonzero;stroke:none" transform="matrix(.35159 0 0 -.35159 12.66 20.6)"/><path d="m0 0 36-63h-72Z" clip-path="url(#a)" style="fill:#000 icc-color(sRGB-IEC61966-2,.1,0,0,0);fill-opacity:1;fill-rule:nonzero;stroke:none" transform="matrix(.35159 0 0 -.35159 12.66 -1.58)"/></svg>-->
       <svg class="burger-icon" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.02 26.02" width="30" height="16.5"width="30" height="16.5">
        <g>
          <g id="Layer_1">
            <g id="hamburger-close">
              <line id="Line_2" x1=".51" y1=".51" x2="25.51" y2="25.51" style="fill: none; stroke: #000; stroke-miterlimit: 4; stroke-width: 1.5px;"/>
              <line id="Line_3" x1=".51" y1="25.51" x2="25.51" y2=".51" style="fill: none; stroke: #000; stroke-miterlimit: 4; stroke-width: 1.5px;"/>
            </g>
          </g>
        </g>
       </svg>
    </span>
  </button>

  <top-page-menu></top-page-menu>
  <div ngbAccordion [closeOthers]="true" class="accordion-container">
    <div ngbAccordionItem *ngFor="let menuItem of menuItemsData?.data; let j = index" class="accordion-item">
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton><span [innerHTML]="menuItem.attributes.Label | htmlFormat"></span></button>
      </h2>
      <div ngbAccordionCollapse class="accordion-sub-item">
        <div class="menu-container-mobile columns-{{menuItem.attributes.subItems.length + 1}}">
          <div class="menu-item col{{i}}" *ngFor="let subItem of menuItem.attributes.subItems.slice(0,4); let i = index">

            <a [routerLink]="subItem.Url" (click)="closeScroll()" class="heading">
              <span [innerHTML]="subItem.Label | htmlFormat"></span>
            </a>

            <ul>
              <li *ngFor="let item of subItem.subItems; let i = index">
                <a href="{{ item['Url'] }}">{{ item["Label"] }}</a>

              </li>
            </ul>
          </div>
          <div *ngIf="menuItemsData.data[j].attributes.subItems.length < 4"
               class="image-container col3"
               [ngClass]="'columns-' + menuItemsData.data[j].attributes.subItems.length">
            <img src="{{imgBaseUrl + menuItem.attributes.MegaMedia.data.attributes.url}}" alt="Image">
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


