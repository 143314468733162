<div class="stage-container">
  <div class="title-container">
    <h1>
      {{ page?.page?.data?.attributes?.Title ?? "" }}
    </h1>
  </div>
  <div class="content">
    <div class="html" [innerHTML]="page?.page?.data?.attributes?.HtmlContent ?? ''"></div>
  </div>
  <div class="page-sub-title-container">
    <p *ngIf="page?.Untertitel" style="margin-bottom: 0" class="before-underline">{{ page?.Untertitel }}</p>
    <div style="display: flex; flex-direction: row; justify-content: space-between;">
      <h3 class="page-title">{{ page?.Titel }}</h3>
      <div class="arrow-block"><img src="../../assets/triangle-up-triple-sideways.svg" /></div>
    </div>
  </div>
  <div class="stage-info-flex" *ngIf="initialLoadDone">
    <div class="day-selector-container">
      <app-day-selector
        *ngIf="days"
        [days]="days"
        (onDaySelected)="daySelected($event)"
        [selectedDay]="selectedDay"
      ></app-day-selector>
      <div class="information-options-box">
        <div class="important-information-container">
          <p class="information-location-title">Wichtige Informationen</p>
          <div class="information-container">
            @for (item of page?.Infos; track $index) {
            <app-information-item [item]="item"> </app-information-item>
            }
          </div>
        </div>
        <div class="location-container">
          <p class="information-location-title">Veranstaltungsort</p>
          <div class="location-container-content-box">
            <img class="location-container-content-arrows" src="../../assets/triangle-up-triple-sideways.svg" />
            <div class="location-container-content">
              <div class="location-container-content-title" [innerHTML]="page?.Veranstaltungsort?.Title"></div>
              <div class="location-container-content-text" [innerHTML]="page?.Veranstaltungsort?.Info"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="selectedDay" class="filter-container">
    <h3 class="active-filter">Filter</h3>
    <div class="filter-tags">
      <span class="filter-tag" *ngIf="selectedDay">
        {{ selectedDay.attributes.Datum }}
        <button (click)="onRemoveDayFilter()" class="remove-filter-tag">
          <svg
            width="20px"
            height="20px"
            stroke-width="1.5"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color="grey"
          >
            <path
              d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426"
              stroke="#FFFFFF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </button>
      </span>
    </div>
  </div>
  <app-speech-list
    #speechList
    *ngIf="workshops"
    [items]="workshops"
    stageTitle="Workshopraum"
    [dayTitle]="selectedDay?.attributes?.Wochentag ?? ''"
    [dayDate]="(selectedDay?.attributes?.Datum ?? '' | date : 'dd.MM.') ?? ''"
    (goToPage)="goToPage($event)"
    [pagination]="pagination"
    [startTimeSelector]="startTimeSelector"
    [endTimeSelector]="endTimeSelector"
    [titleSelector]="titleSelector"
    [subTitleSelector]="subTitleSelector"
    [institutionSelector]="institutionSelector"
    [daySelector]="daySelector"
    [dateSelector]="dateSelector"
    [speakersSelector]="speakersSelector"
    [descriptionSelector]="descriptionSelector"
    [locationSelector]="locationSelector"
    [iconItems]="listIconItems"
    [sortOptions]="listSortOptions"
    [customPanelTemplate]="customPanelTemplate"
    [customContentTemplate]="customContentTemplate"
    [handleColorSelector]="handleColorSelector"
  ></app-speech-list>
  <ng-template #customPanelTemplate let-item>
    <div class="signups-display">
      <div class="signups-counter">
        {{ item.attributes.Platzanzahl - item.attributes.signups }}/{{ item.attributes.Platzanzahl }}
      </div>
      @switch (signupState(item.id)) { @case (SUCCESS) {
      <div class="signups-info signups-success">Angemeldet</div>
      } @case (NOTDONE) {
      <div class="signups-info signups-notDone">Plätze verfügbar</div>
      } @case (FULL) {
      <div class="signups-info signups-full">Ausgebucht</div>
      } @case(FAIL_DUE_TO_FULL) {
      <div class="signups-info signups-full">Ausgebucht</div>
      } @default {
      <div class="signups-info signups-notDone">Plätze verfügbar</div>
      } }
    </div>
  </ng-template>
  <ng-template #customContentTemplate let-item>
    <form #f="ngForm" (ngSubmit)="signup(f, item)" style="margin-bottom: 2rem">
      <div style="display: flex; flex-direction: row; gap: 20px 12px">
        @if (inputDiv.hidden) {
        <button
          [disabled]="
            signupState(item.id) == SUCCESS || signupState(item.id) == FAIL_DUE_TO_FULL || signupState(item.id) == FULL
          "
          class="submit-button"
          (click)="inputDiv.hidden = false"
        >
          Jetzt Anmelden
          <svg
            xmlns="http://www.w3.org/2000/svg"
            shape-rendering="geometricPrecision"
            text-rendering="geometricPrecision"
            image-rendering="optimizeQuality"
            fill-rule="evenodd"
            clip-rule="evenodd"
            viewBox="0 0 512 512"
            class="arrow"
          >
            <path
              fill-rule="nonzero"
              d="M165 0c-8-1-16 6-17 15s7 17 16 17l274 19L5 484c-7 7-7 17 0 23 6 7 16 7 23 0L461 74l19 274c0 9 8 16 17 16s16-9 15-17L491 37c0-9-7-15-15-16L165 0z"
            />
          </svg>
        </button>
        } @else {
        <button
          type="submit"
          [disabled]="!f.valid || submitting || (signupState(item.id) != NOTDONE && signupState(item.id) != FAIL)"
          class="submit-button"
        >
          Anmeldung absenden
          <svg
            xmlns="http://www.w3.org/2000/svg"
            shape-rendering="geometricPrecision"
            text-rendering="geometricPrecision"
            image-rendering="optimizeQuality"
            fill-rule="evenodd"
            clip-rule="evenodd"
            viewBox="0 0 512 512"
            class="arrow"
          >
            <path
              fill-rule="nonzero"
              d="M165 0c-8-1-16 6-17 15s7 17 16 17l274 19L5 484c-7 7-7 17 0 23 6 7 16 7 23 0L461 74l19 274c0 9 8 16 17 16s16-9 15-17L491 37c0-9-7-15-15-16L165 0z"
            />
          </svg>
        </button>
        <button (click)="inputDiv.hidden = true" class="signup-cancel">
          <svg
            width="20px"
            height="20px"
            stroke-width="1.5"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color="grey"
          >
            <path
              d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426"
              stroke="#FFFFFF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </button>
        }
      </div>
      <div #inputDiv hidden>
        <div class="inputs-row">
          <div class="input-container">
            <label class="input-label"> Name: <b style="color: red">*</b></label>
            <input required name="name" type="text" ngModel #name="ngModel" />
            <span class="error-text" *ngIf="name.errors?.['required'] && name.touched"
              >Dieses Feld muss ausgefüllt sein</span
            >
          </div>
          <div class="input-container">
            <label class="input-label"> Email: <b style="color: red">*</b></label>
            <input required [pattern]="emailRegexPattern" name="email" type="email" ngModel #email="ngModel" />
            <span class="error-text" *ngIf="email.errors?.['pattern'] && email.touched">Ungültige E-Mail</span>
            <span class="error-text" *ngIf="email.errors?.['required'] && email.touched"
              >Dieses Feld muss ausgefüllt sein</span
            >
          </div>
        </div>
      </div>

      @if(!inputDiv.hidden) { @switch (signupState(item.id)) { @case (SUCCESS) {
      <label class="success-text"> Anmeldung erfolgreich </label>
      } @case (FAIL) {
      <label class="error-text"> Anmeldung fehlgeschlagen. Versuche es später erneut. </label>
      } @case (FAIL_DUE_TO_FULL) {
      <label class="error-text"> Anmeldung fehlgeschlagen. Inzwischen wurden alle Plätze besetzt. </label>
      } } }
    </form>
  </ng-template>
</div>
<div #dynamicContent></div>
