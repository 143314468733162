import { Component, Input } from "@angular/core"
import { ApiService } from "src/api.service"
import { ProgrammPageInfoModel } from "src/models/programm-page-model"

@Component({
  selector: "app-information-item",
  templateUrl: "./information-item.component.html",
  styleUrl: "./information-item.component.scss",
})
export class InformationItemComponent {
  @Input({ required: true })
  item!: ProgrammPageInfoModel

  constructor(private apiService: ApiService) {}

  public get url(): string {
    return `${this.apiService.apiUrl}${this.item.Bild.data.attributes.url}`
  }
}
