import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { AuthenticationService } from "src/authentication.service"

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.authService.getTokenFromStorage()
    if (token) {
      const cloned = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + token),
      })

      return next.handle(cloned)
    } else {
      return next.handle(req)
    }
  }
}
