import { Component } from '@angular/core';
import { AuthenticationService } from '../../authentication.service';

@Component({
  selector: 'app-unlock',
  standalone: true,
  imports: [],
  templateUrl: './unlock.component.html',
  styleUrl: './unlock.component.scss'
})
export class UnlockComponent {
  constructor(private authService: AuthenticationService) {}

  unlockSite(): void {
    this.authService.unlockSite();
  }
}
