<div class="register-container">
  <div class="title-container">
    <h1>
      {{ page?.data?.attributes?.page?.data?.attributes?.Title ?? "" }}
    </h1>
  </div>
  <div class="content">
    <div [innerHTML]="page?.data?.attributes?.page?.data?.attributes?.HtmlContent ?? ''"></div>
    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
      <div class="inputs-row">
        <app-best-input 
          label="Vorname:" 
          [formGroup]="registerForm"
          [required]="true" 
          controlName="firstName" 
          [validatorErrors]="[{key: 'required', errorText: 'Dieses Feld muss ausgefüllt sein'}]"
        />
        <app-best-input 
          label="Nachname:" 
          [formGroup]="registerForm"
          [required]="true" 
          controlName="lastName" 
          [validatorErrors]="[{key: 'required', errorText: 'Dieses Feld muss ausgefüllt sein'}]"
        />
      </div>
      <div class="inputs-row">
        <app-best-input 
          label="Telefon:" 
          [formGroup]="registerForm"
          [required]="true" 
          controlName="tel" 
          infoText="Format: +43 (0)1 234 5678"
          type="tel"
          [validatorErrors]="[{key: 'required', errorText: 'Dieses Feld muss ausgefüllt sein'},{key: 'pattern', errorText: 'Ungültige Telefonnummer'}]"
        />
        <app-best-input 
          label="E-Mail:"
          [formGroup]="registerForm"
          controlName="email"
          type="email"
          [required]="true"
          [validatorErrors]="[{key: 'required', errorText: 'Dieses Feld muss ausgefüllt sein'},{key: 'pattern', errorText: 'Ungültige E-Mail'}]"
        />
      </div>
      <div class="inputs-row">
        <app-best-input 
          label="Passwort:"
          [formGroup]="registerForm"
          controlName="password"
          type="password"
          [required]="true"
          infoText="Mindestens 8 Zeichen."
          [validatorErrors]="[{key: 'required', errorText: 'Dieses Feld muss ausgefüllt sein'}, {key: 'minlength', errorText: 'Dieses Feld muss mindestens 8 Zeichen lang sein'}]"
        />
        <app-best-input 
          label="Passwort wiederholen:"
          [formGroup]="registerForm"
          controlName="passwordConfirmation"
          type="password"
          [required]="true"
          infoText="Mindestens 8 Zeichen."
          [validatorErrors]="[{key: 'required', errorText: 'Dieses Feld muss ausgefüllt sein'}, {key: 'minlength', errorText: 'Dieses Feld muss mindestens 8 Zeichen lang sein'}]"
        >
          @if(registerForm.errors?.['sameInput'] && registerForm.get('passwordConfirmation')?.touched) {
          <span class="error-text">Die eingegebenen Passwörter stimmen nicht überein</span>
          }
        </app-best-input>
      </div>

      <div class="inputs-row">
        <div class="input-container checkbox">
          <label class="input-label">
            <input formControlName="ageCheck" type="checkbox" />
            <span class="custom-checkbox"></span>
            Ich bestätige, dass ich mindestens 18 Jahre alt bin<b style="color: red">*</b>
          </label>
        </div>
      </div>
      <div class="inputs-row pt-1">
        <div class="input-container checkbox">
          <label class="input-label">
            <input formControlName="agb" type="checkbox" />
            <span class="custom-checkbox"></span>
            Ich habe die AGB und Datenschutzbelehrung gelesen und akzeptiere diese durch das Absenden des Formulars.<b
              style="color: red"
              >*</b
            >
          </label>
        </div>
      </div>

      <label class="error-text" *ngIf="registrationSuccess != undefined && !registrationSuccess">
        Die E-Mail oder der Name sind bereits vergeben.
      </label>

      <label class="success-text" *ngIf="registrationSuccess">
        Sie haben sich erfolgreich registriert und werden in Kürze zur Homepage weitergeleitet.
      </label>

      <label *ngIf="!registerForm.valid && fh?.submitAttempted" class="error-text" style="padding-top: 32px"
        >Einige Felder wurden nicht richtig ausgefüllt. Bitte überprüfen sie ihre Angaben.
      </label>

      <button class="submit-button" type="submit">
        Account erstellen
        <svg
            xmlns="http://www.w3.org/2000/svg"
            shape-rendering="geometricPrecision"
            text-rendering="geometricPrecision"
            image-rendering="optimizeQuality"
            fill-rule="evenodd"
            clip-rule="evenodd"
            viewBox="0 0 512 512"
            class="arrow"
          >
            <path
              fill-rule="nonzero"
              d="M165 0c-8-1-16 6-17 15s7 17 16 17l274 19L5 484c-7 7-7 17 0 23 6 7 16 7 23 0L461 74l19 274c0 9 8 16 17 16s16-9 15-17L491 37c0-9-7-15-15-16L165 0z"
            />
          </svg>
      </button>
    </form>
  </div>
</div>
<div #dynamicContent></div>
