import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { FormControl } from "@angular/forms"
import { firstValueFrom } from "rxjs"
import { ApiService } from "src/api.service"
import { StrapiFile } from "src/models/filetypes-model"

export enum FileType {
  "image",
  "document",
  "video",
  "audio",
}

export interface UploadConfig {
  ref: string
  refId: string
  field: string
}

const acceptMap: { [key in FileType]: string[] } = {
  [FileType.image]: [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/bmp",
    "image/webp",
    "image/svg+xml",
    "image/tiff",
    "image/x-icon",
  ],
  [FileType.document]: [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "text/plain",
    "application/rtf",
  ],
  [FileType.video]: [
    "video/mp4",
    "video/mpeg",
    "video/quicktime",
    "video/webm",
    "video/x-msvideo",
    "video/x-ms-wmv",
    "video/ogg",
  ],
  [FileType.audio]: ["audio/mpeg", "audio/wav", "audio/ogg", "audio/webm", "audio/aac"],
}

@Component({
  selector: "app-file-card-container",
  templateUrl: "./file-card-container.component.html",
  styleUrl: "./file-card-container.component.scss",
})
export class FileCardContainerComponent implements OnInit {
  @Input({ required: true })
  files!: StrapiFile[]
  @Input()
  uploadConfig?: UploadConfig
  @Input({ required: true })
  control?: FormControl
  @Input()
  selectLimit?: number
  @Input()
  uploadLimit: number = 5
  @Input()
  allowTypes: FileType[] = [FileType.image, FileType.document, FileType.video, FileType.audio]
  @Input()
  placeholder = "logo.jpg"

  @Output()
  fileUploadFailed: EventEmitter<boolean> = new EventEmitter()

  displayAcceptMap: { [key in FileType]: string } = {
    [FileType.image]: "JPEG, PNG, GIF, WebP, SVG, TIFF, Icon, BMP",
    [FileType.document]: "PDF, Word, Excel, PowerPoint, Text, RTF",
    [FileType.video]: "MP4, MPEG, QuickTime, WebM, AVI, WMV, OGG",
    [FileType.audio]: "MP3, WAV, OGG, WebM, AAC",
  }

  selectedFiles: number[] = []
  uploadedFileNames?: string[]

  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    var controlVal = this.control?.value
    if (!controlVal) return
    if (Array.isArray(controlVal)) {
      this.selectedFiles.push(...controlVal)
    } else {
      this.selectedFiles.push(controlVal)
    }
  }

  calculateAcceptTypes(): string {
    const acceptedTypes: string[] = []

    this.allowTypes.forEach((type) => {
      if (type === FileType.document) {
        // Add document types that are not included in image, video, or audio
        const excludedTypes = new Set([
          ...acceptMap[FileType.image],
          ...acceptMap[FileType.video],
          ...acceptMap[FileType.audio],
        ])
        const documentTypes = acceptMap[FileType.document].filter((docType) => !excludedTypes.has(docType))
        acceptedTypes.push(...documentTypes)
      } else {
        acceptedTypes.push(...acceptMap[type])
      }
    })

    return acceptedTypes.join(",")
  }

  //MARK: Upload
  protected async onUpload(event: any) {
    if (!this.uploadConfig) return
    const files = event.target.files
    if (files.length + this.files.length > this.uploadLimit) {
      alert("Sie haben die Maximalanzahl hochladbaren Dateien überschritten.")
      event.target.value = "" // Clear the input
      return
    }
    try {
      const formData = new FormData()
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i])
      }
      formData.append("ref", this.uploadConfig.ref)
      formData.append("refId", this.uploadConfig.refId)
      formData.append("field", this.uploadConfig.field)

      var response = await firstValueFrom(this.apiService.postFile(formData))
      if (!this.files) {
        this.files = [response]
      } else {
        this.files.push(...response)
      }
      this.uploadedFileNames = files.map((f: File) => f.name)
      this.fileUploadFailed.emit(false)
    } catch (_) {
      this.fileUploadFailed.emit(true)
    }
  }

  protected async onDeleted(event: StrapiFile) {
    this.files = this.files.filter((f) => f.id != event.id)
    this.selectedFiles = this.selectedFiles.filter((f) => f != event.id)
  }

  private handleAsList(): boolean {
    return this.selectLimit !== 1
  }

  protected isSelected(file: StrapiFile) {
    return this.selectedFiles.includes(file.id)
  }

  protected onFileSelect(file: StrapiFile) {
    setTimeout(() => {
      if (!this.handleAsList()) {
        if (this.control?.value !== file.id) {
          this.control?.setValue(file.id)
          this.selectedFiles = [file.id]
        } else {
          this.control?.setValue(null)
          this.selectedFiles = []
        }
      } else {
        var ids = this.control?.value as number[] | null
        if (!ids) ids = []
        if (ids.find((i) => i == file.id) != undefined) {
          ids = ids.filter((i) => i != file.id)
          this.selectedFiles = this.selectedFiles.filter((i) => i != file.id)
        } else if (!this.selectLimit || ids.length < this.selectLimit) {
          ids.push(file.id)
          this.selectedFiles.push(file.id)
        }
        this.control?.setValue(ids)
      }
    }, 1)
  }
}
