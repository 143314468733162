import { AfterViewInit, Component, EventEmitter, Input, Output } from "@angular/core"
import { StrapiDataWrapper } from "src/models/strapi-wrapper"
import { TagModel } from "src/models/tag-model"

const htmlTagRegex = /<\/?[^>]+(>|$)/g

@Component({
  selector: "app-day-selector",
  templateUrl: "./day-selector.component.html",
  styleUrl: "./day-selector.component.scss",
})
export class DaySelectorComponent implements AfterViewInit {
  @Input({ required: true })
  days!: StrapiDataWrapper<TagModel>[]

  @Output()
  onDaySelected: EventEmitter<StrapiDataWrapper<TagModel>> = new EventEmitter()

  @Input()
  selectedDay?: StrapiDataWrapper<TagModel>

  selectDay(day: StrapiDataWrapper<TagModel>) {
    this.selectedDay = day
    this.onDaySelected.emit(this.selectedDay)
  }

  dayCounter(day: StrapiDataWrapper<TagModel>) {
    return this.days.findIndex((d) => d.id === day.id) + 1
  }

  ngAfterViewInit(): void {
    if (this.selectedDay) {
      this.onDaySelected.emit(this.selectedDay)
      return
    }
    setTimeout(() => {
      this.selectDay(this.days[0])
    })
  }

  public weekday(day: TagModel): string {
    return day.Wochentag.replaceAll(htmlTagRegex, "")
  }
}
